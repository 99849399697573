import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { UsersActions, UpdatePasswordInfo } from '@myblueprint-spaces/redux/lib/users';

export default function useResetPassword() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (userId: string, passwordInfo: UpdatePasswordInfo) => dispatch(UsersActions.resetPassword(userId, passwordInfo)),
    [dispatch]
  );
}

