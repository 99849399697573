import { NotificationType, UserNotificationDisplayData } from '@myblueprint-spaces/redux/lib/notifications';
import { ImportTypeURL } from 'pages/Imports/types';
import { Location, Path } from 'react-router-dom';
import { NotificationWithDisplayData } from './types';
import { cleanAssociationName } from 'modules/helpers/accountTypeHelper';

export type INotificationInteraction = (location: Location | null, notification?: Notification | null) => { url: string | Partial<Path>, action?: () => void } | null;

export const useNotificationInteraction = () => (location: Location | null, notification?: NotificationWithDisplayData): { url: string, action?: () => void } | null => {
  const { type, displayData } = (notification ?? {}) as NotificationWithDisplayData;
  const { schoolOrDistrictName } = (displayData ?? {}) as UserNotificationDisplayData;

  if (type === NotificationType.AdminRoleAdminUpdated) {
    return { url: `/settings/account#${schoolOrDistrictName ? cleanAssociationName(schoolOrDistrictName) : 'associations'}` };
  } else if (type === NotificationType.AdminTeacherApprovalRequest || type === NotificationType.AdminTeacherApprovalRequestMulti) {
    return { url: '/staff?approvalRequests=true' };
  } else if (type === NotificationType.AdminCsvImports) {
    return { url: `/imports/${ImportTypeURL.CSV}` };
  } else if (type === NotificationType.AdminOneRosterImports) {
    return { url: `/imports/${ImportTypeURL.ONEROSTER}` };
  }
  // Fallback
  return location ? { url: location.pathname + location.search } : null;
};
