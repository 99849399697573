import styled from 'styled-components';
import { Colors, Shadows } from '@myblueprint-spaces/papier-core';
import LinkButton from '@myblueprint-spaces/papier-web/lib/Atoms/LinkButton';

export const AccountTypeWrapper = styled.div`
  background: ${Colors.grey4};
  margin: 0;
  padding: 0;
`;

export const ButtonContent = styled.div`
  background: ${Colors.white};
  ${Shadows.shadow1};
  border-radius: 0.25rem;
  padding: 0.5rem;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  padding: 0.5rem 1rem 0 1rem;
`;

export const Container = styled.div`
  ${({ noMargin }:{ noMargin?: boolean }) => noMargin ? '' : 'margin-left: 0.75rem'};
`;

export const StyledLink = styled(LinkButton)`
  text-decoration: none;
  width: 100%;
  display: block;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover > div, &:focus > div {
    ${Shadows.shadow3}
  }
`;
