import React, { useContext } from 'react';
import Logo from '@myblueprint-spaces/papier-web/lib/Common/Logo';
import { Wrapper, Top, List } from './styles';
import SchoolSelection from './components/SchoolSelection';
import { RovingTabIndexProvider } from '@myblueprint-spaces/react-roving-tabindex';
import ItemContent from './components/ItemContent';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import CurrentSchoolAccountInfoContext from 'contexts/CurrentSchoolAccountInfoContext';
import CurrentSchoolContext from 'contexts/CurrentSchoolContext';

const Sidebar = ({ onNavigate }: { onNavigate?: () => void }) => {
  const { isDistrict, isSuperAdmin } = useContext(CurrentSchoolAccountInfoContext);
  const { license } = useContext(CurrentSchoolContext);
  const { portfolioTemplateEnabled } = license || {};

  const options = [
    {
      icon: 'computer',
      text: 'Admin.Common:Dashboard',
      to: '/dashboard',
      dataTest: 'dashboard'
    },
    {
      icon: 'people',
      text: 'Common:Student_plural',
      to: '/students',
      dataTest: 'students'
    },
    {
      icon: 'bus',
      text: 'Admin.Common:Staff',
      to: '/staff',
      dataTest: 'staff'
    },
    {
      icon: 'class',
      text: 'Common:Classes',
      to: '/classes',
      dataTest: 'classes'
    }
  ];

  if (isSuperAdmin && isDistrict && portfolioTemplateEnabled) options.push({
    icon: 'lightning',
    text: 'Admin.Common:PortfolioTemplates',
    to: '/portfolios',
    dataTest: 'portfolios'
  });


  return (
    <Wrapper>
      <Top>
        <Logo size="medium" style={{ marginBottom: '1rem' }} />
        <SchoolSelection />
      </Top>
      <RovingTabIndexProvider>
        <List role="menu">
          {options.map((itemProps, i) => <ItemContent key={i} {...itemProps} onClick={onNavigate} exactPath={false} />)}
        </List>
      </RovingTabIndexProvider>
    </Wrapper>
  );
};

export default withErrorBoundaries(Sidebar);
