import { Colors } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

export const SchoolItem = styled.div`
  border-radius: 0.5rem;
  border: solid 0.0625rem ${Colors.grey2};
  padding: 0 1rem;
  width: 100%;

  div:last-child {
    border-bottom: none;
  }
`;

export const StyledAsset = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  margin-bottom: 0.5rem;
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0.5rem 0 1rem;
`;

export const TypographyWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export const SchoolsWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const HeaderContainer = styled.div`
  padding-top: 0.75rem;
`;
