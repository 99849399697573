import React from 'react';
import StaffHooks from 'modules/hooks/redux/staff';
import StudentHooks from 'modules/hooks/redux/student';
import SchoolSelectionRow from './SchoolSelectionRow';
import { SchoolSelectionRowProps } from './types';

const SchoolSelectionRowContainer = (props: SchoolSelectionRowProps) => {
  const { school } = props;
  const staffCount = StaffHooks.useStaffCount(school.id);
  const studentCount = StudentHooks.useStudentCount(school.id);

  const allProps = {
    ...props,
    staffCount,
    studentCount
  };

  return (<SchoolSelectionRow {...allProps} />);
};

export default SchoolSelectionRowContainer;
