import styled from 'styled-components';
import { Breakpoints, Colors } from '@myblueprint-spaces/papier-core';
import ImageBlock from '@myblueprint-spaces/papier-web/lib/Atoms/ImageBlock';
import CleanButton from '@myblueprint-spaces/papier-web/lib/Atoms/CleanButton';

export const TopWrapper = styled.header`
  width: 100%;
  padding: 0.75rem 0;
`;

export const Badge = styled.div(({ showBadge }: { showBadge?: boolean }) => `
  position: relative;

  ${showBadge ? `
    &:before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      top: 0;
      right: 0;
      background: ${Colors.danger1};
      border-radius: 50%;
      border: 0.125rem solid ${Colors.white};
      z-index: 99;
    }
  `: ''}
`);

export const AvatarButton = styled(CleanButton)`
  border-radius: 50%;
  overflow: hidden;
  -moz-background-clip: padding; 
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  & > div {
    margin: 0;
    overflow: hidden;
    -moz-background-clip: padding; 
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
`;

export const CustomEmoji = styled(ImageBlock)`
  width: 1.75rem;

  @media (max-width: ${Breakpoints.medium}em) {
    width: 1rem;
  }
`;

export const Onboarding = styled.div`
  padding: 0 2.5rem 2.5rem;
  text-align: center;
`;
