import React from 'react';
import { userScope } from '@myblueprint-spaces/redux/lib/scopes';
import UserHooks from 'modules/hooks/redux/user';
import Topbar from './Topbar';
import { NotificationHooks } from '@myblueprint-spaces/web-common/lib/Common/Notifications';

const TopbarContainer = (props) => {
  const basicInfo = UserHooks.useBasicUserInfo();
  const associations = UserHooks.useAllAssociations();
  const currentScope = userScope(basicInfo?.id as string);
  const logout = UserHooks.useLogout();
  const newNotifications = NotificationHooks.useHasNewCount();

  const allProps = {
    ...props,
    associations,
    currentScope,
    newNotifications,
    logout
  };

  return <Topbar {...allProps} />;
};

export default TopbarContainer;
