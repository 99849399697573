import React, { useCallback, useContext } from 'react';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import ComplexI18n from '@myblueprint-spaces/papier-web/lib/Common/ComplexI18n';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import LinkButton from '@myblueprint-spaces/papier-web/lib/Atoms/LinkButton';
import EmptyState from '@myblueprint-spaces/papier-web/lib/Common/EmptyState';
import ImageBlock from '@myblueprint-spaces/papier-web/lib/Atoms/ImageBlock';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import { useTranslation } from 'react-i18next';
import emptyInvites from 'assets/Asset_empty_invites.png';
import { User, UserEndpoints } from '@myblueprint-spaces/redux/lib/user';
import { AccountType } from '@myblueprint-spaces/appstate';
import { getScopeTypeFromAccountType } from 'modules/helpers/accountTypeHelper';
import { ScopeType } from '@myblueprint-spaces/redux/lib/scopes';
import { failToast, successToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { EmptyStatewrapper, LogoutWrapper, Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';

const VerifyEmail = ({ user, logout }: { user?: User; logout: () => Promise<void>; }) => {
  const { t } = useTranslation(['AccountSettings.Common', 'AccountSettings.Admin', 'Common']);
  const { isSmall } = useContext(WindowSizeContext);
  const navigate = useNavigate();

  const scopeType = getScopeTypeFromAccountType(AccountType.Admin) as ScopeType;
  const verifyEmail = () => {
    UserEndpoints.resendEmailVerification(scopeType)
      .then(() => {
        successToast(t, t('AccountSettings.Admin:EmailResent').toString());
      })
      .catch((err) => {
        failToast(err, t);
      });
  };

  const handleLogout = useCallback(() => {
    return logout()
      .then(() => navigate('/'));
  }, [logout, navigate]);

  return (
    <Wrapper>
      <EmptyStatewrapper>
        <Row align="center" verticalAlign="middle">
          <Column columns={{ small: 11, medium: 5, large: 3 }}>
            <EmptyState
              image={<ImageBlock src={emptyInvites} alt="" aria-hidden="true" />}
              title={t('AccountSettings.Common:VerifyAccount.Title').toString()}
              subtitle={{ children: <ComplexI18n i18nKey="AccountSettings.Admin:VerifyAccount.Subtitle" i18nParams={{ email: user?.email }}>
                <Typography color="black3" type={isSmall ? 'compact' : 'body2'} weight="regular" />
                <Typography color="black2" type={isSmall ? 'compact' : 'body2'} weight="demibold" />
              </ComplexI18n> }}
            />
            <div style={{ marginBottom: '0.5rem' }}>
              <Button
                text={t('AccountSettings.Admin:RefreshPage')}
                dataTest="refresh-page"
                color="primary"
                expanded
                async
                // eslint-disable-next-line no-restricted-properties
                onClick={() => window.location.reload()} />
            </div>
            <Button
              text={t('AccountSettings.Common:ResendEmail')}
              dataTest="resend-email"
              color="secondary"
              expanded
              onClick={verifyEmail} />
          </Column>
        </Row>
      </EmptyStatewrapper>
      <LogoutWrapper>
        <CovalentGrid align="center">
          <Typography color="black3" type={isSmall ? 'compact' : 'body2'} weight="regular">{t('AccountSettings.Admin:TryDifferentAccount')}</Typography>
          <LinkButton color="primary1" type={isSmall ? 'compact' : 'body2'} weight="medium" onClick={handleLogout}>
            {t('Common:Actions.Logout')}
          </LinkButton>
        </CovalentGrid>
      </LogoutWrapper>
    </Wrapper>
  );
};

export default VerifyEmail;

