import React from 'react';
import InviteHooks from 'modules/hooks/redux/invites';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ActivateScreen from './ActivateScreen';
import useCreateUser from './hooks';
import { GoogleReCaptchaPropsType } from 'views/Login/Shared';
import SignUpHooks from 'modules/hooks/redux/signup';

const ActivateScreenContainer = (props: { googleReCaptchaProps: GoogleReCaptchaPropsType }) => {
  const createUser = useCreateUser();
  const acceptInvite = InviteHooks.useAcceptInvite();
  const inviteId = SignUpHooks.useInviteId();
  const invite = InviteHooks.useInviteWithId(inviteId);

  const allProps = {
    ...props,
    createUser,
    acceptInvite,
    invite
  };

  return (
    <ActivateScreen {...allProps} />
  );
};

export default withGoogleReCaptcha(ActivateScreenContainer as React.FunctionComponent);
