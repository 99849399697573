import React from 'react';
import AuthenticationHooks from 'modules/hooks/redux/authentication';
import InviteHooks from 'modules/hooks/redux/invites';
import SignUpHooks from 'modules/hooks/redux/signup';
import LoginButton from './LoginButton';
import { ILoginButtonContainerProps } from './types';

const LoginButtonContainer = (props: ILoginButtonContainerProps) => {
  const settings = AuthenticationHooks.useSettings();
  const inviteId = SignUpHooks.useInviteId();
  const invite = InviteHooks.useInviteWithId(inviteId);

  const allProps = {
    ...props,
    settings,
    invite
  };

  return <LoginButton {...allProps} />;
};

export default LoginButtonContainer;

