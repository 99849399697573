import { createContext } from 'react';
import FakeDropdownContainer from './FakeDropdownContainer';
import { AcceptedTypes } from './types';

export const FakeDropdownContext = createContext({} as {
  options?: AcceptedTypes[];
  selected?: AcceptedTypes | AcceptedTypes[];
  setSelected?: (e: AcceptedTypes | AcceptedTypes[]) => void;
  isFiltered?: boolean
});

export default FakeDropdownContainer;
