import { AccountType } from '@myblueprint-spaces/appstate';
import { AuthenticationResult } from '@myblueprint-spaces/redux/lib/authentication';
import axios from 'axios';
import { AdminAssociation, FamilyAssociation, StudentAssociation, TeacherAssociation, UserAssociations } from '@myblueprint-spaces/redux/lib/user';
import { toast } from '@myblueprint-spaces/web-common/lib/services/toasts';
import { failToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';
import { NavigateFunction } from 'react-router-dom';
import { TFunction } from 'i18next';

export const hasAccountTypeRecord
= (aType: AccountType, teacherAssociations: TeacherAssociation[] | null = null, studentAssociations: StudentAssociation[] | null = null, familyAssociation: FamilyAssociation | null = null, adminAssociation: AdminAssociation | null = null) => {
  // teacher and student will always be arrays, family will be obj or null
  switch (aType) {
    case AccountType.Teacher:
      return teacherAssociations && teacherAssociations.length;
    case AccountType.Student:
      return studentAssociations && studentAssociations.length;
    case AccountType.Family:
      return familyAssociation;
    case AccountType.Admin:
      return adminAssociation;
    default:
      return false;
  }
};

export const handleError = (err, missingRecordUrl, attemptingLoginUrl, t, navigate) => {
  if (axios.isAxiosError(err)) {
    const { response } = err;
    const { data, status } = response || {};
    if (data?.resultCode === AuthenticationResult.RecordDoesNotExist) navigate(missingRecordUrl);
    else if (status === 409) navigate(attemptingLoginUrl);
  }
  failToast(err, t);
  throw err;
};

export const associationsRedirectHelper
= (userAssociations: UserAssociations,
  urls: { spacesTeacherAccountUrl: string, spacesStudentAccountUrl: string, spacesFamilyAccountUrl: string, dashboardUrl: string, ssoErrorUrl: string },
  t: TFunction<string[], undefined>,
  navigate: NavigateFunction) => {
  const { teachers, students, family, admin } = userAssociations;
  const { spacesTeacherAccountUrl, spacesStudentAccountUrl, spacesFamilyAccountUrl, dashboardUrl, ssoErrorUrl } = urls;

  if (hasAccountTypeRecord(AccountType.Admin, null, null, null, admin)) {
    navigate(dashboardUrl);
  } else if (hasAccountTypeRecord(AccountType.Teacher, teachers)) {
    // eslint-disable-next-line no-restricted-properties
    window.location.href = spacesTeacherAccountUrl;
  } else if (hasAccountTypeRecord(AccountType.Student, null, students)) {
    // eslint-disable-next-line no-restricted-properties
    window.location.href = spacesStudentAccountUrl;
  } else if (hasAccountTypeRecord(AccountType.Family, null, null, family)) {
    // eslint-disable-next-line no-restricted-properties
    window.location.href = spacesFamilyAccountUrl;
  } else {
    toast(null, { text: t('Common.Errors:InvalidAccountState').toString() });
    navigate(ssoErrorUrl);
  }
};

export const accountRedirectHelper = (accountTypeFromScope, dashboardUrl, spacesTeacherAccountUrl, spacesStudentAccountUrl, spacesFamilyAccountUrl, navigate) => {
  switch (accountTypeFromScope) {
    case AccountType.Admin:
      navigate(dashboardUrl);
      break;
    case AccountType.Teacher:
      // eslint-disable-next-line no-restricted-properties
      window.location.href = spacesTeacherAccountUrl;
      break;
    case AccountType.Student:
      // eslint-disable-next-line no-restricted-properties
      window.location.href = spacesStudentAccountUrl;
      break;
    case AccountType.Family:
      // eslint-disable-next-line no-restricted-properties
      window.location.href = spacesFamilyAccountUrl;
      break;
  }
};
