import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { InviteSelectors } from '@myblueprint-spaces/redux/lib/invites';
import { ScopeSelectors } from '@myblueprint-spaces/redux/lib/scopes';

export default function useStudentInvitesWithInvitedBy(schoolId: string, withIntegrationId ?: boolean) {
  return useSelector((state: IState) => {
    const invitedStudents = InviteSelectors.getSentStudentInvitesForAdmin(state, schoolId, withIntegrationId);
    // senderScope might be null if sender is a removed user
    return invitedStudents?.map((s) => ({ ...s, senderScope: s.senderScope ? ScopeSelectors.getScopeModel(state, s.senderScope.scopeType, s.senderScope.ownerId) : null }));
  });
}
