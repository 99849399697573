import React from 'react';
import { useTranslation } from 'react-i18next';
import { CaptchaWrapper } from './styles';
import ExternalLink from '@myblueprint-spaces/papier-web/lib/Atoms/ExternalLink';
import ComplexI18n from '@myblueprint-spaces/papier-web/lib/Common/ComplexI18n';

const CaptchaDisclaimer = () => {
  const { t } = useTranslation(['LoginSignUp.Common']);

  return (
    <CaptchaWrapper type="subtitle1" color="black3">
      <ComplexI18n i18nKey="LoginSignUp.Common:Captcha.Disclaimer">
        <ExternalLink underline aria-label={t('LoginSignUp.Common:CaptchaPrivacyPolicy').toString()} href="https://policies.google.com/privacy" />
        <ExternalLink underline href="https://policies.google.com/terms" />
      </ComplexI18n>
    </CaptchaWrapper>
  );
};

export default CaptchaDisclaimer;
