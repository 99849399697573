import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { useLocation, useNavigate } from 'react-router-dom';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import { ILoginButtonProps } from './types';
import { handleNonSSORedirect } from './helpers';
import { LoginEmailFormContext } from '../LoginEmail';
import { ButtonStatus } from '@myblueprint-spaces/papier-core';

const LoginButton = ({ settings, initiateLogin, setOnClose, invite }: ILoginButtonProps) => {
  const { t } = useTranslation(['LoginSignUp.Common']);
  const { search } = useLocation() || {};
  const { btnStatus, setBtnStatus, email } = useContext(LoginEmailFormContext);
  const getFullUrl = useGetFullUrl();
  const activateUrl = getFullUrl('/activate');
  const noAccessUrl = getFullUrl('/no-access');
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof settings !== 'undefined') {
      if (settings) {
        const { ssoRequired } = settings;
        setOnClose((userClosedWindow) => {
          setBtnStatus(ButtonStatus.success);
          if (userClosedWindow && ssoRequired) {
            navigate(noAccessUrl);
          } else if (userClosedWindow && !ssoRequired) {
            handleNonSSORedirect(navigate, activateUrl, search, email, invite);
          }
        });
        initiateLogin();
        setBtnStatus(ButtonStatus.loading);
      } else {
        setBtnStatus(ButtonStatus.success);
        handleNonSSORedirect(navigate, activateUrl, search, email, invite);
      }
    }
  }, [settings]);

  return (
    <Button
      text={t('LoginSignUp.Common:Login.Login')}
      expanded
      type="submit"
      size="medium"
      dataTest="login"
      showResult
      status={btnStatus} />
  );
};

export default withErrorBoundaries(LoginButton);
