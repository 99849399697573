import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { InviteActions } from '@myblueprint-spaces/redux/lib/invites';

export default function useEditStudentInvite() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (inviteId: string, integrationId: string) => dispatch(InviteActions.editStudentInvite(inviteId, integrationId)),
    [dispatch]
  );
}
