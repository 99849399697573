import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@myblueprint-spaces/papier-web/lib/Common/Dialog';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import TextBox from '@myblueprint-spaces/papier-web/lib/Common/TextBox';
import Form from '@myblueprint-spaces/papier-web/lib/Common/Form';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { validateEmail } from '@myblueprint-spaces/modules';
import { ForgotPasswordInternal } from './styles';
import { UserEndpoints } from '@myblueprint-spaces/redux/lib/user';
import { ForgotPasswordProps, ForgotPasswordStateProps } from './types';
import { failToast, successToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';


export default class ForgotPassword extends React.Component<ForgotPasswordProps, ForgotPasswordStateProps> {
  static propTypes = {
    t: PropTypes.func.isRequired,
    email: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    googleReCaptchaProps: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      email: props.email || ''
    };
  }

  componentDidUpdate(prevProps) {
    const { email } = this.props;
    if (prevProps.email !== email) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        email: email || ''
      });
    }
  }

  handleValidate = () => {
    const { email } = this.state;
    return validateEmail(email);
  };

  handleSuccess = () => {
    const { t, onClose } = this.props;

    if (onClose) {
      onClose();
    }

    successToast(t, t('ResetPassword.Common:Reset.ResetSent').toString());
  };

  handleFail = (e?) => {
    const { t } = this.props;

    failToast(e, t);
  };

  handleSubmit = () => {
    const { googleReCaptchaProps } = this.props;
    const { email } = this.state;
    return googleReCaptchaProps.executeRecaptcha('reset_password')
      .then((token) => UserEndpoints.forgotPassword(email, token));
  };

  render() {
    const { open, t, onClose } = this.props;
    const { email } = this.state;

    const buttonProps = {
      text: t('Admin.Common:ResetPassword'),
      disabled: !validateEmail(email),
      dataTest: 'forgot-password'
    };

    return (
      <Dialog
        title={t('ResetPassword.Common:Forgot.Title')}
        visible={open}
        size="small"
        closeButton
        overlayClickExits
        onClose={onClose}>
        <div>
          <Row>
            <Column
              columns={{ small: 10 }} offset={{ small: 1 }}>
              <ForgotPasswordInternal>
                <Form
                  onSubmit={this.handleSubmit}
                  onValidate={this.handleValidate}
                  button={buttonProps}
                  onSuccess={this.handleSuccess}
                  onFail={this.handleFail}
                  showResult>
                  <HeaderBlock
                    title={t('ResetPassword.Common:Forgot.Title').toString()}
                    subtitle={t('ResetPassword.Common:Forgot.Subtitle').toString()} />
                  <TextBox
                    label={t('Common:UserInfo.Email')}
                    value={email}
                    onChange={(e) => this.setState({ email: e })}
                  />
                </Form>
              </ForgotPasswordInternal>
            </Column>
          </Row>
        </div>
      </Dialog>
    );
  }
}

