import React, { useCallback, useContext, useState } from 'react';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import Form from '@myblueprint-spaces/papier-web/lib/Common/Form';
import TextBox from '@myblueprint-spaces/papier-web/lib/Common/TextBox';
import LinkButton from '@myblueprint-spaces/papier-web/lib/Atoms/LinkButton';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import PasswordTextBox from '@myblueprint-spaces/papier-web/lib/Common/PasswordTextBox';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { CaptchaDisclaimer, LoginFormWrapper, FormWrapper, StyledAsset } from 'views/Login/Shared';
import ForgotPassword from 'views/Login/ForgotPassword';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { EmailInUseFormProps } from './types';
import styled from 'styled-components';
import Image from 'assets/Asset_emailUsed.png';
import { failToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import { useNavigate } from 'react-router-dom';
import { AuthenticationResult } from '@myblueprint-spaces/redux/lib/authentication';
import { AuthenticationContext } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';

const HeaderWrapper = styled.div`
  padding: 0.75rem 0 0;
`;

const EmailInUseForm = (props: EmailInUseFormProps) => {
  const { invite, login, acceptInvite, googleReCaptchaProps } = props;
  const { t } = useTranslation(['Common', 'LoginSignUp.Common', 'EmailInUse.Common']);
  const getFullUrl = useGetFullUrl();
  const activateUrl = getFullUrl('/activate');
  const navigate = useNavigate();
  const { saveToken, clearToken } = useContext(AuthenticationContext);

  const { email } = invite || {};
  const [password, setPassword] = useState({
    value: '',
    valid: true,
    validationText: t('Common:Validations.InvalidCredentials')
  });
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);

  const handleValidate = () => {
    let valid = true;

    if (!password.value) {
      valid = false;
      setPassword((current) => update(current, {
        valid: { $set: valid }
      }));
    }

    return valid;
  };

  const handleSubmit = useCallback(() => {
    return googleReCaptchaProps!.executeRecaptcha('login')
      .then((token) => login(email as string, password.value, token)
        .then(async (sessionToken) => {
          clearToken();
          sessionToken && saveToken(sessionToken);

          await acceptInvite!();
          navigate('/dashboard');
        })
        .catch((e) => {
          if (e?.resultCode === AuthenticationResult.Fail) {
            failToast(e, t, t('Common:Validations.InvalidCredentials').toString());
            throw e;
          }
        }));
  }, [googleReCaptchaProps, login, email, password.value, acceptInvite, navigate, t]);

  const onClick = useCallback(() => navigate(activateUrl), [navigate, activateUrl]);

  const label = (
    <LinkButton
      type="body2"
      color="primary1"
      weight="regular"
      onClick={() => setForgotPasswordDialog(true)}
      dataTest="email-in-use">
      {t('LoginSignUp.Common:UserInfo.ForgotPassword')}
    </LinkButton>
  );

  return (
    <LoginFormWrapper>
      <HeaderWrapper>
        <Row collapse expanded>
          <Column columns={{ medium: 3, large: 4 }} collapse={false}>
            <Button
              title={t('Common:Actions.Back')}
              icon="left-arrow"
              size="medium"
              rounded
              color="secondary"
              onClick={onClick}
              dataTest="left-arrow"
            />
          </Column>
        </Row>
      </HeaderWrapper>
      <FormWrapper largeForMobile>
        <Form
          onSubmit={handleSubmit}
          onValidate={handleValidate}
          button={{
            text: t('Common:Actions.Continue'),
            dataTest: 'continue'
          }}
          showResult>
          <HeaderBlock
            image={<StyledAsset src={Image} alt="" aria-hidden="true" />}
            title={{ children: t('EmailInUse.Common:EmailInUse.Title'), color: 'danger1' }}
            subtitle={t('EmailInUse.Common:EmailInUse.Subtitle').toString()} />
          <TextBox
            label={t('EmailInUse.Common:EmailInUse.RegisteredEmail')}
            size="medium"
            value={email}
            disabled
            dataTest="email"
          />
          <PasswordTextBox
            label={t('EmailInUse.Common:EmailInUse.AccountPassword')}
            labelRight={label}
            size="medium"
            password={password}
            onChange={(e) => setPassword((state) =>
              update(state, {
                value: { $set: e },
                valid: { $set: true }
              }))}
            validation={
              password.valid
                ? null
                : {
                  state: 'error',
                  message: t('Common:Validations.Password')
                }
            }
            dataTest="password"
          />
        </Form>
        <ForgotPassword
          email={email as string}
          open={forgotPasswordDialog}
          onClose={() => setForgotPasswordDialog(false)}
        />
      </FormWrapper>
      <CaptchaDisclaimer />
    </LoginFormWrapper>
  );
};

export default EmailInUseForm;
