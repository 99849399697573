import Dialog from '@myblueprint-spaces/papier-web/lib/Common/Dialog';
import RowColumn from '@myblueprint-spaces/papier-web/lib/Common/Grid/RowColumn';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import SessionExpired from 'assets/Asset_link_expired.png';
import { DialogContentWrapper, StyledAsset } from './styles';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';

export interface ISessionExpiredDialog {
  open: boolean;
  handleOnClick: () => void;
}

export const SessionExpiredDialog = ({ open, handleOnClick }: ISessionExpiredDialog) => {
  const { t } = useTranslation(['Account.Common']);
  const { isSmall } = useContext(WindowSizeContext);

  return (
    <Dialog
      size="small"
      closeButton={false}
      visible={open}
      title={t('Account.Common:SessionExpired.Title')}
      overlayClickExits={false}>
      <DialogContentWrapper>
        <RowColumn align="center">
          <StyledAsset src={SessionExpired} alt="" />
        </RowColumn>
        <HeaderBlock
          title={{ type: isSmall ? 'body2' : 'header4', weight: 'bold', children: t('Account.Common:SessionExpired.Title') }}
          subtitle={{ type: isSmall ? 'subtitle1' : 'body2', color: 'black3',  children: t('Account.Common:SessionExpired.Subtitle') }}
        />
        <RowColumn align="center">
          <Button expanded text={t('Account.Common:SessionExpired.GoToLogin')} dataTest="go-to-Login" onClick={handleOnClick} />
        </RowColumn>
      </DialogContentWrapper>
    </Dialog>
  );
};

export default withErrorBoundaries(SessionExpiredDialog);
