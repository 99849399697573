import * as PropTypes from 'prop-types';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IState } from '@myblueprint-spaces/redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { UserSelectors, UserProfile } from '@myblueprint-spaces/redux/lib/user';
import { getLanguageString } from '@myblueprint-spaces/redux/lib/resources';

/**
 * Updates the HTML's root `lang` attribute in response to changes in the language
 */
type LangProps = WithTranslation & { userProfile?: UserProfile };

const withHtmlLang = <P extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) => {
  class HtmlLangHoc extends React.Component<LangProps> {
    public static propTypes = {
      t: PropTypes.func,
      i18n: PropTypes.object,
      userProfile: PropTypes.object
    };
    public constructor(props: LangProps) {
      super(props);

      this.updateHtmlLang = this.updateHtmlLang.bind(this);
    }
    public componentDidMount() {
      const { i18n, userProfile } = this.props;
      if (userProfile) {
        i18n.changeLanguage(getLanguageString(userProfile.culture));
      }
      i18n.on('initialized', this.updateHtmlLang);
      i18n.on('languageChanged', this.updateHtmlLang);
    }

    public componentDidUpdate(prevProps: LangProps) {
      const { i18n, userProfile } = this.props;

      if (userProfile && (!prevProps.userProfile || userProfile.culture !== prevProps.userProfile.culture)) {
        i18n.changeLanguage(getLanguageString(userProfile.culture));
      }
    }

    public updateHtmlLang = (language: string) => {
      document.documentElement.setAttribute('lang', language);
    };

    public componentWillUnmount() {
      const { i18n } = this.props;
      i18n.off('initialized', this.updateHtmlLang);
      i18n.off('languageChanged', this.updateHtmlLang);
    }

    public render() {
      const { t, i18n, tReady, userProfile, ...rest } = this.props;
      return <WrappedComponent {...rest as P} />;
    }
  }

  const mapStateToProps = (state: IState) => ({
    userProfile: UserSelectors.getUserProfile(state)
  });

  return compose(withTranslation(), connect(mapStateToProps))(HtmlLangHoc as React.ComponentType<LangProps>);
};

export default withHtmlLang;
