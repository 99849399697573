import { AccountType } from '@myblueprint-spaces/appstate';
import React from 'react';
import { failToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';
import { SchoolDisplayScope } from '@myblueprint-spaces/redux/lib/scopes';
import { TFunction } from 'i18next';

export const getAccountTypeIcon = (accountType) => {
  switch (accountType) {
    case AccountType.Teacher:
      return 'bus';
    case AccountType.Family:
      return 'house';
    case AccountType.Student:
      return 'people';
    case AccountType.Admin:
      return 'archive';
    default: return;
  }
};

export const getAccountTypeText = (t, accountType) => {
  switch (accountType) {
    case AccountType.Teacher:
      return t('Common:Teacher');
    case AccountType.Family:
      return t('Common:Family');
    case AccountType.Student:
      return t('Common:Student');
    case AccountType.Admin:
      return t('Common:Administrator');
    default: return;
  }
};

export const createNewTeacher = (
  usedSchool: React.MutableRefObject<SchoolDisplayScope | undefined> | undefined,
  createTeacher: (schoolId: string | undefined) => Promise<void>,
  spacesUrl: string,
  t: TFunction,
  storedToken: string | null
) => {
  return createTeacher(usedSchool?.current?.id).then(() => {
    const { href: spacesAccountClasslistUrl, searchParams } = new URL('/teacher/class-list', spacesUrl);
    storedToken && searchParams.append('sessionToken', storedToken);
    searchParams.append('switchAccountDialog', 'true');
    // eslint-disable-next-line no-restricted-properties
    window.location.href = spacesAccountClasslistUrl + `?${searchParams.toString()}`;
  })
    .catch((e) => failToast(e, t));
};
