import React from 'react';
import NotFound from './NotFound';
import UserHooks from 'modules/hooks/redux/user';

const NotFoundContainer = () => {
  const user = UserHooks.useUser();

  return (
    <NotFound user={user} />
  );
};

export default NotFoundContainer;

