import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { UpdateUserProfile, UserActions } from '@myblueprint-spaces/redux/lib/user';

export default function useUpdateUserProfile() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  return React.useCallback(
    (userProfileInfo: UpdateUserProfile) => dispatch(UserActions.updateUserProfile(userProfileInfo)),
    [dispatch]
  );
}
