import React, { useEffect } from 'react';
import { getAccountTypeFromExternalUserType, getAccountTypeFromScopeType } from 'modules/helpers/accountTypeHelper';
import SchoolHooks from 'modules/hooks/redux/schools';
import SignUpHooks from 'modules/hooks/redux/signup';
import AdditionalInformation from './AdditionalInformation';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GoogleReCaptchaPropsType } from 'views/Login/Shared';
import { AccountType } from '@myblueprint-spaces/appstate';
import { ScopeType } from '@myblueprint-spaces/redux/lib/scopes';
import { ExternalUserType } from '@myblueprint-spaces/redux/lib/authentication';
import AuthenticationHooks from 'modules/hooks/redux/authentication';

const AdditionalInformationContainer = ({ googleReCaptchaProps }: { googleReCaptchaProps: GoogleReCaptchaPropsType }) => {
  const externalUserData = SignUpHooks.useExternalUserData();
  const relatedRecords = SignUpHooks.useRelatedRecords();
  const signUpSchools = SchoolHooks.useSignUpSchools();
  const loadSignUpSchools = SchoolHooks.useLoadSignUpSchools();
  const resetSettings = AuthenticationHooks.useResetSettings();

  const { email, firstName, lastName, schoolId: externalSchoolId } = externalUserData || {};
  const schoolFromExternalId = externalSchoolId ? relatedRecords?.filter((record) => record.scope.ownerId === externalSchoolId)?.[0]?.scope : null;
  const invites = relatedRecords?.filter((record) => record.scope.ownerId !== externalSchoolId);
  const hasInvites = (invites || []).length > 0;
  const inviteRecords = invites?.map((record) => ({
    displayName: record?.scope?.displayName,
    id: record?.scope?.ownerId,
    accountType: getAccountTypeFromScopeType(record?.scopeType as ScopeType),
    isDistrict: record?.scope?.scopeType === ScopeType.District
  }));

  // Check to see if there is any accountTypes tied to the user's relatedRecords
  const nonNullRelatedRecordScopeTypes = relatedRecords?.filter((r) => r.scopeType !== null);
  const accountTypeFromRelatedRecords = (nonNullRelatedRecordScopeTypes || []).length > 0 ? getAccountTypeFromScopeType(nonNullRelatedRecordScopeTypes?.[0]?.scopeType) : undefined;

  const providedSSOAccountType = (externalUserData?.userType === ExternalUserType.None || (externalUserData?.userType === ExternalUserType.RegularSchoolAdmin && !schoolFromExternalId))
    ? undefined : getAccountTypeFromExternalUserType(externalUserData?.userType);

  const { displayName, ownerId } = schoolFromExternalId || {};
  let schoolAndDistrictList: { displayName: string, id: string, accountType?: AccountType, isDistrict?: boolean }[] = [];
  if (displayName && ownerId) schoolAndDistrictList.push({ displayName: displayName, id: ownerId });
  if (inviteRecords) schoolAndDistrictList = schoolAndDistrictList.concat(inviteRecords);

  useEffect(() => {
    if (signUpSchools === undefined) loadSignUpSchools();
  }, [signUpSchools]);

  const allProps = {
    googleReCaptchaProps,
    email: email as string,
    firstName,
    lastName,
    schoolAndDistrictList,
    providedSSOAccountType,
    schoolFromExternalId,
    hasInvites,
    externalUserData,
    signUpSchools,
    accountTypeFromRelatedRecords,
    resetSettings
  };

  return <AdditionalInformation {...allProps} />;
};

export default withGoogleReCaptcha(AdditionalInformationContainer as React.FunctionComponent);
