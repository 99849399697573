import React, { FunctionComponent, useContext } from 'react';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import Pill from '@myblueprint-spaces/papier-web/lib/Common/Pill';
import { StyledSchool } from './styles';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { SchoolSelectionRowProps } from './types';
import { useTranslation } from 'react-i18next';
import { useRovingTabIndexProps } from '@myblueprint-spaces/react-roving-tabindex';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { TextSkeleton } from '@myblueprint-spaces/papier-web/lib/Common/LoadingSkeleton/TextSkeleton/TextSkeleton';
import { PillSkeletonWrapper } from 'components/Shared';

const SchoolSelectionRow = ({ school, isSelected, onClick, studentCount, staffCount }: SchoolSelectionRowProps) => {
  const { displayName: name } = school;
  const { t } = useTranslation(['Common', 'Admin.Common']);
  const { isSmall } = useContext(WindowSizeContext);

  const memoizedOnClick = React.useCallback(() => {
    onClick(school);
  }, [onClick, school]);

  const itemProps = useRovingTabIndexProps<HTMLLIElement>({
    isSelected: false,
    disabled: false,
    enterClicks: true,
    actionOnClick: memoizedOnClick
  });

  const pillLoadingSkeleton = (
    <PillSkeletonWrapper>
      <CovalentGrid verticalAlign="middle">
        <Icon size="small" type="people" color="grey1" />
        <TextSkeleton width="5rem" />
      </CovalentGrid>
    </PillSkeletonWrapper>
  );

  return (
    <StyledSchool role="option" isSelected={isSelected as boolean} small={isSmall} {...itemProps} aria-selected={isSelected} data-test="school-row">
      <Row align={isSmall ? 'left' : 'center'} verticalAlign="middle" span>
        <Column columns={{ small: 12, medium: 'expand' }} collapse={isSmall}>
          <Typography as="span" color={isSelected ? 'primary1' : 'black1'} ellipsis style={{ display: 'block' }}>{name}</Typography>
        </Column>
        {school.id ? <React.Fragment>
          <Column shrink>
            {studentCount !== undefined
              ? <Pill color="primary" icon={{ size: 'small', type: 'people' }} typographyProps={{ type: 'body2' }} text={t('Common:XStudent', { count: studentCount ?? 0 }) as string} />
              : pillLoadingSkeleton}
          </Column>
          <Column shrink collapse={false}>
            {staffCount !== undefined ? <Pill color="primary" icon={{ size: 'small', type: 'people' }} typographyProps={{ type: 'body2' }} text={t('Admin.Common:Count.XStaffWithCount', { count: staffCount ?? 0 }) as string} />
              : pillLoadingSkeleton}
          </Column>
        </React.Fragment> : null}
      </Row>
    </StyledSchool>
  );
};

export default withErrorBoundaries(SchoolSelectionRow as FunctionComponent<SchoolSelectionRowProps>);
