import React from 'react';
import AccountSwitch from './AccountSwitch';
import { AccountType } from '@myblueprint-spaces/appstate';
import UserHooks from 'modules/hooks/redux/user';
import StaffHooks from 'modules/hooks/redux/staff';
import { AccountSwitchContainerProps, AccountSwitchProps } from './types';

const AccountSwitchContainer = (props: AccountSwitchContainerProps) => {
  const { associations } = props;
  const switchAccount = UserHooks.useSwitchAccount();
  const createTeacher = StaffHooks.useCreateTeacher();
  const accountTypes = !associations ? [] : Object.keys(associations).map((associationType) => {
    const currentAssociations = associations[associationType];

    if (currentAssociations && (!Array.isArray(currentAssociations) || currentAssociations.length > 0)){
      switch (associationType.toLowerCase()) {
        case 'students':
          return AccountType.Student;
        case 'teachers':
          return AccountType.Teacher;
        case 'family':
          return AccountType.Family;
        case 'admin':
          return AccountType.Admin;
      }
    }
  }).filter((a) => typeof a!== 'undefined');

  if (!accountTypes.includes(AccountType.Teacher)) accountTypes.push(AccountType.Teacher);

  const allProps = {
    ...props,
    accountTypes,
    switchAccount,
    createTeacher
  };

  return <AccountSwitch {...allProps as AccountSwitchProps} />;
};

export default AccountSwitchContainer;
