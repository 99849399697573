import React from 'react';
import PropTypes from 'prop-types';
import { CustomRow, CustomColumn } from './styles';

function FormWrapper({ children, largeForMobile }) {
  return (
    <CustomRow align="center" expanded collapse>
      <CustomColumn columns={{ small: largeForMobile ? 10 : 8, medium: 4 }}>
        {children}
      </CustomColumn>
    </CustomRow>
  );
}

FormWrapper.propTypes = {
  children: PropTypes.any,
  largeForMobile: PropTypes.bool
};

export default FormWrapper;



