import React from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import LoginHooks from 'modules/hooks/redux/login';
import EmailInUseForm from './EmailInUseForm';
import { EmailInUseFormProps } from './types';
import InviteHooks from 'modules/hooks/redux/invites';
import SignUpHooks from 'modules/hooks/redux/signup';

const EmailInUseFormContainer = (props: Partial<EmailInUseFormProps>) => {
  const login = LoginHooks.useLogin();
  const acceptInvite = InviteHooks.useAcceptInvite();
  const inviteId = SignUpHooks.useInviteId();
  const invite = InviteHooks.useInviteWithId(inviteId);

  const allProps = {
    login,
    acceptInvite,
    invite,
    ...props
  } as EmailInUseFormProps;

  return (
    <EmailInUseForm {...allProps} />
  );
};

export default withGoogleReCaptcha(EmailInUseFormContainer);
