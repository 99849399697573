import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { DatadogClientConfig } from '@myblueprint-spaces/redux/lib/globals';

export function initializeDatadog(config: DatadogClientConfig) {
  const { clientToken, site, service } = config;
  const version = process.env.REACT_APP_VERSION;
  const usedService = `${service}_admin`;

  datadogRum.init({ ...config, trackFrustrations: true, version, service: usedService });
  datadogLogs.init({
    clientToken: clientToken,
    site: site,
    service: usedService,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    version
  });
}
