import useLogin from './useLogin';
import useLoadSettings from './useLoadSettings';
import useResetSettings from './useResetSettings';
import useExternalAuthenticate from './useExternalAuthenticate';
import useSettings from './useSettings';
import useLoadIdpSettings from './useLoadIdpSettings';
import useSaveAuthenticationData from './useSaveAuthenticationData';
import useTokenRefresh from './useTokenRefresh';
import useExchangeToken from './useExchangeToken';

const AuthenticationHooks = {
  useLogin,
  useLoadSettings,
  useResetSettings,
  useExternalAuthenticate,
  useSettings,
  useLoadIdpSettings,
  useSaveAuthenticationData,
  useTokenRefresh,
  useExchangeToken
};

export default AuthenticationHooks;
