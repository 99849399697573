import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { InviteActions, NewStudentAdminInvite } from '@myblueprint-spaces/redux/lib/invites';

export default function useInviteStudentAdmin() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (invitesInfo: NewStudentAdminInvite) => dispatch(InviteActions.inviteStudentAdmin(invitesInfo)),
    [dispatch]
  );
}
