import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';

const CustomSwitchRoute = ({ children }) => (
  <Routes>
    {children}
    <Route path="*" element={<Navigate to="/404" replace />} />
  </Routes>
);

CustomSwitchRoute.propTypes = {
  children: PropTypes.any
};

export default CustomSwitchRoute;
