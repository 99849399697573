import styled from 'styled-components';

export const StyledAsset = styled.img`
  width: 10rem;
  height: 10rem;
  margin: 0.5rem 0;
`;

export const DialogContentWrapper = styled.div`
  padding: 2.5rem;
`;
