import React, { FunctionComponent, useContext } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import { Row, RowColumn, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { PopoverItem } from '@myblueprint-spaces/papier-web/lib/Common/Popover';
import { AccountTypeWrapper, ButtonContent, ContentWrapper, StyledLink, Container } from './styles';
import { AccountType } from '@myblueprint-spaces/appstate';
import { getAccountTypeIcon, getAccountTypeText, createNewTeacher } from './helpers';
import { getAccountTypeStr, hasTeacherAccountOnSchool } from 'modules/helpers/accountTypeHelper';
import AccountTypeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AccountTypeContext';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { useTranslation } from 'react-i18next';
import CurrentSchoolContext from 'contexts/CurrentSchoolContext';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import { AccountSwitchProps } from './types';
import { StaffUser } from '@myblueprint-spaces/redux/lib/staff';
import { AuthenticationContext } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';

const AccountSwitch = (props: AccountSwitchProps) => {
  const { associations, accountTypes, closeParent, switchAccount, createTeacher, usedSchool } = props;
  const currentAccountType = useContext(AccountTypeContext);
  const { storedToken } = useContext(AuthenticationContext);
  const { t } = useTranslation(['Common', 'Admin.Common']);
  const { school } = useContext(CurrentSchoolContext);
  const hasTeacher = school && hasTeacherAccountOnSchool({ teachers: associations.teachers } as Partial<StaffUser>, school?.id);
  const { spacesUrl, adminUrl } = React.useContext(AppConfigContext)!.appLinks;
  const { href: adminDashboardUrl } = new URL('/dashboard', adminUrl);

  const getDataTestStr = (accountType) => {
    switch (accountType) {
      case AccountType.Teacher:
        return 'linkTo-teacher-account';
      case AccountType.Student:
        return 'linkTo-student-account';
      case AccountType.Family:
        return 'linkTo-family-account';
      case AccountType.Admin:
        return 'add-teacher';
    }
  };


  const [hoveredState, setHoveredState] = React.useState<{
    teacher?: boolean;
    family?: boolean;
    student?: boolean;
    admin?: boolean;
  }>({
    teacher: false,
    family: false,
    student: false,
    admin: false
  });

  const updateHoverState = (accountType, value) => {
    setHoveredState(update(hoveredState, {
      $set: {
        [accountType]: value
      },
    })
    );
  };

  const columns = accountTypes.length <= 2 ? 12 : (accountTypes.length - 1) % 2 === 0 || (accountTypes.length === 3 && !hasTeacher) ? 6 : 4;

  const getLinkProps = (accountTypeStr) => ({
    onFocus: () => updateHoverState(accountTypeStr, true),
    onBlur: () => updateHoverState(accountTypeStr, false)
  });
  let firstAcc = true;

  return (
    <AccountTypeWrapper>
      <RowColumn expanded>
        <ContentWrapper>
          <Typography type="body2" color="black2" style={{ marginBottom: '0.5rem' }}>{ currentAccountType === AccountType.Teacher ? t('Common:AccountType.Switch.Teacher') : t('Common:AccountType.Switch')}</Typography>
          <Row expanded collapse>
            {
              accountTypes.map((accountType, index) => {
                if (currentAccountType !== accountType) {
                  const accountTypeStr = getAccountTypeStr(accountType);
                  const spacesRedirectionUrl = new URL(accountType === AccountType.Teacher ? `/${accountTypeStr}/class-list` : accountTypeStr, spacesUrl);
                  storedToken && spacesRedirectionUrl.searchParams.append('sessionToken', storedToken);

                  const dataTest = getDataTestStr(accountType);
                  const result = (
                    <Column key={index} columns={{ small: columns }}>
                      <Container noMargin={firstAcc}>
                        <PopoverItem
                          type="custom"
                          handleDeactivate={closeParent}>
                          <StyledLink
                            {...getLinkProps(accountTypeStr)}
                            onClick={ () => {
                              if (!hasTeacher && accountType === AccountType.Teacher) {
                                createNewTeacher(usedSchool, createTeacher, spacesUrl, t, storedToken);
                              } else {
                                switchAccount().then(() => {
                                  // eslint-disable-next-line no-restricted-properties
                                  window.location.href = accountType === AccountType.Admin ? adminDashboardUrl : spacesRedirectionUrl.href;
                                });
                              }
                            }}
                            dataTest={dataTest}>
                            <ButtonContent
                              onMouseEnter={() => updateHoverState(accountTypeStr, true)}
                              onMouseLeave={() => updateHoverState(accountTypeStr, false)}>
                              <RowColumn align="center">
                                <Icon
                                  type={getAccountTypeIcon(accountType) as string}
                                  color={hoveredState[accountTypeStr] ? 'primary1' : 'black3'} />
                              </RowColumn>
                              <RowColumn align="center">
                                <Typography
                                  type="subtitle1"
                                  color={hoveredState[accountTypeStr] ? 'primary1' : 'black3'}>
                                  {getAccountTypeText(t, accountType)}
                                </Typography>
                              </RowColumn>
                            </ButtonContent>
                          </StyledLink>
                        </PopoverItem>
                      </Container>
                    </Column>
                  );
                  firstAcc = false;
                  return result;
                }
              })}
          </Row>
        </ContentWrapper>
      </RowColumn>
      <PopoverItem type="divider" />
    </AccountTypeWrapper>
  );
};

AccountSwitch.propTypes = {
  accountTypes: PropTypes.arrayOf(PropTypes.oneOf([
    AccountType.Student,
    AccountType.Teacher,
    AccountType.Family,
    AccountType.Admin
  ])),
  closeParent: PropTypes.func.isRequired,
  switchAccount: PropTypes.func.isRequired,
  createTeacher: PropTypes.func.isRequired,
  usedSchool: PropTypes.object
};

export default withErrorBoundaries(AccountSwitch as FunctionComponent<AccountSwitchProps>);
