import { useCallback } from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { StudentAdminActions } from '@myblueprint-spaces/redux/lib/students';

export default function useSetStudentStatus() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();

  return useCallback((studentIds: string[], active: boolean) => dispatch(StudentAdminActions.setStudentStatus(studentIds, active)), []);
}
