import React, { useContext } from 'react';
import { SignUpUserData } from '@myblueprint-spaces/redux/lib/signUp';
import SignUpHooks from 'modules/hooks/redux/signup';
import { getAccountTypeFromScopeType } from 'modules/helpers/accountTypeHelper';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import { ScopeType } from '@myblueprint-spaces/redux/lib/scopes';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import InviteHooks from 'modules/hooks/redux/invites';
import UserHooks from 'modules/hooks/redux/user';
import { useTranslation } from 'react-i18next';
import { handleError, associationsRedirectHelper, accountRedirectHelper } from './helpers';
import { useNavigate } from 'react-router-dom';
import AuthenticationHooks from 'modules/hooks/redux/authentication';
import { AuthenticationContext } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';

export function useCreateSSOUser() {
  const saveUserData = SignUpHooks.useSaveUserData();
  const createUser = SignUpHooks.useCreateUser();
  const getFullUrl = useGetFullUrl();
  const { appLinks: { spacesUrl } } = React.useContext(AppConfigContext)!;
  const inviteId = SignUpHooks.useInviteId();
  const invite = InviteHooks.useInviteWithId(inviteId);
  const loadAssociations = UserHooks.useLoadAssociations();
  const { t } = useTranslation(['Common.Errors', 'Common']);
  const navigate = useNavigate();
  const exchangeToken = AuthenticationHooks.useExchangeToken();
  const { saveToken, clearToken } = useContext(AuthenticationContext);

  return React.useCallback((signUpData: SignUpUserData, captcha?: string) => {
    const accountTypeFromScope = getAccountTypeFromScopeType(signUpData?.scopeType as ScopeType);
    const attemptingLoginUrl = getFullUrl('/email-in-use');
    const missingRecordUrl = getFullUrl('/missing-record');
    const { href: spacesTeacherAccountUrl } = new URL('/teacher', spacesUrl);
    const { href: spacesStudentAccountUrl } = new URL('/student', spacesUrl);
    const { href: spacesFamilyAccountUrl } = new URL('/family', spacesUrl);
    const dashboardUrl = getFullUrl('/dashboard');
    const ssoErrorUrl = getFullUrl('/sso-error');

    saveUserData(signUpData);
    return createUser(undefined, captcha)
      .then(async () => {
        clearToken();
        const token = await exchangeToken();
        saveToken(token);

        if (invite) {
          navigate(dashboardUrl);
        } else {
          if (accountTypeFromScope) {
            accountRedirectHelper(accountTypeFromScope, dashboardUrl, spacesTeacherAccountUrl, spacesStudentAccountUrl, spacesFamilyAccountUrl, navigate);
          } else {
            const userAssociations = await loadAssociations();
            const urls = {
              spacesTeacherAccountUrl,
              spacesStudentAccountUrl,
              spacesFamilyAccountUrl,
              dashboardUrl,
              ssoErrorUrl
            };
            associationsRedirectHelper(userAssociations, urls, t, navigate);
          }
        }
      })
      .catch((err) => handleError(err, missingRecordUrl, attemptingLoginUrl, t, navigate));
  }, [navigate, invite, createUser, t, saveUserData]);
}

export function useSSOLogin() {
  const inviteId = SignUpHooks.useInviteId();
  const invite = InviteHooks.useInviteWithId(inviteId);
  const acceptInvite = SignUpHooks.useAcceptInvite();
  const getFullUrl = useGetFullUrl();
  const dashboardUrl = getFullUrl('/dashboard');
  const navigate = useNavigate();
  const exchangeToken = AuthenticationHooks.useExchangeToken();
  const { saveToken, clearToken } = useContext(AuthenticationContext);

  return React.useCallback(async () => {
    clearToken();
    const token = await exchangeToken();
    saveToken(token);

    if (invite) {
      await acceptInvite();
      navigate(dashboardUrl);
    } else {
      navigate(dashboardUrl);
    }
  },[invite, acceptInvite, navigate, dashboardUrl]);
}
