import { useContext } from 'react';
import { AccountType, getGradeString, sortGrades } from '@myblueprint-spaces/appstate';
import { enumToString, getEnumValues } from '@myblueprint-spaces/modules/lib/enums';
import { AdminRole } from '@myblueprint-spaces/redux/lib/admins';
import { AdminRoleAssociation } from '@myblueprint-spaces/redux/lib/admins/types';
import { Invite, InviteAdminData, InviteType } from '@myblueprint-spaces/redux/lib/invites';
import { SchoolDisplayScope, ScopeType } from '@myblueprint-spaces/redux/lib/scopes';
import { StaffUser } from '@myblueprint-spaces/redux/lib/staff';
import CurrentSchoolAccountInfoContext, { ICurrentSchoolAccountInfoContext } from 'contexts/CurrentSchoolAccountInfoContext';
import CurrentSchoolContext from 'contexts/CurrentSchoolContext';
import { StaffColumn } from 'contexts/UserEditDialogsContext';
import { InviteColumn } from 'pages/StaffManager/components/InvitedTab/components/Shared/types';
import { Grade } from '@myblueprint-spaces/redux/lib/students';
import LoggedUserContext from 'contexts/LoggedUserContext';
import { ExternalUserType } from '@myblueprint-spaces/redux/lib/authentication';

export function getAccountTypeEnum(accountTypeStr: string): AccountType | undefined {
  switch (accountTypeStr.toLowerCase()) {
    case 'teacher':
      return AccountType.Teacher;
    case 'student':
      return AccountType.Student;
    case 'family':
      return AccountType.Family;
    case 'admin':
      return AccountType.Admin;
    default:
      return undefined;
  }
}

export function getAccountTypeStr(accountTypeEnum?: AccountType): string {
  switch (accountTypeEnum) {
    case AccountType.Teacher:
      return enumToString(AccountType, AccountType.Teacher)?.toLowerCase() || '';
    case AccountType.Student:
      return enumToString(AccountType, AccountType.Student)?.toLowerCase() || '';
    case AccountType.Family:
      return enumToString(AccountType, AccountType.Family)?.toLowerCase() || '';
    case AccountType.Admin:
      return enumToString(AccountType, AccountType.Admin)?.toLowerCase() || '';
    default:
      return '';
  }
}

export function getAccountTypeStrCamelCase(accountTypeEnum): string {
  const accountTypeString = getAccountTypeStr(accountTypeEnum);

  return accountTypeString.replace(/./, accountTypeString.charAt(0).toUpperCase());
}

/**
 * The function returns the ScopeType enum value
 * for the corresponding AcccountType enum value
 *
 * @param {AccountType} accountType - AccountType enum value
 *
 * @returns {ScopeType} Returns corresponding ScopeType enum value
 */
export function getScopeTypeFromAccountType(accountType: AccountType): ScopeType | undefined {
  switch (accountType) {
    case AccountType.Student:
      return ScopeType.Student;
    case AccountType.Teacher:
      return ScopeType.Teacher;
    case AccountType.Family:
      return ScopeType.Family;
    case AccountType.Admin:
      return ScopeType.Admin;
    default:
      return undefined;
  }
}

/**
 * The function returns the AcccountType enum value
 * for the corresponding ScopeType enum value
 *
 * @param {ScopeType} scopeType - ScopeType enum value
 *
 * @returns {AccountType} Returns corresponding AccountType enum value
 */
export function getAccountTypeFromScopeType(scopeType?: ScopeType | null): AccountType | undefined {
  switch (scopeType) {
    case ScopeType.Student:
      return AccountType.Student;
    case ScopeType.Teacher:
      return AccountType.Teacher;
    case ScopeType.Family:
      return AccountType.Family;
    case ScopeType.Admin:
      return AccountType.Admin;
    default:
      return undefined;
  }
}

/**
 * The function returns the AccountType enum value
 * for the corresponding ExternalUserType enum value
 *
 * @param {ExternalUserType} externalUserType - ExternalUserType enum value
 *
 * @returns {AccountType} Returns corresponding AccountType enum value
 */

export function getAccountTypeFromExternalUserType(externalUserType?: ExternalUserType): AccountType | undefined {
  switch (externalUserType) {
    case ExternalUserType.Student:
      return AccountType.Student;
    case ExternalUserType.Teacher:
      return AccountType.Teacher;
    case ExternalUserType.Family:
      return AccountType.Family;
    case ExternalUserType.RegularSchoolAdmin:
      return AccountType.Admin;
    default:
      return undefined;
  }
}

/**
 * The function returns if based on the associations provided, the account is District or School
 *
 * @param {AdminRoleAssociation[]} adminAssociations - AdminRoleAssociation array
 *
 * @returns {boolean} Returns true/false for isDistrictAccount
 */
export function isDistrictAccount(adminAssociations: AdminRoleAssociation[]) {
  return !!adminAssociations?.some((assoc) => assoc.scope.scopeType === ScopeType.District);
}

/**
 * The function returns if the invite is for District or School
 *
 * @param {Invite} invite - invite
 *
 * @returns {boolean} Returns true/false for isDistrictInvite
 */
export function isDistrictInvite(invite: Invite) {
  const { data, type: inviteType } = invite || {};

  return inviteType === InviteType.Admin && !!(data as InviteAdminData)?.associations?.some((assoc) => assoc.scopeType === ScopeType.District);
}

/**
 * The function returns if, in the pair Association-School, the account is Super Admin
 *
 * @param {AdminRoleAssociation[]} adminAssociations - AdminRoleAssociation array
 * @param {SchoolDisplayScope} school - school
 *
 * @returns {boolean | undefined} Returns true/false for isSuperAdminAccount or undefined in case of no schoolId
 */
export function isSuperAdminAccount(adminAssociations?: AdminRoleAssociation[], school?: SchoolDisplayScope) {
  if (!adminAssociations) return false;
  const isDistrict = isDistrictAccount(adminAssociations);

  if (!school) {
    if (!isDistrict) return undefined;
    return adminAssociations?.find((assoc) => assoc.scope.scopeType === ScopeType.District)?.role === AdminRole.SuperAdmin;
  } else {
    const checkedScope = isDistrict ? { ownerId: school?.districtId, scopeType: ScopeType.District } : { ownerId: school?.id, scopeType: ScopeType.School };
    return adminAssociations?.find((assoc) => assoc.scope.ownerId === checkedScope?.ownerId && assoc.scope.scopeType === checkedScope?.scopeType)?.role === AdminRole.SuperAdmin;
  }
}

/**
 * The function returns if, the invite is for Super Admin
 *
 * @param {invite} invite - invite
 *
 * @returns {boolean} Returns true/false for isSuperAdminInvite
 */
export function isSuperAdminInvite(invite) {
  const { data, type: inviteType } = invite || {};

  return inviteType === InviteType.Admin && data?.role === AdminRole.SuperAdmin;
}

/**
 * The function returns if the account has an Admin account in the specific school
 *
 * @param {StaffUser} adminAssociations - AdminRoleAssociation array
 * @param {string} schoolId - schoolId
 *
 * @returns {boolean | undefined} Returns true/false for hasAdminAccountOnSchool
 */
export function hasAdminAccountOnSchool(staff: StaffUser, schoolId: string) {
  if (!staff || !schoolId) return false;
  const adminAssoc = staff?.admin?.associations;

  const isDistrict = adminAssoc && isDistrictAccount(adminAssoc);
  return isDistrict || !!adminAssoc?.find((assoc) => assoc.scope.ownerId === schoolId);
}

/**
 * The function returns if the account has a Teacher in the specific school
 *
 * @param {StaffUser} adminAssociations - AdminRoleAssociation array
 * @param {string} schoolId - schoolId
 *
 * @returns {boolean | undefined} Returns true/false for hasTeacherAccountOnSchool
 */
export function hasTeacherAccountOnSchool(staff: Partial<Pick<StaffUser, Required<'teachers'>>>, schoolId: string) {
  if (!staff || !schoolId) return false;

  return staff?.teachers?.some((record) => record.schoolId === schoolId);
}

/**
 * The function returns if the account is Teacher based on the highest association
 *
 * @param {StaffUser} adminAssociations - AdminRoleAssociation array
 * @param {string} schoolId - schoolId
 *
 * @returns {boolean | undefined} Returns true/false for isTeacher or undefined in case of no schoolId
 */
export function isTeacherAccount(staff?: StaffUser, schoolId?: string) {
  if (!staff) return undefined;

  if (!schoolId) {
    // if looking at "All Schools", if there's an admin record, we consider not a Teacher
    return !staff?.admin;
  }
  return !(staff?.admin?.associations?.some((assoc) => assoc.scope.ownerId === schoolId)) && hasTeacherAccountOnSchool(staff, schoolId);
}

/**
 * The function returns if the invite is for Teacher
 *
 * @param {invite} invite - invite
 *
 * @returns {boolean | undefined} Returns true/false for isTeacher or undefined in case of no schoolId
 */
export function isTeacherInvite(invite?: Invite) {
  const { type: inviteType } = invite || {};

  return inviteType === InviteType.SchoolTeacher;
}

export function getAdminAssociationOnSchool(associations, schoolId) {
  return associations.find(({ scope: { ownerId, scopeType } }) => ownerId === schoolId && scopeType === ScopeType.School);
}

export enum PERMISSION_FEATURES {
  RESETPASS = 0,
  DELETE,
  EDIT,
  EDITROLE,
  RESENDINVITE,
  DELETEINVITE,
  CHANGEACTIVESTATUS,
}

export type PermissionCheck = {
  school: SchoolDisplayScope;
  loggedUserSuperInSchool: boolean;
  accountCheckedSuperInSchool: boolean;
  loggedUserIsDistrict: boolean;
  accountCheckedIsDistrict: boolean;
  invite?: InviteColumn;
  staff?: StaffColumn;
  ignoreTeacher?: boolean;
}

export const checkPermissionCondition = ({ school, loggedUserSuperInSchool, accountCheckedSuperInSchool,
  loggedUserIsDistrict, accountCheckedIsDistrict, invite, staff, ignoreTeacher = false }: PermissionCheck) => {
  if (!ignoreTeacher) {
    const isTeacher = invite ? isTeacherInvite(invite) : isTeacherAccount(staff, school?.id);
    // Since this system cannot be accessed by Teacher-role-only, anyone can delete (and edit/reset-pass) a Teacher
    // If logged user is district and superadmin we don't need to check for schools, because they will be superadmin in all of them
    if (isTeacher) return true;
  }

  // both districts and logged is SA
  if (loggedUserIsDistrict && accountCheckedIsDistrict) {
    return !accountCheckedSuperInSchool;
  }
  // if falsed last condition and staff id District, logged user cannot manage it
  if (accountCheckedIsDistrict) return false;

  // if falsed till here,
  return loggedUserIsDistrict || loggedUserSuperInSchool && !accountCheckedSuperInSchool;
};

/**
 * @param feature PERMISSION_FEATURES
 * @param schoolId schoolId
 * @param accountInfo basically the entire obj useContext(CurrentSchoolAccountInfoContext)
 * @param staff entire staff we want to run the feature on
 * @returns true/false
 */
export const checkPermission = (
  feature: PERMISSION_FEATURES,
  { isSuperAdmin, isDistrict, adminAssociations }: ICurrentSchoolAccountInfoContext,
  availableSchools: SchoolDisplayScope[],
  school?: SchoolDisplayScope | null,
  staff?: StaffColumn,
  invite?: InviteColumn,
  isInactive?: boolean
) => {
  if (!staff && !invite) return false;

  const { admin, schools: staffSchools } = staff || {};
  const { data, schools: inviteSchools } = invite || {};
  const checkedSchoolIds = staffSchools ? staffSchools.map((s) => s.id) : inviteSchools?.map((s) => s.id);
  const loggedSchoolIds = availableSchools.map((s) => s.id);

  if (!inviteSchools && !staffSchools) return false;

  let assocBeingChecked;
  let schools;
  let accountCheckedIsDistrict;
  let isInviteSuperAdmin = false;

  if (staff) {
    assocBeingChecked = admin?.associations;
    schools = staffSchools;
    accountCheckedIsDistrict = assocBeingChecked && isDistrictAccount(assocBeingChecked);
  } else {
    assocBeingChecked = (data as InviteAdminData)?.associations;
    schools = inviteSchools;
    accountCheckedIsDistrict = isDistrictInvite(invite as Invite);
    isInviteSuperAdmin = isSuperAdminInvite(invite);
  }

  // if both are districts, in order to even proceed, logged user must be SA
  if (isDistrict && accountCheckedIsDistrict && !isSuperAdmin) return false;

  switch (feature) {
    case PERMISSION_FEATURES.RESENDINVITE:
    case PERMISSION_FEATURES.DELETEINVITE:
    case PERMISSION_FEATURES.RESETPASS:
    case PERMISSION_FEATURES.DELETE:
    case PERMISSION_FEATURES.CHANGEACTIVESTATUS:
    case PERMISSION_FEATURES.EDIT: {
      if (school) {
        if (feature === PERMISSION_FEATURES.CHANGEACTIVESTATUS && school.rostered) return false;
        const { id: currentSchoolId } = school;
        // if the accounts are in different schools they cannot interact
        if (loggedSchoolIds.includes(currentSchoolId) && checkedSchoolIds?.includes(currentSchoolId)) {
          const accountCheckedSuperInSchool = staff ? isSuperAdminAccount(assocBeingChecked, school) : isInviteSuperAdmin;

          return checkPermissionCondition({
            school, loggedUserSuperInSchool: isSuperAdmin, accountCheckedSuperInSchool: !!accountCheckedSuperInSchool,
            loggedUserIsDistrict: isDistrict, accountCheckedIsDistrict: !!accountCheckedIsDistrict, invite, staff
          });
        }
        return false;
      } else {
        if (feature === PERMISSION_FEATURES.CHANGEACTIVESTATUS && !(staff && hasStaffActiveTeacherAccount(staff, isInactive))) return false;
        return !!schools?.some((s) => {
          const { id: sId } = s;
          // if the accounts are in different schools/districts they cannot interact
          if (loggedSchoolIds.includes(sId)) {
            const currentLoggedUserIsSuperAdmin = isSuperAdminAccount(adminAssociations, s);
            const accountBeingCheckedIsSuperAdmin = staff ? isSuperAdminAccount(assocBeingChecked, s) : isInviteSuperAdmin;

            if (feature === PERMISSION_FEATURES.CHANGEACTIVESTATUS && s.rostered) return false;

            return checkPermissionCondition({
              school: s, loggedUserSuperInSchool: !!currentLoggedUserIsSuperAdmin, accountCheckedSuperInSchool: !!accountBeingCheckedIsSuperAdmin,
              loggedUserIsDistrict: isDistrict, accountCheckedIsDistrict: !!accountCheckedIsDistrict, invite, staff
            });
          }
          return false;
        });
      }
    }
    case PERMISSION_FEATURES.EDITROLE: {
      if (school) {
        const { id: currentSchoolId } = school;
        // if the accounts are in different schools they cannot interact
        if (loggedSchoolIds.includes(currentSchoolId) && checkedSchoolIds?.includes(currentSchoolId)) {
          const accountCheckedSuperInSchool = staff ? isSuperAdminAccount(assocBeingChecked, school) : isInviteSuperAdmin;

          // LoggedUser can only alter role of an account, if permissions remains the same after changing the role
          return checkPermissionCondition({
            school, loggedUserSuperInSchool: isSuperAdmin, accountCheckedSuperInSchool: !!accountCheckedSuperInSchool,
            loggedUserIsDistrict: isDistrict, accountCheckedIsDistrict: !!accountCheckedIsDistrict, invite, staff
          })
            && checkPermissionCondition({
              school, loggedUserSuperInSchool: isSuperAdmin, accountCheckedSuperInSchool: !accountCheckedSuperInSchool,
              loggedUserIsDistrict: isDistrict, accountCheckedIsDistrict: !!accountCheckedIsDistrict, invite, staff
            });
        }
        return false;
      }

      // EDITROLE should always be used in a context of a school never "All Schools", if !school it means this is a District checked account
      // if the account being checked is district, nobody can change their role
      return false;
    }
    default:
      return false;
  }
};

export const usePermissions = (feature, staff?: StaffColumn, invite?: InviteColumn) => {
  const { school } = useContext(CurrentSchoolContext);
  const accountInfo = useContext(CurrentSchoolAccountInfoContext);
  const { availableSchools } = useContext(LoggedUserContext);

  return checkPermission(feature, accountInfo, availableSchools, school, staff, invite);
};

export const getAvailableGrades = (t, numberOnly = false, shortNames = false) => {
  return getEnumValues(Grade)
    .filter((gradeFlag) => gradeFlag > Grade.None && (gradeFlag !== Grade.Other && gradeFlag !== Grade.Toddler))
    .sort(sortGrades)
    .map((gradeFlag) => ({
      label: getGradeString(gradeFlag, t, numberOnly, shortNames),
      value: gradeFlag,
    }));
};

export const hasStaffActiveTeacherAccountOnSchool = (staff: Partial<Pick<StaffUser, Required<'teachers'>>>, schoolId: string) => {
  if (!staff || !schoolId) return false;

  return staff?.teachers?.some((record) => record.schoolId === schoolId && record.enrolled && record.approved);
};

export const hasStaffActiveTeacherAccount = (data: StaffColumn, isInactive?: boolean) => {
  if (isInactive) return true;

  return data.schools?.some((school) => hasStaffActiveTeacherAccountOnSchool(data, school.id)) ?? false;
};

export const cleanAssociationName = (name) => name.replaceAll(/[.#?/\\%`']/g, '').split(' ').join('_').toLowerCase();
