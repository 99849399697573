import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import FakeDropdown from './FakeDropdown';
import { FakeDropdownContainerProps, FakeDropdownProps } from './types';

const FakeDropdownContainer = (props: FakeDropdownContainerProps) => {
  const { selectorFunction, refreshOption, sortFunction, ...rest } = props;
  const { disabled } = rest;
  const options = refreshOption ? selectorFunction?.(refreshOption)?.sort(sortFunction) : selectorFunction?.()?.sort(sortFunction);

  // Selector _must_ be called with every render, but we can discard the result and memoize the result
  const memoizedOptions = useMemo(() => disabled ? [] : options, [refreshOption, disabled]);

  const allProps = {
    ...rest,
    options: memoizedOptions
  } as FakeDropdownProps;

  // Component
  return <FakeDropdown {...allProps} />;
};

FakeDropdownContainer.propTypes = {
  selectorFunction: PropTypes.func,
  refreshOption: PropTypes.string,
  sortFunction: PropTypes.func
};

export default FakeDropdownContainer;
