import { InviteError } from '@myblueprint-spaces/redux/lib/invites';

export const NAME_MAX_CHAR = 35;
export const STUDENT_ID_MAX_CHAR = 64;
export const EMAIL_ID_MAX_CHAR = 128;
export const STUDENT_UNIQUE_ID_MAX_CHAR = 255;
export const MAX_CLASSROOM_STUDENTS = 200;

export const PORTFOLIO_TEMPLATE_NAME_MAX_CHAR = 100;
export const PORTFOLIO_TEMPLATE_DESCRIPTION_MAX_CHAR = 5000;
export const PORTFOLIO_TEMPLATE_MEDIA_MAX = 10;
export const PORTFOLIO_TEMPLATE_SECTION_TITLE_MAX_CHAR = 100;
export const PORTFOLIO_TEMPLATE_MAX_SECTIONS = 20;

enum invalidEmailString {
  InvalidEmail = 'ErrInvalidEmail'
}

export const CustomInviteErrorEnum = { ...InviteError, ...invalidEmailString };
