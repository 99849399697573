import { validateName } from '@myblueprint-spaces/modules';
import { NAME_MAX_CHAR } from 'modules/constants';
import update, { Spec } from 'immutability-helper';
import { MainInfo } from './types';
import { AccountType } from '@myblueprint-spaces/appstate';

export const handleValidateHelper = ({ firstName, lastName, agreement, dropdownAccountType, schoolsAndDistrict, showAccountTypeDropdown, showSchoolDropdown, userInfo, setUserInfo, t }) => () => {
  let valid = true;
  const validDetails: Spec<MainInfo> = {};
  const fName: string = firstName?.value;
  const lName: string = lastName?.value;

  if (!fName) {
    valid = false;
    validDetails.firstName = {
      valid: { $set: valid },
      validationText: { $set: t('Spaces.Common:Validations.FirstName.Empty') }
    };
  }

  if (fName && !validateName(fName)) {
    valid = false;
    validDetails.firstName = {
      valid: { $set: valid },
      validationText: { $set: t('Common:Validations.FirstName') }
    };
  }

  if (fName && fName.length > NAME_MAX_CHAR) {
    valid = false;
    validDetails.firstName = {
      valid: { $set: valid },
      validationText: { $set: t('Common:Name.Length.Validation', { count: fName.length - NAME_MAX_CHAR, limit: NAME_MAX_CHAR }) }
    };
  }

  if (!lName) {
    valid = false;
    validDetails.lastName = {
      valid: { $set: valid },
      validationText: { $set: t('Spaces.Common:Validations.LastName.Empty') }
    };
  }

  if (lName && !validateName(lName)) {
    valid = false;
    validDetails.lastName = {
      valid: { $set: valid },
      validationText: { $set: t('Common:Validations.LastName') }
    };
  }


  if (lName && lName.length > NAME_MAX_CHAR) {
    valid = false;
    validDetails.lastName = {
      valid: { $set: valid },
      validationText: { $set: t('Common:Name.Length.Validation', { count: lName.length - NAME_MAX_CHAR, limit: NAME_MAX_CHAR }) }
    };
  }

  if (!agreement.value) {
    valid = false;
    validDetails.agreement = {
      valid: { $set: valid }
    };
  }

  if (showAccountTypeDropdown && dropdownAccountType.value === undefined) {
    valid = false;
    validDetails.dropdownAccountType = {
      valid: { $set: valid }
    };
  }

  if (showSchoolDropdown && schoolsAndDistrict?.length === 0) {
    valid = false;
    validDetails.chosenSchool = {
      valid: { $set: valid }
    };
  }

  setUserInfo(update(userInfo, validDetails));
  return valid;
};

export const handleChangeHelper = (userInfo, setUserInfo) => (key) => (e) => {
  setUserInfo(update(userInfo,
    {
      [key]: {
        value: { $set: e },
        valid: { $set: true }
      }
    }
  ));
};

export const getDefaultState = (t, userFirstName?: string | null, userLastName?: string | null, providedSSOAccountType?: AccountType) => (
  {
    firstName: {
      value: userFirstName || '',
      valid: true,
      validationText: t('Spaces.Common:Validations.FirstName.Empty')
    },
    lastName: {
      value: userLastName || '',
      valid: true,
      validationText: t('Spaces.Common:Validations.LastName.Empty')
    },
    agreement: {
      value: false,
      valid: true,
      validationText: 'LoginSignUp.Common:Validation.Agreement'
    },
    dropdownAccountType: {
      value: providedSSOAccountType,
      valid: true,
      validationText: t('LoginSignUp.Common:Validation.AccountType')
    },
    chosenSchool: {
      value: undefined,
      valid: true,
      validationText: t('LoginSignUp.Common:Validation.SelectSchool')
    }
  }
);
