import React, { useContext } from 'react';
import RowColumn from '@myblueprint-spaces/papier-web/lib/Common/Grid/RowColumn';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import ImageBlock from '@myblueprint-spaces/papier-web/lib/Atoms/ImageBlock';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LinkExpired from 'assets/Asset_link_expired.png';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { FailedStateWrapper } from './styles';

const FailedStateScreen = () => {
  const { t } = useTranslation(['Admin.Common', 'AccountSettings.Admin']);
  const { isSmall } = useContext(WindowSizeContext);
  const navigate = useNavigate();

  return (
    <RowColumn align="center" span verticalAlign="middle">
      <FailedStateWrapper>
        <HeaderBlock
          image={<ImageBlock src={LinkExpired} alt="" aria-hidden="true" />}
          title={{ type: isSmall ? 'body1' : 'header4', children: t('Admin.Common:FailedLoading.Title') }}
          subtitle={{ type: isSmall ? 'compact': 'body2', color: 'black3', children: t('Admin.Common:FailedLoading.Subtitle') }} />
        <Button expanded onClick={() => navigate(0)} text={t('AccountSettings.Admin:RefreshPage')} dataTest="refresh-page" />
      </FailedStateWrapper>
    </RowColumn>
  );
};

export default withErrorBoundaries(FailedStateScreen);
