import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { Invite, InviteAdminData } from '@myblueprint-spaces/redux/lib/invites';
import { ScopeSelectors, ScopeType } from '@myblueprint-spaces/redux/lib/scopes';

export function useSchoolOrDistrictNames(invite?: Invite) {
  return useSelector((state: IState) => {
    if (!invite) return undefined;
    const names = {
      [ScopeType.District]: [],
      [ScopeType.School]: []
    } as Record<string, Array<string>>;

    (invite.data as InviteAdminData).associations.forEach((assoc) => {
      names[assoc.scopeType].push(ScopeSelectors.getScopeModel(state, assoc.scopeType, assoc.ownerId)!.displayName);
    });

    return names;
  });
}
