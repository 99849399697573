import useStudentCount from './useStudentCount';
import useLoadStudents from './useLoadStudents';
import useStudentInvites from './useStudentInvites';
import useStudentsWithSchoolId from './useStudentsWithSchoolId';
import useStudent from './useStudent';
import useEditStudents from './useEditStudents';
import useAddClassroom from './useAddClassroom';
import useRemoveClassroom from './useRemoveClassroom';
import useMissingStudentIds from './useMissingStudentIds';
import useStudentsAmountWithClassroomId from './useStudentsAmountWithClassroomId';
import useSentStudentInvites from './useSentStudentInvites';
import useSetStudentStatus from './useSetStudentStatus';

const StudentHooks = {
  useStudentCount,
  useLoadStudents,
  useStudentInvites,
  useStudentsWithSchoolId,
  useStudent,
  useEditStudents,
  useAddClassroom,
  useRemoveClassroom,
  useMissingStudentIds,
  useStudentsAmountWithClassroomId,
  useSentStudentInvites,
  useSetStudentStatus,
};

export default StudentHooks;
