import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Form from '@myblueprint-spaces/papier-web/lib/Common/Form';
import Logo from '@myblueprint-spaces/papier-web/lib/Common/Logo';
import TextBox from '@myblueprint-spaces/papier-web/lib/Common/TextBox';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import RowColumn from '@myblueprint-spaces/papier-web/lib/Common/Grid/RowColumn';
import update, { Spec } from 'immutability-helper';
import { LoginFormWrapper, FormWrapper, CaptchaDisclaimer } from 'views/Login/Shared';
import { HeaderContainer, BackButtonWrapper, RegionButtonWrapper } from '../Shared/styles';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { useTranslation } from 'react-i18next';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import { ILoginEmailProps, MainInfo } from './types';
import RegionSelector from '@myblueprint-spaces/web-common/lib/Common/RegionSelector';
import SamlLogin from 'components/Login/SamlLogin';
import { ButtonStatus } from '@myblueprint-spaces/papier-core';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import { LoginEmailFormContext } from '.';
import { handleError, handleFormSubmit } from '../LoginButton/helpers';
import { MAX_EMAIL_LENGTH, validateEmail } from '@myblueprint-spaces/modules';
import { useNavigate } from 'react-router-dom';

const LoginEmail = ({ invite, isDistrictAdmin, isLicensed, resetSettings, loadSettings }: ILoginEmailProps) => {
  const { t } = useTranslation(['Common', 'Signup', 'LoginSignUp.Common', 'Spaces.Common', 'LoginSignUp.Teacher']);
  const { isSmall } = useContext(WindowSizeContext);
  const { appLinks: { marketingUrl }  }  = useContext(AppConfigContext)!;

  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.initial);
  const getFullUrl = useGetFullUrl();
  const navigate = useNavigate();

  useEffect(() => {
    return () => clearTimeout(timeout.current as unknown as number);
  }, []);

  const [userInfo, setUserInfo] = useState<MainInfo>({
    email: {
      value: invite?.email || '',
      valid: true,
      validationText: 'Common:Validations.Email'
    }
  });
  const { email } = userInfo;
  const emailLengthExceeded = (email?.value as string).length > MAX_EMAIL_LENGTH;

  const handleValidate = () => {
    let valid = true;
    const validDetails = {} as MainInfo;

    if (!email.value || !validateEmail((email.value as string).trim())) {
      handleError(setBtnStatus, timeout);
      valid = false;
      validDetails.email = { valid: { $set: valid } };
    }

    setUserInfo(update(userInfo, validDetails as Spec<Readonly<MainInfo>, never>));
    return valid;
  };

  const handleFieldUpdate = (field) => (value) => {
    setUserInfo(update(userInfo, {
      [field]: {
        value: { $set: value },
        valid: { $set: true }
      }
    }));
  };

  const onSubmit = useCallback(() => {
    const errorUrl = getFullUrl('/sso-error');
    setBtnStatus(ButtonStatus.loading);
    handleFormSubmit(resetSettings, loadSettings, (email.value as string)?.trim(), errorUrl, t, setBtnStatus, timeout, navigate);
  }, [resetSettings, loadSettings, email.value, t, setBtnStatus, timeout, navigate]);

  return (
    <React.Fragment>
      <LoginEmailFormContext.Provider value={{ btnStatus, setBtnStatus, email: (email.value as string)?.trim() }}>
        <LoginFormWrapper>
          <HeaderContainer>
            <CovalentGrid align="justify">
              <BackButtonWrapper>
                <Button
                  rounded
                  // eslint-disable-next-line no-restricted-properties
                  onClick={() => window.location.href = marketingUrl}
                  icon="left-arrow"
                  size={isSmall ? 'small' : 'medium'}
                  color="secondary"
                  dataTest="back"
                  title={t('Common:Actions.Back')}
                />
              </BackButtonWrapper>
              {!invite && <RegionButtonWrapper>
                <RegionSelector />
              </RegionButtonWrapper>}
            </CovalentGrid>
          </HeaderContainer>
          <FormWrapper>
            <RowColumn align="center">
              <Logo size="xlarge" logoOnly />
            </RowColumn>
            <HeaderBlock
              title={{ as: 'h1', children: t('LoginSignUp.Common:Login.LoginToAccount') }}
              subtitle={{ type: 'compact', color: 'black3', children: invite ? undefined : t('LoginSignUp.Common:Login.SSO') }}
              style={{ paddingBottom: '2rem' }}
            />
            <Form onSubmit={onSubmit} onValidate={handleValidate}>
              <TextBox
                autoComplete="on"
                name="email"
                label={t('Common:UserInfo.Email')}
                value={email.value as string}
                validation={email.valid && !emailLengthExceeded
                  ? null
                  : {
                    state: 'error',
                    message: emailLengthExceeded
                      ? t('Common:Email.LengthValidation', { count: (email?.value as string).length - MAX_EMAIL_LENGTH, limit: MAX_EMAIL_LENGTH })
                      :t(email.validationText as string)
                  }}
                onChange={handleFieldUpdate('email')}
                dataTest="email"
                disabled={isDistrictAdmin || isLicensed} />
              <SamlLogin />
            </Form>
          </FormWrapper>
        </LoginFormWrapper>
      </LoginEmailFormContext.Provider>
      <CaptchaDisclaimer />
    </React.Fragment>
  );
};

export default withErrorBoundaries(LoginEmail);
