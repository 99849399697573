import React, { useState, useContext, useRef, useCallback } from 'react';
import { ITypographyProps } from '@myblueprint-spaces/papier-core';
import LinkButton from '@myblueprint-spaces/papier-web/lib/Atoms/LinkButton';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import TextBox from '@myblueprint-spaces/papier-web/lib/Common/TextBox';
import Form from '@myblueprint-spaces/papier-web/lib/Common/Form';
import Logo from '@myblueprint-spaces/papier-web/lib/Common/Logo';
import PasswordTextBox from '@myblueprint-spaces/papier-web/lib/Common/PasswordTextBox';
import Tooltip from '@myblueprint-spaces/papier-web/lib/Common/Tooltip';
import InlineElement from '@myblueprint-spaces/papier-web/lib/Atoms/InlineElement';
import RowColumn from '@myblueprint-spaces/papier-web/lib/Common/Grid/RowColumn';
import { BackButtonWrapper, HeaderContainer } from '../Shared/styles';
import update from 'immutability-helper';
import ForgotPassword from 'views/Login/ForgotPassword';
import { CaptchaDisclaimer, LoginFormWrapper, FormWrapper } from 'views/Login/Shared';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { useTranslation } from 'react-i18next';
import { LoginProps } from './types';
import { ScopeType } from '@myblueprint-spaces/redux/lib/scopes';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import { failToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import axios from 'axios';
import { AuthenticationResult } from '@myblueprint-spaces/redux/lib/authentication';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthenticationHooks from 'modules/hooks/redux/authentication';
import { AuthenticationContext } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';
import { ExternalAuthLoginButton } from '@myblueprint-spaces/web-common/lib/Common/ExternalAuthButtons';
import { LoginProviders } from '@myblueprint-spaces/redux/lib/user';
import AuthLoadingContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthLoadingContext';
import { Or, ConnectWithExternal } from 'views/Login/Shared/styles';
import { Column, Row } from '@myblueprint-spaces/papier-web/lib/Common/Grid';

const Login = (props: LoginProps) => {
  const { login, googleReCaptchaProps, invite, schoolOrDistrictNames, acceptInvite, email: userEmail } = props;
  const { isSmall } = useContext(WindowSizeContext);
  const { saveToken, clearToken } = useContext(AuthenticationContext);
  const { search } = useLocation();
  const { t } = useTranslation(['Common', 'LoginSignUp.Common', 'Invitations.Admin']);
  const navigate = useNavigate();
  const [email, setEmail] = useState({
    value: invite ? invite.email : userEmail,
    valid: true,
    validationText: 'Common:Validations.InvalidCredentials'
  });
  const [password, setPassword] = useState({
    value: '',
    valid: true,
    validationText: 'Common:Validations.InvalidCredentials'
  });
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
  const isSchoolInvite = invite && schoolOrDistrictNames![ScopeType.School].length > 0;
  const schoolNames = invite && (isSchoolInvite ? schoolOrDistrictNames![ScopeType.School] : schoolOrDistrictNames![ScopeType.District]);
  const titleRef = useRef(null);
  const { appLinks: { adminUrl } } = React.useContext(AppConfigContext)!;
  const searchParams = new URLSearchParams(search);
  const approvalRequestParams = searchParams.get('approvalRequests');
  const { pathname }  = new URL(searchParams.get('redirectUrl') || '/dashboard', adminUrl);
  const redirectUrl = `${pathname}${approvalRequestParams ? '?approvalRequests=true' : ''}`;
  const inviteTitle =  isSchoolInvite ? t('Invitations.Admin:LoginPage.School.Title') : t('Invitations.Admin:LoginPage.District.Title');
  const getFullUrl = useGetFullUrl();
  const emailMismatchUrl = getFullUrl('/email-mismatch');
  const noAccessUrl = getFullUrl('/no-access');
  const exchangeToken = AuthenticationHooks.useExchangeToken();

  const onClick = useCallback(() => navigate('/') , [navigate]);

  // Handle Login Form Submit
  const handleSubmit = useCallback(() => {
    return googleReCaptchaProps!.executeRecaptcha('login')
      .then((token) => login(email.value, password.value, token)
        .then(async (token) => {
          clearToken();
          token && saveToken(token);

          if (invite) {
            await acceptInvite!();
            navigate('/dashboard');
          }
          navigate(redirectUrl);
        })
        .catch((e) => {
          if (e.resultCode === AuthenticationResult.SsoRequired) navigate(noAccessUrl);
          else if (e.resultCode === AuthenticationResult.AccountLocked) failToast(e, t, t('Common:Validations.AccountLocked').toString());
          else if (e.resultCode === AuthenticationResult.Fail) {
            failToast(e, t, t('Common:Validations.InvalidCredentials').toString());
          }
          throw e;
        }));
  }, [googleReCaptchaProps, login, email.value, password.value, invite, acceptInvite, navigate, redirectUrl, noAccessUrl, t]);

  const handleValidate = () => {
    let valid = true;

    if (!email.value) {
      valid = false;
      setEmail(update(email, {
        valid: { $set: false },
      }));
    }

    if (!password.value) {
      valid = false;
      setPassword(update(password, {
        valid: { $set: false },
      }));
    }
    return valid;
  };

  const handleAuthenticated = useCallback(async () => {
    clearToken();
    const token = await exchangeToken();
    token && saveToken(token);

    if (invite) {
      return acceptInvite!().then(() => {
        navigate('/dashboard');
      }).catch((e) => {
        if (axios.isAxiosError(e)) {
          const { response } = e;
          const { status } = response || {};
          if (status === 400) navigate(emailMismatchUrl);
        }
        throw e;
      });
    }
    navigate(redirectUrl);
    return Promise.resolve();
  }, [invite, acceptInvite, navigate, redirectUrl, emailMismatchUrl, exchangeToken]);

  const inviteSubtitle = (schoolNames?.length === 1
    ? t('Invitations.Admin:LoginPage.Subtitle', { school: schoolNames?.join(', '), count: schoolNames?.length })
    : ({ as: 'div', children: (
      <React.Fragment>
        <CovalentGrid align="center" verticalAlign="middle">
          <Typography>{t('Invitations.Admin:LoginPage.Subtitle', { school: schoolNames?.join(', '), count: schoolNames?.length })}</Typography>
          <InlineElement ref={titleRef}><Icon type="info" color="grey1" removeMargin size="medium" /></InlineElement>
        </CovalentGrid>
        <Tooltip trigger={titleRef} size="medium">
          <ul style={{ paddingInlineStart: '1rem' }}>
            {schoolNames?.map((s, i) => (
              <li key={i} style={{ listStyleType: 'disc', paddingRight: '0.5rem' }}>
                {s}
              </li>
            ))}
          </ul>
        </Tooltip>
      </React.Fragment>
    ) } as ITypographyProps));

  const subtitle = !invite ? '' : inviteSubtitle;

  const forgotPassword = (
    <LinkButton
      type="body2"
      color="primary1"
      onClick={() => setOpenForgotPasswordDialog(true)}
      dataTest="forgot-password">
      {t('LoginSignUp.Common:UserInfo.ForgotPassword')}
    </LinkButton>
  );

  return (
    <LoginFormWrapper>
      {!invite && <HeaderContainer>
        <CovalentGrid align="justify">
          <BackButtonWrapper>
            <Button
              rounded
              onClick={onClick}
              icon="left-arrow"
              size={isSmall ? 'small' : 'medium'}
              color="secondary"
              dataTest="back"
              title={t('Common:Actions.Back')}
            />
          </BackButtonWrapper>
        </CovalentGrid>
      </HeaderContainer>}
      <FormWrapper>
        <RowColumn align="center">
          <Logo size="xlarge" logoOnly />
        </RowColumn>
        <HeaderBlock
          title={{ as: 'h1', children: !invite ? t('LoginSignUp.Common:Login.LoginToAccount') : inviteTitle }}
          subtitle={subtitle ? subtitle : undefined}
        />
        <ConnectWithExternal>
          <Typography type="body2" color="black2" style={{ marginBottom: '0.5rem' }}>
            {t('LoginSignUp.Common:LoginWith')}
          </Typography>
          <Row expanded>
            <Column columns={{ small: 12 }}>
              <ExternalAuthLoginButton
                provider={LoginProviders.google}
                onLogin={handleAuthenticated}
              />
            </Column>
          </Row>
          <Or type={isSmall ? 'compact' : 'body2'}>{t('Common:Or')}</Or>
        </ConnectWithExternal>
        <AuthLoadingContext.Consumer>
          {
            ({ loading }) => (
              <Form
                showResult
                onSubmit={handleSubmit}
                onValidate={handleValidate}
                button={{
                  text: t('LoginSignUp.Common:Login.Login'),
                  disabled: loading,
                  dataTest: 'login-button'
                }}
              >
                <TextBox
                  label={t('Common:UserInfo.Email')}
                  disabled
                  value={email.value}
                  validation={email.valid
                    ? null
                    : {
                      state: 'error',
                      message: t(email.validationText)
                    }}
                  onChange={(e) => setEmail(update(email, { value: { $set: e }, valid: { $set: true } }))}
                  dataTest="email" />
                <PasswordTextBox
                  label={t('Common:UserInfo.Password')}
                  labelRight={forgotPassword}
                  password={password}
                  validation={password.valid
                    ? undefined
                    : {
                      state: 'error',
                      message: t(password.validationText)
                    }}
                  onChange={(e) => setPassword(update(password, { value: { $set: e }, valid: { $set: true } }))}
                  dataTest="password"
                />
              </Form>
            )
          }
        </AuthLoadingContext.Consumer>
      </FormWrapper>
      <ForgotPassword
        email={email.value}
        open={openForgotPasswordDialog}
        onClose={() => setOpenForgotPasswordDialog(false)}
      />
      <CaptchaDisclaimer />
    </LoginFormWrapper>
  );
};

export default Login;
