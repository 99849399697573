import useBasicUserInfo from './useBasicUserInfo';
import useAdminAssociation from './useAdminAssociation';
import useLoadAdminUser from './useLoadAdminUser';
import useUserProfile from './useUserProfile';
import useUser from './useUser';
import useUpdateUserProfile from './useUpdateUserProfile';
import useLoadUser from './useLoadUser';
import useVerifyEmail from './useVerifyEmail';
import useStartHub from  './useStartHub';
import useDeleteAccount from './useDeleteAccount';
import useLogout from './useLogout';
import useResetPassword from './useResetPassword';
import useUpdateUserBasicInfo from './useUpdateUserBasicInfo';
import useDeleteOAuth from './useDeleteOAuth';
import useIsAvatarDefault from './useIsAvatarDefault';
import useDeleteUserAvatar from './useDeleteUserAvatar';
import useUpdateUserAvatar from './useUpdateUserAvatar';
import useAllAssociations from './useAllAssociations';
import useSwitchAccount from './useSwitchAccount';
import useTeacherAssociations from './useTeacherAssociations';
import useStudentAssociations from './useStudentAssociations';
import useFamilyAssociation from './useFamilyAssociation';
import useLoadAssociations from './useLoadAssociations';

const UserHooks = {
  useBasicUserInfo,
  useAdminAssociation,
  useLoadAdminUser,
  useUserProfile,
  useUser,
  useUpdateUserProfile,
  useLoadUser,
  useVerifyEmail,
  useStartHub,
  useDeleteAccount,
  useLogout,
  useResetPassword,
  useUpdateUserBasicInfo,
  useDeleteOAuth,
  useIsAvatarDefault,
  useDeleteUserAvatar,
  useUpdateUserAvatar,
  useAllAssociations,
  useSwitchAccount,
  useTeacherAssociations,
  useStudentAssociations,
  useFamilyAssociation,
  useLoadAssociations
};

export default UserHooks;
