import { Breakpoints } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

export const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 9.5rem;

  @media (max-width: ${Breakpoints.medium}em) {
    padding: 1rem;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0.75rem 0.75rem 0 0;
  display: flex;
  justify-content: flex-end;
`;
