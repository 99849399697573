import React, { useCallback } from 'react';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import ImageBlock from '@myblueprint-spaces/papier-web/lib/Atoms/ImageBlock';
import EmptyState from '@myblueprint-spaces/papier-web/lib/Common/EmptyState';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { useTranslation } from 'react-i18next';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import TheEnd from 'assets/Asset_theEnd.png';
import { useNavigate } from 'react-router-dom';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { User } from '@myblueprint-spaces/redux/lib/user';

const NotFound = ({ user }: { user?: User }) => {
  const { t } = useTranslation(['Common.404', 'Common', 'Spaces.Common']);
  const { adminUrl } = React.useContext(AppConfigContext)!.appLinks;
  const navigate = useNavigate();

  // eslint-disable-next-line no-restricted-properties
  const onClick = useCallback(() => user ? navigate(-1) : window.location.replace(adminUrl), [user, navigate, adminUrl]);

  return (
    <Row align="center" verticalAlign="middle">
      <Column columns={{ small: 10 }}>
        <div style={{ paddingTop: '3rem' }}>
          <EmptyState
            image={<ImageBlock src={TheEnd} alt="" aria-hidden="true" />}
            title={t('Common:404.Title').toString()}
            subtitle={t('Common:404.Subtitle').toString()}
            action={
              <Button
                text={user ? t('Common:GoBack') : t('Spaces.Common:GoToHomePage')}
                dataTest="go-back"
                icon="left-arrow"
                color="inversePrimary" shadow
                onClick={onClick}
              />}
          />
        </div>
      </Column>
    </Row>
  );
};

export default withErrorBoundaries(NotFound);
