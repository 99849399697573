import UserHooks from 'modules/hooks/redux/user';
import React, { useEffect } from 'react';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import NoSchoolsAvailable from './NoSchoolsAvailable';
import useAuthSetup from 'modules/hooks/useAuthSetup';

const NoSchoolsAvailableContainer = () => {
  const user = UserHooks.useUser();
  const logout = UserHooks.useLogout();
  const loadUser = UserHooks.useLoadUser();

  useAuthSetup();

  useEffect(() => {
    if (!user) loadUser();
  }, [user]);

  if (user === undefined) return <LoadingDots color="primary0" />;

  return <NoSchoolsAvailable logout={logout} user={user} />;
};

export default NoSchoolsAvailableContainer;
