import { AuthenticationError } from '@myblueprint-spaces/redux/lib/api/common/authentication';
import { NavigateFunction } from 'react-router-dom';

export const handleErrorHelper = (navigate: NavigateFunction, pathname: string, search: string) => (e) => {
  let url;
  if (e.response?.status === 401 || e instanceof AuthenticationError) {
    url = `/?redirectUrl=${pathname}`;
  } else {
    url = '/';
  }
  if (search) {
    navigate([url, search.substr(1, search.length)].join('&').toString());
  } else {
    navigate(url);
  }
  throw e;
};
