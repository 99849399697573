import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LoadingGif from '@myblueprint-spaces/papier-web/lib/Common/LoadingGif';
import LoggedLayout from 'layouts/LoggedLayout';
import LoggedUserContext from 'contexts/LoggedUserContext';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import { setIntercomVisibility, shutDownIntercom } from 'modules/services/intercom';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import ImpersonationBanner from '@myblueprint-spaces/web-common/lib/Common/ImpersonationBanner';
import VerifyEmail from 'components/VerifyEmail';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { AuthenticatedUserProps } from './types';
import FailedStateScreen from './components/FailedStateScreen';
import { handleErrorHelper } from './helper';
import { AuthenticationContext } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';

const NUM_RETRIES = 3;

const AuthenticatedUser = ({ logout, allAssociations, user, loadUser, startHub, availableSchools, verifyEmail, loadAdminUser }: AuthenticatedUserProps) => {
  const { search, pathname } = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [showFailedStateScreen, setShowFailedStateScreen] = useState(false);
  const { integrations: { excludeExpression } } = useContext(AppConfigContext)!;
  const { isSmall } = useContext(WindowSizeContext);
  const { appLinks: { spacesUrl } } = useContext(AppConfigContext)!;
  const { storedToken } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const handleError = handleErrorHelper(navigate, pathname, search);

  const retryPromise = (action: () => Promise<void>, callback: () => void, numberRetries = NUM_RETRIES) => {
    if (numberRetries === 0) {
      setShowFailedStateScreen(true);
      return false;
    }
    action()
      .then(callback)
      .catch((e) => {
        if (e?.response?.status === 524) {
          retryPromise(action, callback, numberRetries - 1);
        } else {
          setShowFailedStateScreen(true);
        }
      });
  };

  useEffect(() => {
    const loadUserAndStartHub = async () => {
      try {
        await loadUser();
        startHub();
      } catch (e) {
        handleError(e);
      }
    };

    loadUserAndStartHub();
  }, []);

  useLayoutEffect(() => {
    if (user) {
      const excludeUser = RegExp(excludeExpression).test(user.email!);
      if (loaded) setIntercomVisibility(!excludeUser && !isSmall);
    }
  }, [user, loaded, excludeExpression, isSmall]);

  useEffect(() => {
    if (user) {
      if (search && !user.emailVerified) {
        const evid = (new URLSearchParams(search)).get('evid');
        if (evid) {
          verifyEmail(evid)
            .then(() => {
              navigate(pathname, { replace: true });
              retryPromise(loadAdminUser, () => setLoaded(true));
            });
        }
      } else if (user.emailVerified) {
        retryPromise(loadAdminUser, () => setLoaded(true));
      }

      return () => {
        if (loaded) shutDownIntercom();
      };
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const evid = (new URLSearchParams(search)).get('evid');
      if (!evid && !user.emailVerified) {
        setShowVerifyEmail(true);
      } else if (allAssociations && user.emailVerified) {
        setShowVerifyEmail(false);
      }
    }
  }, [user, allAssociations]);

  useEffect(() => {
    if (availableSchools && availableSchools.length === 0) {
      navigate('/no-schools');
    }
  }, [availableSchools, navigate]);

  useEffect(() => {
    // Check if the user exists but has no admin account to redirect in the fallback order
    if (user && allAssociations && !allAssociations.admin) {
      if (allAssociations.teachers?.length) {
        const spacesTeacherUrl = new URL('/teacher', spacesUrl);
        spacesTeacherUrl.searchParams.append('sessionToken', storedToken!);
        // eslint-disable-next-line no-restricted-properties
        window.location.href = spacesTeacherUrl.href;
      } else if (allAssociations.students?.length) {
        const spacesStudentUrl = new URL('/student', spacesUrl);
        spacesStudentUrl.searchParams.append('sessionToken', storedToken!);
        // eslint-disable-next-line no-restricted-properties
        window.location.href = spacesStudentUrl.href;
      } else if (allAssociations.family) {
        const spacesFamilyUrl = new URL('/family', spacesUrl);
        spacesFamilyUrl.searchParams.append('sessionToken', storedToken!);
        // eslint-disable-next-line no-restricted-properties
        window.location.href = spacesFamilyUrl.href;
      } else {
        logout();
        navigate('/error');
      }
    }
  }, [user, allAssociations, spacesUrl]);

  if (showFailedStateScreen) return <FailedStateScreen />;

  if (showVerifyEmail) return <VerifyEmail user={user} logout={logout} />;

  if (!user || !allAssociations?.admin || !loaded || !availableSchools) return <LoadingGif />;

  return (
    <LoggedUserContext.Provider value={{ availableSchools, loggedUserBasicInfo: user }}>
      <ImpersonationBanner />
      <LoggedLayout>
        <Outlet />
      </LoggedLayout>
    </LoggedUserContext.Provider>
  );
};

export default withErrorBoundaries(AuthenticatedUser);
