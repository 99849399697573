import ImageBlock from '@myblueprint-spaces/papier-web/lib/Atoms/ImageBlock';
import styled from 'styled-components';

export const EmptyStateWrapper = styled.div`
  text-align: center;
  padding-top: 3.5rem;
`;

export const CustomImage = styled(ImageBlock)`
  width: 7.5rem;
  height: 7.5rem;
`;
