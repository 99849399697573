import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { AuthenticationActions } from '@myblueprint-spaces/redux/lib/authentication';

export default function useLoadSettings() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (email: string) => dispatch(AuthenticationActions.loadSettings(email)),
    [dispatch]
  );
}
