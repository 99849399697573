import React, { useCallback, useContext, useLayoutEffect, useState } from 'react';
import Checkbox from '@myblueprint-spaces/papier-web/lib/Common/Checkbox';
import PasswordTextBox from '@myblueprint-spaces/papier-web/lib/Common/PasswordTextBox';
import Logo from '@myblueprint-spaces/papier-web/lib/Common/Logo';
import Form from '@myblueprint-spaces/papier-web/lib/Common/Form';
import TextBox from '@myblueprint-spaces/papier-web/lib/Common/TextBox';
import useId from '@myblueprint-spaces/papier-web/lib/modules/hooks/useId';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import { RowColumn, Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import update, { Spec } from 'immutability-helper';
import { CaptchaDisclaimer, LoginFormWrapper, FormWrapper } from 'views/Login/Shared';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { useTranslation } from 'react-i18next';
import { getValidationDetails } from 'modules/helpers/basicUserInfoFormValidation';
import { LoginProviders, TimeZones } from '@myblueprint-spaces/redux/lib/user';
import { SignUpUserData } from '@myblueprint-spaces/redux/lib/signUp';
import { PasswordStrengthIndicator } from 'components/PasswordStrengthIndicator/PasswordStrengthIndicator';
import { AccountType, validPassword } from '@myblueprint-spaces/appstate';
import { ActivateProps, ActivateUserInfo } from './types';
import { HeaderContainer } from './styles';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import { BackButtonWrapper } from '../Shared/styles';
import axios from 'axios';
import { AuthenticationResult } from '@myblueprint-spaces/redux/lib/authentication';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { useNavigate } from 'react-router-dom';
import AuthenticationHooks from 'modules/hooks/redux/authentication';
import { AuthenticationContext } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';
import { ExternalAuthSignUpButton } from '@myblueprint-spaces/web-common/lib/Common/ExternalAuthButtons';
import AuthLoadingContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthLoadingContext';
import TermsOfAgreement from '@myblueprint-spaces/web-common/lib/Common/TermsOfAgreement';
import { ConnectWithExternal, Or } from 'views/Login/Shared/styles';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';

const ActivateScreen = (props: ActivateProps) => {
  const { createUser, googleReCaptchaProps, invite, acceptInvite } = props;
  const { isSmall } = React.useContext(WindowSizeContext);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZones;
  const { email } = invite || {};
  const termsId = useId(null);
  const getFullUrl = useGetFullUrl();
  const mismatchUrl = getFullUrl('/email-mismatch');
  const emailInUseUrl = getFullUrl('/email-in-use');
  const noAccessUrl = getFullUrl('/no-access');
  const dashboardUrl = getFullUrl('/dashboard');
  const navigate = useNavigate();
  const exchangeToken = AuthenticationHooks.useExchangeToken();
  const { saveToken, clearToken } = useContext(AuthenticationContext);

  // DO NOT remove 'PasswordStrength.Common' from here, even though this is not used in this page, we need to load it along with the page, otherwise the focus
  // is lost while typing the password...
  const { t } = useTranslation(['Common', 'LoginSignUp.Common', 'Invitations.Admin', 'PasswordStrength.Common', 'Admin.Common']);
  const [userInfo, setUserInfo] = useState<Omit<ActivateUserInfo, 'email'>>({
    firstName: {
      value: '',
      valid: true,
      validationText: t('Common:Validations.FirstName')
    },
    lastName: {
      value: '',
      valid: true,
      validationText: t('Common:Validations.LastName')
    },
    password: {
      value: '',
      valid: true,
      validationText: t('Common:Validations.Password')
    },
    agreement: {
      value: false,
      valid: true,
      validationText: t('LoginSignUp.Common:Validation.Agreement')
    },
  });
  const [emailDisabled, setEmailDisabled] = useState(false);
  const { firstName, lastName, password, agreement } = userInfo;

  const handleUpdate = (field, value) => {
    setUserInfo(update(userInfo, {
      [field]: { $set: { value, valid: { $set: true } } }
    }));
  };

  const googleSignUp = (info, captcha) => {
    return createUser(info.user, captcha, info.externalData)
      .then(async () => {
        clearToken();
        const token = await exchangeToken();
        saveToken(token);
        navigate(dashboardUrl);
      });
  };

  // Handle Login Form Submit
  const handleSubmit = useCallback(() => {
    const user: SignUpUserData = {
      firstName: firstName.value as string,
      lastName: lastName.value as string,
      password: password.value as string,
      email,
      timeZone
    };

    return googleReCaptchaProps.executeRecaptcha('signup')
      .then((token) => createUser(user, token)
        .then((sessionToken) => {
          clearToken();
          sessionToken && saveToken(sessionToken);
          navigate(dashboardUrl);
        })
      )
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          const { response } = e;
          const { data, status } = response || {};

          if (status === 409) navigate(emailInUseUrl);
          else if (data?.resultCode === AuthenticationResult.SsoRequired) navigate(noAccessUrl);
        }
        throw e;
      });
  }, [firstName.value, lastName.value, password.value, email, timeZone, googleReCaptchaProps, createUser, navigate, dashboardUrl, emailInUseUrl, noAccessUrl]);

  const handleValidate = () => {
    const initialValidation = getValidationDetails(userInfo, t, true);
    let { valid } = initialValidation;
    const { validDetails } = initialValidation as { validDetails: Partial<ActivateUserInfo>};
    const { value: passwordValue } = password;
    const { value: agreementValue } = agreement;

    if (!passwordValue) {
      valid = false;
      validDetails.password = {
        valid: { $set: valid },
        validationText: { $set: t('Common:Validations.Password') }
      };
    }
    if (passwordValue && !validPassword(passwordValue as string)) {
      valid = false;
      validDetails.password = {
        valid: { $set: valid },
        validationText: { $set: null }
      };
    }
    if (!agreementValue) {
      valid = false;
      validDetails.agreement = {
        valid: { $set: valid }
      };
    }

    setUserInfo(update(userInfo, validDetails as Spec<Omit<ActivateUserInfo, 'email'>, never>));

    return valid;
  };

  const handleAuthenticated = useCallback(async () => {
    clearToken();
    const token = await exchangeToken();
    saveToken(token);

    return acceptInvite()
      .then(() => navigate(dashboardUrl))
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          const { response } = e;
          const { status } = response || {};
          if (status === 400) navigate(mismatchUrl);
        }
        throw e;
      });
  }, [acceptInvite, navigate, dashboardUrl, mismatchUrl, exchangeToken]);

  const validateUsedEmail = useCallback((externalChosenEmail) => {
    if (email !== externalChosenEmail) {
      navigate(mismatchUrl);
      return false;
    }
    return true;
  }, [email, navigate, mismatchUrl]);

  const onClick = useCallback(() => navigate('/'), [navigate]);

  // hack to prevent Chrome from autofilling the wrong field
  // (https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164 - Chrome won'tFix - won't accept autocomplete='off' and apparently ignores all other valid autocomplete strings)
  useLayoutEffect(() => {
    setTimeout(() => setEmailDisabled(true), 600);
  }, []);

  return (
    <React.Suspense fallback={<LoadingDots />}>
      <LoginFormWrapper>
        <HeaderContainer>
          <BackButtonWrapper>
            <Button
              rounded
              onClick={onClick}
              icon="left-arrow"
              size={isSmall ? 'small' : 'medium'}
              color="secondary"
              dataTest="back"
              title={t('Common:Actions.Back')}
            />
          </BackButtonWrapper>
        </HeaderContainer>
        <FormWrapper largeForMobile>
          <RowColumn align="center">
            <Logo size={isSmall ? 'medium' : 'xlarge'} logoOnly />
          </RowColumn>
          <HeaderBlock
            title={{ as: 'h1', children: t('Invitations.Admin:SignUpPage.Title') }}
            subtitle={t('Invitations.Admin:SignUpPage.Subtitle').toString()}
          />
          <ConnectWithExternal>
            <Typography type="body2" color="black2" style={{ marginBottom: '0.5rem' }}>
              {t('LoginSignUp.Common:SignUpWith')}
            </Typography>
            <Row expanded>
              <Column columns={{ small: 12 }}>
                <ExternalAuthSignUpButton
                  provider={LoginProviders.google}
                  accountType={AccountType.Admin}
                  validateUsedEmail={validateUsedEmail}
                  onLogin={handleAuthenticated}
                  createUser={googleSignUp}
                />
              </Column>
            </Row>
            <Or type={isSmall ? 'compact' : 'body2'}>{t('Common:Or')}</Or>
          </ConnectWithExternal>

          <AuthLoadingContext.Consumer>
            {
              ({ loading }) => (
                <Form
                  showResult
                  onSubmit={handleSubmit}
                  onValidate={handleValidate}
                  button={{
                    text: t('LoginSignUp.Common:SignUp.SignUp'),
                    disabled: loading,
                    dataTest: 'signup-button'
                  }}
                >
                  <Row collapse={false}>
                    <Column columns={{ small: 12 }}>
                      <TextBox
                        label={t('Common:UserInfo.Email')}
                        value={email}
                        type="email"
                        name="email"
                        disabled={emailDisabled}
                        autoComplete="on"
                        dataTest="email" />
                    </Column>
                    <Column columns={{ small: 6 }}>
                      <TextBox
                        type="text"
                        label={t('Common:UserInfo.FirstName')}
                        value={firstName.value as string}
                        validation={firstName.valid
                          ? null
                          : {
                            state: 'error',
                            message: firstName.validationText as string
                          }}
                        name="given-name"
                        autoComplete="off"
                        onChange={(e) => handleUpdate('firstName', e)}
                        dataTest="firstName" />
                    </Column>
                    <Column columns={{ small: 6 }}>
                      <TextBox
                        type="text"
                        label={t('Common:UserInfo.LastName')}
                        value={lastName.value as string}
                        validation={lastName.valid
                          ? null
                          : {
                            state: 'error',
                            message: lastName.validationText as string
                          }}
                        name="family-name"
                        autoComplete="off"
                        onChange={(e) => handleUpdate('lastName', e)}
                        dataTest="lastName" />
                    </Column>
                    <Column columns={{ small: 12 }}>
                      <PasswordTextBox
                        label={t('Common:UserInfo.Password')}
                        password={password as {
                          value: string,
                          valid: boolean,
                          validationText: string
                        }}
                        name="password"
                        autoComplete="on"
                        validation={password.valid
                          ? undefined
                          : {
                            state: 'error',
                            message: password.validationText as string
                          }}
                        onChange={(e) => handleUpdate('password', e)}
                        data-test="password"
                      />
                      <PasswordStrengthIndicator password={password.value as string} />
                      <div style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}>
                        <Checkbox id={termsId} labelId={`${termsId}_label`} checked={!!agreement.value} size="small" validation={agreement.valid ? null : { state: 'error', message: agreement.validationText as string }}
                          label={<TermsOfAgreement />} onChange={(e) => handleUpdate('agreement', e)} dataTest="agreement" alignItems="start" hideCheckBoxControl={false} />
                      </div>
                    </Column>
                  </Row>
                </Form>
              )
            }
          </AuthLoadingContext.Consumer>
        </FormWrapper>
        <CaptchaDisclaimer />
      </LoginFormWrapper>
    </React.Suspense>
  );
};

export default withErrorBoundaries(ActivateScreen);
