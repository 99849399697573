import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import Popover, { PopoverElem } from '@myblueprint-spaces/papier-web/lib/Common/Popover';
import Panel from '@myblueprint-spaces/papier-web/lib/Common/Panel';
import usePrevious from '@myblueprint-spaces/papier-web/lib/modules/hooks/usePrevious';
import { useTranslation } from 'react-i18next';
import { TopbarProps } from './types';
import { Badge, AvatarButton, TopWrapper } from './styles';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import Sidebar from '../Sidebar';
import { ConnectedScopeAvatar } from '@myblueprint-spaces/web-common/lib/Common/ConnectedAvatar';
import { RovingTabIndexProvider } from '@myblueprint-spaces/react-roving-tabindex';
import AccountSwitch from './components/AccountSwitch';
import PopoverItem from '@myblueprint-spaces/papier-web/lib/Common/Popover/components';
import CurrentSchoolContext from 'contexts/CurrentSchoolContext';
import { useNavigate } from 'react-router-dom';
import Notifications from 'pages/Notifications';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';

const Topbar = ({ newNotifications, currentScope, mainContentRef, logout, associations }: TopbarProps) => {
  const { t } = useTranslation(['Common', 'AccountSettings.Common', 'Admin.Common']);
  const menuTrigger = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isLargeUp, isSmall } = useContext(WindowSizeContext);
  const previsLargeUp = usePrevious(isLargeUp);
  const prevSidebarOpen = usePrevious(sidebarOpen);
  const { school } = useContext(CurrentSchoolContext);
  const usedSchool = useRef(school);
  const sidebarSize = useRef(isSmall ? window.outerWidth * 0.7 : window.outerWidth * 0.4);
  const [notificationsOpen, setNotificationsOpen] = React.useState(false);
  const notificationsTrigger = React.useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    sidebarSize.current = isSmall ? window.outerWidth * 0.7 : window.outerWidth * 0.4;
  }, [isSmall, window.outerWidth]);

  const notificationsCommonProps = {
    triggerRef: notificationsTrigger,
    open: notificationsOpen,
    closePopover: () => setNotificationsOpen(false)
  };

  const handleClickNotification = useCallback(() => {
    if (isLargeUp) {
      setNotificationsOpen(!notificationsOpen);
    } else {
      navigate('/notifications');
    }
  }, [isLargeUp, navigate, notificationsOpen, setNotificationsOpen]);

  useEffect(() => {
    if (usedSchool) {
      usedSchool.current = school;
    }
  }, [school]);

  useEffect(() => {
    if (previsLargeUp !== isLargeUp) {
      setSidebarOpen(false);
    }
    if (prevSidebarOpen !== sidebarOpen && mainContentRef?.current) {
      if (sidebarOpen) {
        mainContentRef.current.style.position = 'absolute';
        mainContentRef.current.style.left = `${sidebarSize.current}px`;
      } else {
        mainContentRef.current.style.position = 'static';
        mainContentRef.current.style.left = '';
      }
    }
  }, [sidebarOpen, isLargeUp, previsLargeUp, prevSidebarOpen]);

  const menuItems = [
    {
      title: t('AccountSettings.Common:AccountSettings'),
      type: 'menuItem',
      onClick: () => {
        setMenuOpen(false);
        navigate('/settings/account');
      },
      dataTest: 'account-settings'
    },
    {
      type: 'divider'
    },
    {
      title: t('Common:Actions.Logout'),
      type: 'menuItem',
      textColor: 'danger1',
      onClick: () => {
        logout();
        setMenuOpen(false);
      },
      dataTest: 'logout'
    }
  ];

  return (
    <TopWrapper>
      <Row expanded align="justify" collapse>
        {!isLargeUp && <Column columns={{ small: 2 }}>
          <Button color="secondary" title={t('Common:OpenMenu')} icon="menu" rounded dataTest="menu" onClick={() => setSidebarOpen(true)} size="small" />
        </Column>}
        <Column>
          <CovalentGrid align="right">
            <Badge showBadge={newNotifications}>
              <Button color="secondary" rounded icon="bell" ref={notificationsTrigger} dataTest="notifications" onClick={handleClickNotification} title={t('Common:Notifications')} size={isSmall ? 'small' : 'medium'} />
            </Badge>
            <AvatarButton ref={menuTrigger} type="button" onClick={() => setMenuOpen(true)} title={t('Common:OpenMenu').toString()} data-test="avatar">
              <ConnectedScopeAvatar scope={currentScope} size={isSmall ? 'small' : 'medium'} />
            </AvatarButton>
          </CovalentGrid>
        </Column>
      </Row>
      <Popover
        triggerPosition="bottom-right"
        menuPosition="bottom-right"
        triggerRef={menuTrigger}
        open={menuOpen}
        hostAriaLabel={t('Common:OpenMenu')}
        onClose={() => setMenuOpen(false)}
      >
        <RovingTabIndexProvider>
          <PopoverElem style={{ padding: 0, marginTop: '0.5rem', overflow: 'hidden', paddingBottom: '0.5rem' }} role="menuitem">
            <AccountSwitch associations={associations} closeParent={() => setMenuOpen(false)} usedSchool={usedSchool} />
            <div role="menu">
              {menuItems.map((item, index) => (
                <PopoverItem
                  key={index}
                  handleDeactivate={() => setMenuOpen(false)}
                  {...item}
                />
              ))}
            </div>
          </PopoverElem>
        </RovingTabIndexProvider>
      </Popover>
      <Panel open={sidebarOpen} title={t('Admin.Common:Sidebar')} onPanelDidClose={() => setSidebarOpen(false)} enterDir="right" exitDir="left"
        dataTest="sidebar-panel" sizePercentage={`${sidebarSize.current}px`}>
        <Sidebar onNavigate={() => setSidebarOpen(false)} />
      </Panel>
      <Notifications {...notificationsCommonProps} />
    </TopWrapper>
  );
};

export default withErrorBoundaries(Topbar);
