import React, { useContext } from 'react';
import AccountTypeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AccountTypeContext';
import { getAccountTypeStr } from 'modules/helpers/accountTypeHelper';
import { RouteContext } from 'contexts/RouteContext';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import { INotificationsProps } from './types';
import NotificationsPanel, { NotificationsPopover } from '@myblueprint-spaces/web-common/lib/Common/Notifications';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';

const Notifications = (props: INotificationsProps) => {
  const { closePopover, triggerRef, open } = props;
  const accountType = useContext(AccountTypeContext);
  const { goBack } = useContext(RouteContext);
  const strAccountType = getAccountTypeStr(accountType);
  const getFullUrl = useGetFullUrl();
  const notificationsSettingsUrl = getFullUrl('/settings/notifications');
  const notificationsUrl = getFullUrl('/notifications');

  return (
    <React.Suspense fallback={<LoadingDots color="primary1" />}>
      {closePopover
        ? open && <NotificationsPopover triggerRef={triggerRef!} open={!!open} urls={{ settings: notificationsSettingsUrl, page: notificationsUrl }} />
        : <NotificationsPanel open settingsUrl={notificationsSettingsUrl} onClose={() => goBack(`/${strAccountType}/class-list`)} />}
    </React.Suspense>
  );
};

export default withErrorBoundaries(Notifications);
