import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { StudentActions } from '@myblueprint-spaces/redux/lib/students';

export default function useLoadStudents() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (schoolId: string) => dispatch(StudentActions.loadStudents(schoolId)),
    [dispatch]
  );
}
