import styled from 'styled-components';

export const HeaderContainer = styled.div`
  padding-top: 0.75rem;

`;

export const RegionButtonWrapper = styled.div`
  padding: 0 0.75rem;
  float: right;
`;

export const BackButtonWrapper = styled.div`
  margin-left: 0.75rem;
`;
