import React, { useEffect, useState } from 'react';
import InviteHooks from 'modules/hooks/redux/invites';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import { InviteState, InviteType } from '@myblueprint-spaces/redux/lib/invites';
import ErrorState from './components/ErrorState';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';

const Invite = () => {
  const { inviteGuid } = useParams<{ inviteGuid?: string }>();
  const inviteId = InviteHooks.useInviteId();
  const invite = InviteHooks.useInviteWithId(inviteId);
  const loadInviteWithGuid = InviteHooks.useLoadInviteWithGuid();
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const { spacesUrl } = React.useContext(AppConfigContext)!.appLinks;
  const navigate = useNavigate();

  useEffect(() => {
    inviteGuid && loadInviteWithGuid(inviteGuid)
      .catch((err) => {
        setError(true);
        throw err;
      });
  }, [inviteGuid]);

  useEffect(() => {
    if (!validated && invite && invite.state === InviteState.Pending) {
      setValidated(true);
    }
  }, [invite, validated]);

  // Error state
  if (error || (!validated && (invite && invite.state !== InviteState.Pending))) return <ErrorState />;

  if (typeof invite === 'undefined') return <LoadingDots color="primary0" />;

  if (invite.type !== InviteType.Admin) {
    const { href: inviteUrl } = new URL(`/invite/${inviteGuid}`, spacesUrl);
    // eslint-disable-next-line no-restricted-properties
    window.location.href = inviteUrl;
    return <LoadingDots color="primary0" />;
  } else {
    navigate('/', { replace: true });
  }

  return (
    <LoadingDots />
  );
};

export default withErrorBoundaries(Invite);
