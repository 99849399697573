import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { AuthenticationActions } from '@myblueprint-spaces/redux/lib/authentication';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';

export default function useLogout(withRedirect = true) {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  const { clearToken } = React.useContext(AuthenticationContext);

  return React.useCallback(
    () => dispatch(AuthenticationActions.logout()).then(() => {
      if (withRedirect) navigate('/');
      clearToken();
    }),
    [dispatch, navigate]
  );
}
