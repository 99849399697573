import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { ExternalUserData, SignUpActions, SignUpUserData } from '@myblueprint-spaces/redux/lib/signUp';
import { ScopeType } from '@myblueprint-spaces/redux/lib/scopes';

export default function useCreateUser() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (data: SignUpUserData, captcha: string, externalData?: ExternalUserData) => {
      dispatch(SignUpActions.saveUserData({ ...data, scopeType: ScopeType.Admin }));
      if (externalData) dispatch(SignUpActions.saveExternalUserData({ externalData, relatedRecords: null }));
      return dispatch(SignUpActions.createUser(data.password, captcha));
    },
    [dispatch]
  );
}
