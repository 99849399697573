import styled from 'styled-components';
import { Colors } from '@myblueprint-spaces/papier-core';
import CleanButton from '@myblueprint-spaces/papier-web/lib/Atoms/CleanButton';

export const ListItem = styled.li(({ isCurrent }: { isCurrent?: boolean }) => `
  text-align: left;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  position: relative;
  ${isCurrent ? `
    &:before {
      transition: all 250ms ease-in-out;
      width: 0.25rem;
      background: ${Colors.primary1};
      content: '';
      position: absolute;
      top: -0.0625rem;
      height: calc(100% + 0.125rem);
      display: block;
    }` : ''}
`);

export const CustomLink = styled(CleanButton)`
  display: block;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  width: 100%;
  border-radius: 0;
  
  :focus, :hover {
    background-color: ${Colors.primary2};
  }
`;
