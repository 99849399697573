import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import AuthenticationHooks from 'modules/hooks/redux/authentication';
import SignUpHooks from 'modules/hooks/redux/signup';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SsoProcessor from './SsoProcessor';

const SsoProcessorContainer = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const data = searchParams.get('data');
  const navigate = useNavigate();

  const saveAuthenticationData = AuthenticationHooks.useSaveAuthenticationData();
  const saveExternalUserData = SignUpHooks.useSaveExternalUserData();

  useEffect(() => {
    if (!data) navigate('/');
  }, [data, navigate]);

  if (!data) return <LoadingDots />;

  const allProps = {
    saveAuthenticationData,
    saveExternalUserData,
    data
  };

  return <SsoProcessor {...allProps} />;
};

export default SsoProcessorContainer;
