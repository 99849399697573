import React from 'react';
import PropTypes from 'prop-types';
import AppRoot from './AppRoot';
import { compose } from 'redux';
import LoadingGif from '@myblueprint-spaces/papier-web/lib/Common/LoadingGif';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import { withTranslation } from 'react-i18next';
import { WindowSizeProvider } from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import withHtmlLang from 'modules/services/i18next/withHtmlLang';
import GlobalHooks from 'modules/hooks/redux/global';
import { initializeDatadog } from 'modules/services/datadog';
import { GoogleContextProvider } from '@myblueprint-spaces/web-common/lib/Common/Contexts/GoogleContext';
import { AnalyticsContextProvider } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AnalyticsContext';
import { Product } from '@myblueprint-spaces/redux/lib/api/common/globals/enums';
import ProductContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/ProductContext';

const AppRootContainer = ({ i18n, ...rest }) => {
  const loadWebConfigs = GlobalHooks.useLoadConfigs();
  const appConfig = GlobalHooks.useConfig();
  const { datadog } = GlobalHooks.useIntegrations() || {};

  React.useEffect(() => {
    loadWebConfigs();
  }, []);

  React.useEffect(() => {
    if (datadog) {
      initializeDatadog(datadog);
    }
  }, [datadog]);

  if (!appConfig) return <LoadingGif />;

  return (
    <ProductContext.Provider value={{ productType: Product.Admin }}>
      <AnalyticsContextProvider>
        <AppConfigContext.Provider value={appConfig}>
          <GoogleContextProvider>
            <WindowSizeProvider>
              <AppRoot {...rest} />
            </WindowSizeProvider>
          </GoogleContextProvider>
        </AppConfigContext.Provider>
      </AnalyticsContextProvider>
    </ProductContext.Provider>
  );
};

AppRootContainer.propTypes = {
  appConfig: PropTypes.object,
  loadWebConfigs: PropTypes.func,
  notificationList: PropTypes.array,
  i18n: PropTypes.object
};

export default compose(withHtmlLang, withTranslation())(AppRootContainer);
