import styled from 'styled-components';
import { Colors, Shadows } from '@myblueprint-spaces/papier-core';
import { ISchoolSelectionRow } from './types';

export const StyledSchool = styled.li<ISchoolSelectionRow>(({ small, isSelected }) => {
  const background = isSelected ? `background-color: ${Colors.primary2};` : '';

  return `
    width: ${small ? 'calc(100% - 2.25rem)' : '100%'};  
    ${small ? 'padding: 0 1rem; margin: 0.125rem;' : 'padding-top: 0.25rem;'}
    height: ${small ? '4.4375rem' : '3.25rem'};
    text-align: left;
    transition: all 250ms ease-in-out;
    ${background}
    cursor: pointer;

    :focus {
      ${Shadows.primaryFloatingFocus};
    }

    :hover, :focus {
      background-color: ${Colors.primary2};
    }    
  `;
});

export const AvatarWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;
