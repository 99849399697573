import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { StaffSelectors } from '@myblueprint-spaces/redux/lib/staff';

export default function useStaffCount(schoolId?: string, approved = true) {
  return useSelector((state: IState) => {
    if (!approved) {
      return StaffSelectors.getTeacherStaffCount(state, schoolId, false);
    }
    return StaffSelectors.getStaffCount(state, schoolId);
  });
}
