import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { InviteSelectors } from '@myblueprint-spaces/redux/lib/invites';

export default function useInviteWithGuid(inviteId?: string | null) {
  return useSelector((state: IState) => {
    if (!inviteId) return undefined;
    return InviteSelectors.getInviteWithId(state, inviteId);
  });
}
