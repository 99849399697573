import { UserErrors } from '@myblueprint-spaces/redux/lib/user';
import axios from 'axios';
import { Invite } from '@myblueprint-spaces/redux/lib/invites';
import { ButtonStatus } from '@myblueprint-spaces/papier-core';
import { failToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';
import { NavigateFunction } from 'react-router-dom';

export const handleError = (setBtnStatus, timeout) => {
  setBtnStatus(ButtonStatus.fail);
  timeout.current = setTimeout(() => setBtnStatus(ButtonStatus.initial), 3000);
};

export const handleFormSubmit = async (resetSettings, loadSettings, email, errorUrl, t, setBtnStatus, timeout, navigate) => {
  await resetSettings();
  return loadSettings(email)
    .catch((err) => {
      if (axios.isAxiosError(err)) {
        const { response } = err;
        const { status, data } = response || {};
        if (status === 400 && data?.code === UserErrors.SsoMisconfigured) navigate(errorUrl);
      }
      handleError(setBtnStatus, timeout);
      failToast(err, t);
      throw err;
    });
};

export const handleNonSSORedirect = (navigate: NavigateFunction, activateUrl: string, search: string, email: string, invite?: Invite | null) => {
  if (invite) {
    navigate(activateUrl);
  } else {
    navigate({ pathname: '/2', search: search }, { state: { email: email } });
  }
};
