
import React from 'react';
import { Route } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import { Clipped, CustomSwitchRoute } from 'components/Shared';
import LoginEmail from 'pages/LoginScreen/components/LoginEmail';
import Login from 'pages/LoginScreen/components/Login';
import AdditionalInformation from 'pages/LoginScreen/components/AdditionalInformation';
import ActivateScreen from 'pages/LoginScreen/components/ActivateScreen';
import EmailInUseForm from 'pages/LoginScreen/components/EmailInUseForm';
import { AuthLoadingContextProvider } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthLoadingContext';

const LoginScreen = () => {
  const { integrations: { captchaClientId } } = React.useContext(AppConfigContext)!;

  return (
    <div role="main" style={{ display: 'contents' }} aria-labelledby="mainLabel">
      <Clipped id="mainLabel">Spaces</Clipped>
      <GoogleReCaptchaProvider
        reCaptchaKey={captchaClientId}
        useRecaptchaNet>
        <AuthLoadingContextProvider>
          <CustomSwitchRoute>
            <Route path="/" element={<LoginEmail />}  />
            <Route path="/2" element={<Login />} />
            <Route path="/more-info" element={<AdditionalInformation />} />
            <Route path="/activate" element={<ActivateScreen />} />
            <Route path="/email-in-use" element={<EmailInUseForm />} />
          </CustomSwitchRoute>
        </AuthLoadingContextProvider>
      </GoogleReCaptchaProvider>
    </div>
  );
};

export default LoginScreen;
