import styled from 'styled-components';
import { Breakpoints, Colors, Shadows } from '@myblueprint-spaces/papier-core';

export const Wrapper = styled.aside`
  height: 100%;
  width: 100%;
  background: ${Colors.white};
  z-index: 999;
  position: relative;

  @media (min-width: ${Breakpoints.large}em) {
    ${Shadows.shadow1};
    width: 15rem;
    mix-blend-mode: multiply;
    clip-path: inset(0 -0.5rem 0 0);
  }
`;

export const Top =  styled.div`
  padding: 0.75rem;
  border-bottom: 0.0625rem solid ${Colors.grey2};
  margin-bottom: 1.5rem;
`;

export const List = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  -webkit-padding-start: 0;
  margin-block-start: 0;
  -webkit-margin-start: 0;
  -webkit-margin-before: 0;
  margin-block-end: 0;
  -webkit-padding-before: 0;
  -webkit-margin-end: 0;
`;
