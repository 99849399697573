import styled from 'styled-components';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';

export const PasswordStrengthIndicatorWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const CheckList = styled.div`
  margin-top: 0.625rem
`;

export const StyledIcon = styled(Icon)`
  margin-left: 0;
  margin-right: 0.5rem;
`;

export const PasswordCheckContainer = styled.div`
  margin-top: 0.25rem;
`;
