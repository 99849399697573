import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { StudentAdminSelectors } from '@myblueprint-spaces/redux/lib/students';
import { ClassroomTeacherSelectors } from '@myblueprint-spaces/redux/lib/classrooms';

export default function useStudentsAmountWithClassroomId(classroomId) {
  return useSelector((state: IState) => {
    if (!classroomId) return undefined;

    let sList = StudentAdminSelectors.getStudentsWithClassroomId(state, classroomId);
    const demoScope = classroomId ? ClassroomTeacherSelectors.getDemoScope(state, classroomId) : null;
    sList = sList?.filter((s) => s?.ownerId !== demoScope?.ownerId);

    return sList?.length ?? 0;
  }
  );
}
