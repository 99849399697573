import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem, CustomLink } from './styles';
import { useRovingTabIndexProps } from '@myblueprint-spaces/react-roving-tabindex';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

const ItemContent = ({ icon, text, to, exactPath = true, onClick, dataTest }: { icon: string; text: string; to: string; exactPath?: boolean; onClick?: () => void; dataTest: string; }) => {
  const { t } = useTranslation(['Admin.Common', 'Common']);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isCurrent = matchPath({ path: `${to?.split('?')?.[0]}/*`, end: exactPath }, pathname);

  const keyDownHandle = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.view.event.preventDefault();
    }
  };

  const itemProps = useRovingTabIndexProps<HTMLButtonElement>({
    isSelected: false,
    disabled: false,
    customKeyDownHandler: keyDownHandle,
    actionOnClick: () => {
      onClick?.();
      navigate(to);
    }
  });

  return (
    <ListItem isCurrent={!!isCurrent} role="none">
      <CustomLink role="menuitem" {...itemProps} data-test={dataTest}>
        <CovalentGrid verticalAlign="middle" collapse={false}>
          <Icon type={icon} color={isCurrent ? 'primary1' : 'black3'} size="small" />
          <Typography type="body2" color={isCurrent ? 'primary1' : 'black3'}>{t(text)}</Typography>
        </CovalentGrid>
      </CustomLink>
    </ListItem>
  );
};

export default ItemContent;
