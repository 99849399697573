import styled from 'styled-components';
import Pill from '@myblueprint-spaces/papier-web/lib/Common/Pill';
import { Breakpoints, Colors, Shadows } from '@myblueprint-spaces/papier-core';

export interface ITagPillProps {
  noMargin?: boolean;
}

export const MainWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const TagPill = styled(Pill)(({ noMargin }: ITagPillProps) => `
  border-radius: 0.25rem;
  padding: 0 0.25rem;
  ${noMargin ? '' : 'margin: auto 0.25rem;'}
  text-transform: uppercase;
  vertical-align: middle;
  
  & span {
    display: block;
  }
`);

export const Card = styled.div(({ color }) => `
  background: ${Colors.white};
  border-radius: 0.5rem;
  padding: 1rem;
  ${Shadows.shadow1};
  overflow: hidden;
  position: relative;

  :before {
    background-color: ${color ? Colors[color] : Colors.primary1};
    height: 100%;
    width: 0.5rem;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: ${Breakpoints.medium}em) {
    padding: 0.75rem;
  }
`);

// can't be Typography we need to many modifications + we need this to be a <a> and StyledComponent doesn't work with 'as'
export const SkipToMain = styled.a`
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px; // required so that it can be considered "validElement"
  height: 1px; // required so that it can be considered "validElement"
  overflow: hidden;
  z-index: -999;

  &:focus, &:active {
    color: ${Colors.primary1};
    background-color: ${Colors.white};
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow:auto;
    margin: 0.75rem 35%;
    padding: 0.25rem;
    border-radius: 0.75rem;
    ${Shadows.primaryFloatingFocus};
    text-align: center;
    font-size: 1.2rem;
    z-index:99999;
  }
`;

export const Clipped = styled.div`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const TopInfo = styled.div`
  flex: 0 1 auto;
  width: 100%;
  padding: 0.25rem 3.125rem 2.1875rem 3.25rem;

  @media (max-width: ${Breakpoints.large}em) {
    padding: 1.5rem 1rem;
    padding-top: 1rem;
  }
`;

export const BottomInfo = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  overflow:scroll;
  padding: 2.5rem 3.25rem;
  background: ${Colors.grey4};
  border-top: 0.0625rem solid ${Colors.grey2};


  @media (max-width: ${Breakpoints.large}em) {
    padding: 0;
    padding-top: 1rem;
  }
`;

export const PillSkeletonWrapper = styled.div`
  background: ${Colors.white};
  border-radius: 125rem;
  margin: 0;
  border: 0.0625rem solid ${Colors.grey3};
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
`;
