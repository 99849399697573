import React, { useEffect, useState, useCallback } from 'react';
import UserHooks from 'modules/hooks/redux/user';
import AuthenticatedUser from './AuthenticatedUser';
import SchoolHooks from 'modules/hooks/redux/schools';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SessionExpiredDialog from './components/SessionExpiredDialog';
import useAuthSetup from 'modules/hooks/useAuthSetup';

const AuthenticatedUserContainer = (props) => {
  const [showSessionExpiredDialog, setShowSessionExpiredDialog] = useState(false);
  const loadUser = UserHooks.useLoadUser();
  const startHub = UserHooks.useStartHub();
  const user = UserHooks.useUser();
  const allAssociations = UserHooks.useAllAssociations();
  const logout = UserHooks.useLogout(false);
  const availableSchools = SchoolHooks.useSchools();
  const loadAdminUser = UserHooks.useLoadAdminUser();
  const verifyEmail = UserHooks.useVerifyEmail();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClick = React.useCallback(() => {
    setShowSessionExpiredDialog(false);
    logout();
    navigate('/', { replace: true });
  },[setShowSessionExpiredDialog, logout]);

  const onAuthenticationFail = useCallback(() => setShowSessionExpiredDialog(true), []);
  useAuthSetup(onAuthenticationFail);

  useEffect(() => {
    if (searchParams.has('sessionToken')) {
      searchParams.delete('sessionToken');
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, []);

  const allProps = {
    ...props,
    user,
    allAssociations,
    loadUser,
    startHub,
    availableSchools,
    loadAdminUser,
    verifyEmail,
    logout
  };

  return (
    <React.Fragment>
      <AuthenticatedUser {...allProps} />
      <SessionExpiredDialog
        open={showSessionExpiredDialog}
        handleOnClick={handleClick}
      />
    </React.Fragment>
  );
};

export default AuthenticatedUserContainer;
