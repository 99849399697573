import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { DisplayScopes, ScopeSelectors, Scope } from '@myblueprint-spaces/redux/lib/scopes';

export default function useScopeModel(scope?: Scope): DisplayScopes | undefined {
  return useSelector((state: IState) => {
    if (!scope) return undefined;
    const { scopeType, ownerId } = scope;
    return ScopeSelectors.getScopeModel(state, scopeType, ownerId);
  });
}
