import { useCallback, useContext, useEffect } from 'react';
import AuthenticationHooks from './redux/authentication';
import UserHooks from './redux/user';
import { AuthenticationContext } from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';
import { AuthenticationLifecycle } from '@myblueprint-spaces/redux';
import { useNavigate } from 'react-router-dom';

const useAuthSetup = (onFail?: () => void) => {
  const logout = UserHooks.useLogout();
  const refreshToken = AuthenticationHooks.useTokenRefresh();
  const exchangeToken = AuthenticationHooks.useExchangeToken();
  const { storedToken, saveToken } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const onAuthenticationFail = useCallback(() => {
    if (onFail) {
      onFail();
    } else {
      logout();
      navigate('/', { replace: true });
    }
  }, [onFail, logout, navigate]);

  const onReauthenticate = useCallback(async () => {
    if (storedToken) {
      return refreshToken(storedToken);
    }

    try {
      const token = await exchangeToken();
      token && saveToken(token);
    } catch (_) {
      return Promise.resolve();
    }

    return Promise.resolve();
  }, [refreshToken, storedToken, exchangeToken, saveToken]);

  useEffect(() => {
    AuthenticationLifecycle.setReauthenticationCallback(onReauthenticate);
    AuthenticationLifecycle.setUnauthorizedCallback(onAuthenticationFail as () => Promise<unknown>);

    return () => {
      AuthenticationLifecycle.setReauthenticationCallback(null);
      AuthenticationLifecycle.setUnauthorizedCallback(null);
    };
  }, [onAuthenticationFail, onReauthenticate]);
};

export default useAuthSetup;
