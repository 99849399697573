import React from 'react';
import { ErrorEnum } from './LoginError';
import NoAccessImage from 'assets/Asset_noAccess.png';
import MissingRecordImage from 'assets/Asset_credentials.png';
import ExternalLink from '@myblueprint-spaces/papier-web/lib/Atoms/ExternalLink';
import ComplexI18n from '@myblueprint-spaces/papier-web/lib/Common/ComplexI18n';
import { TFunction } from 'i18next';

export const getErrorState = (errorEnum: ErrorEnum, t: TFunction, isFrench: boolean): { title: string, subtitle: React.ReactElement | string, image: string } => {
  let title: string;
  let subtitle: React.ReactElement | string;
  let image: string;

  switch (errorEnum) {
    case ErrorEnum.NoAccess:
      title = t('NoAccess.Admin:Title');
      subtitle = t('LoginSignUp.Common:NoAccess.Login').toString();
      image = NoAccessImage;
      break;
    case ErrorEnum.MissingRecord:
      title = t('LoginSignUp.Student:RecordNotFound');
      subtitle = t('LoginSignUp.Student:RecordNotFound.Subtitle').toString();
      image = MissingRecordImage;
      break;
    case ErrorEnum.SSOError:
      title = t('LoginSignUp.Common:Login.Error.Title');
      subtitle = t('LoginSignUp.Common:Login.Error.Subtitle').toString();
      image = MissingRecordImage;
      break;
    case ErrorEnum.NoAssociationsError:
      title = t('NoAccess.Admin:Title');
      subtitle = (
        <ComplexI18n i18nKey="NoAssociations.Admin:Subtitle">
          <ExternalLink href={isFrench ? 'mailto:bonjour@spacesedu.com' : 'mailto:hello@spacesedu.com'} />
        </ComplexI18n>
      );
      image = MissingRecordImage;
      break;
    case ErrorEnum.EmailMismatch:
      title = t('Invitations.Common:InvalidCredentials.Title');
      subtitle = t('Invitations.Common:InvalidCredentials.Subtitle').toString();
      image = MissingRecordImage;
      break;
  }

  return { title, subtitle, image };
};
