import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Popover, { IPopoverConfig } from '@myblueprint-spaces/papier-web/lib/Common/Popover';
import ExternalLink from '@myblueprint-spaces/papier-web/lib/Atoms/ExternalLink';
import ImageBlock from '@myblueprint-spaces/papier-web/lib/Atoms/ImageBlock';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import EmptyState from '@myblueprint-spaces/papier-web/lib/Common/EmptyState';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { EmptyStateWrapper, HeaderWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import NoSchools from 'assets/Asset_no_school.png';
import { getAccountTypeStrCamelCase } from 'modules/helpers/accountTypeHelper';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { ConnectedScopeAvatar } from '@myblueprint-spaces/web-common/lib/Common/ConnectedAvatar';
import { INoSchoolsAvailableProps } from './types';
import AccountTypeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AccountTypeContext';
import { AvatarButton } from 'layouts/LoggedLayout/components/Topbar/styles';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { Scope, userScope } from '@myblueprint-spaces/redux/lib/scopes';

const NoSchoolsAvailable = ({ logout, user }: INoSchoolsAvailableProps) => {
  const { t } = useTranslation(['Admin.Common', 'Account.Teacher', 'Account.Student', 'Account.Family', 'Common']);
  const { isSmall } = useContext(WindowSizeContext);
  const accountType = useContext(AccountTypeContext);
  const menuTrigger = React.useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const camelCaseAcc = getAccountTypeStrCamelCase(accountType);
  const [scope, setScope] = useState<Scope | undefined>();

  useEffect(() => {
    setScope(userScope(user.id));
  }, [user]);

  const handleLogout = useCallback(() => {
    logout();
    setMenuOpen(false);
  }, [setMenuOpen, logout]);

  const menuItems = useMemo(() => ([
    {
      title: t('Common:Actions.Logout'),
      type: 'menuItem',
      textColor: 'danger1',
      onClick: handleLogout,
      dataTest: 'logout'
    }
  ]), [handleLogout]);

  return (
    <React.Fragment>
      <HeaderWrapper>
        <AvatarButton ref={menuTrigger} type="button"
          onClick={() => setMenuOpen(!menuOpen)} title={t(`Account.${camelCaseAcc}:Menu`).toString()} data-test="account-switch-avatar">
          <ConnectedScopeAvatar scope={scope} size={isSmall ? 'small' : 'medium'} />
        </AvatarButton>
        <Popover triggerRef={menuTrigger} open={menuOpen} config={menuItems as IPopoverConfig[]} hostAriaLabel={t(`Account.${camelCaseAcc}:Menu`)}
          onClose={() => setMenuOpen(false)} triggerPosition="bottom-right" menuPosition="bottom-right" />
      </HeaderWrapper>
      <EmptyStateWrapper>
        <Row align="center">
          <Column columns={{ small: 8, medium: 10 }} collapse={isSmall}>
            <EmptyState
              image={<ImageBlock src={NoSchools} alt="" aria-hidden="true" />}
              title={{ children: t('Admin.Common:NoSchoolsAvailable.Title'), type: isSmall ? 'body1' : 'header4', weight: 'demibold' }}
              subtitle={{ children: t('Admin.Common:NoSchoolsAvailable.Subtitle'), type: isSmall ? 'compact': 'body2', color: 'black3' }}
              action={<ExternalLink href={`mailto:${t('Admin.Common:NoSchoolsAvailable.MailTo')}`} style={{ marginTop: '-1rem' }}>
                <Typography type={isSmall ? 'compact' : 'body2'} color="primary1" weight="medium">{t('Admin.Common:NoSchoolsAvailable.MailTo')}</Typography>
              </ExternalLink>}
            />
          </Column>
        </Row>
      </EmptyStateWrapper>
    </React.Fragment>
  );
};

export default withErrorBoundaries(NoSchoolsAvailable);
