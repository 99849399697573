import React from 'react';
import { useTranslation } from 'react-i18next';
import ExternalLink from '@myblueprint-spaces/papier-web/lib/Atoms/ExternalLink';
import ComplexI18n from '@myblueprint-spaces/papier-web/lib/Common/ComplexI18n';
import EmptyState from '@myblueprint-spaces/papier-web/lib/Common/EmptyState';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import styled from 'styled-components';
import EmptyStateImage from 'assets/Asset_linkExpired.png';

export const StyledAsset = styled.img`
  width: 12.5rem;
  height: 12.5rem;
`;

const ErrorState = () => {
  const { t, i18n } = useTranslation(['Common', 'Invitations.Common', 'Invitations.Admin']);

  return (
    <Row align="center" verticalAlign="middle" span>
      <Column columns={{ small: 8, medium: 6, large: 4 }}>
        <EmptyState
          image={<StyledAsset src={EmptyStateImage} alt="" />}
          title={{ children: t('Invitations.Common:ErrorState.Title'), color: 'danger1', as: 'h1' }}
          subtitle={{ children: <ComplexI18n i18nKey="Invitations.Admin:ErrorState.Subtitle">
            <ExternalLink href={i18n.language.startsWith('fr') ? 'mailto:bonjour@spacesedu.com' : 'mailto:hello@spacesedu.com'} />
          </ComplexI18n> }} />
      </Column>
    </Row>
  );
};

export default ErrorState;
