import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { StaffActions } from '@myblueprint-spaces/redux/lib/staff';

export default function useLoadStaff() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (schoolId?: string) => dispatch(StaffActions.loadStaff(schoolId)),
    [dispatch]
  );
}
