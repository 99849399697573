import React from 'react';
import InviteHooks from 'modules/hooks/redux/invites';
import SignUpHooks from 'modules/hooks/redux/signup';
import ScopesHooks from 'modules/hooks/redux/scopes';
import LoginEmail from './LoginEmail';
import { isSuperAdminInvite } from 'modules/helpers/accountTypeHelper';
import { DistrictDisplayScope, SchoolDisplayScope } from '@myblueprint-spaces/redux/lib/scopes/types';
import AuthenticationHooks from 'modules/hooks/redux/authentication';

const LoginEmailContainer = () => {
  const inviteId = SignUpHooks.useInviteId();
  const invite = InviteHooks.useInviteWithId(inviteId);
  const isDistrictAdmin = isSuperAdminInvite(invite);
  const scopeModel = ScopesHooks.useScopeModel(invite?.contextScope);
  const isLicensed = (scopeModel as DistrictDisplayScope | SchoolDisplayScope | undefined)?.licensed;
  const resetSettings = AuthenticationHooks.useResetSettings();
  const loadSettings = AuthenticationHooks.useLoadSettings();

  const allProps = {
    invite,
    isDistrictAdmin,
    isLicensed,
    resetSettings,
    loadSettings
  };

  return <LoginEmail {...allProps} />;
};

export default LoginEmailContainer;
