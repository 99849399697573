import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { CurrentSchoolAccountInfoContextProvider } from './CurrentSchoolAccountInfoContext';
import SchoolHooks from 'modules/hooks/redux/schools';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import LoggedUserContext from './LoggedUserContext';
import { LicenseModel } from '@myblueprint-spaces/redux/lib/api/admin/admin';
import { SchoolDisplayScope } from '@myblueprint-spaces/redux/lib/scopes';

export interface ICurrentSchoolContext {
  school?: SchoolDisplayScope;
  mainProviderSchool?: SchoolDisplayScope | null;
  setSchool: React.Dispatch<React.SetStateAction<SchoolDisplayScope | undefined>>;
  license?: LicenseModel;
}

interface MostRecentSchool {
  id: string;
  date: number;
}

const CurrentSchoolContext = createContext<ICurrentSchoolContext>({} as ICurrentSchoolContext);

const CurrentSchoolContextProvider = ({ children }) => {
  const allSchools = SchoolHooks.useLastVisited();
  const license = SchoolHooks.useLicense();
  const { availableSchools } = useContext(LoggedUserContext);
  const updateLastVisited = SchoolHooks.useUpdateLastVisited();
  const previousContext = useContext(CurrentSchoolContext);
  const { school: mainProviderSchool } = 'school' in previousContext ? previousContext : { school: null };
  const [school, setSchool] = useState<SchoolDisplayScope | undefined>(undefined);
  const loaded = useRef(false);

  useEffect(() => {
    if (mainProviderSchool === null) {
      let mostRecentSchoolId: MostRecentSchool | undefined = undefined;
      if (availableSchools.length > 1) {
        if (allSchools) {
          Object.entries(allSchools).forEach((s) => {
            if (s[1] && (!mostRecentSchoolId || mostRecentSchoolId.date < s[1].getTime())) {
              mostRecentSchoolId = { id: s[0], date: s[1]!.getTime() };
            }
          });
          setSchool((!mostRecentSchoolId || (mostRecentSchoolId as MostRecentSchool).id === 'all') ? undefined : availableSchools?.find((s) => s.id === mostRecentSchoolId?.id) as SchoolDisplayScope);
        }
      } else {
        setSchool(availableSchools[0] as SchoolDisplayScope);
      }
      loaded.current = true;
    } else {
      setSchool(mainProviderSchool);
    }
  // cannot/should not have dependencies as it is to run only first time
  }, []);

  useEffect(() => {
    mainProviderSchool !== null && setSchool(mainProviderSchool);
  }, [mainProviderSchool]);

  useEffect(() => {
    if (loaded.current) {
      updateLastVisited(school?.id as string);
    }
  }, [school]);

  if (typeof allSchools === undefined) return <LoadingDots />;

  return (
    <CurrentSchoolContext.Provider value={{ school, setSchool, mainProviderSchool, license }}>
      <CurrentSchoolAccountInfoContextProvider>
        {children}
      </CurrentSchoolAccountInfoContextProvider>
    </CurrentSchoolContext.Provider>
  );
};

export { CurrentSchoolContextProvider };
export default CurrentSchoolContext;
