import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import EmptyState from '@myblueprint-spaces/papier-web/lib/Common/EmptyState';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import styled from 'styled-components';
import { getErrorState } from './helper';
import { useNavigate } from 'react-router-dom';

export const StyledAsset = styled.img`
  width: 12.5rem;
  height: 12.5rem;
`;

export enum ErrorEnum {
  NoAccess = 0,
  MissingRecord,
  SSOError,
  NoAssociationsError,
  EmailMismatch
}

const LoginError = ({ errorEnum }: { errorEnum: ErrorEnum }) => {
  const { t, i18n } = useTranslation(['NoAccess.Admin', 'NoAssociations.Admin', 'Common', 'LoginSignUp.Common', 'LoginSignUp.Student', 'Invitations.Common']);
  const isFrench = i18n.language.startsWith('fr');
  const { title, subtitle, image } = getErrorState(errorEnum, t, isFrench);
  const navigate = useNavigate();

  const onClick = useCallback(() => navigate('/'), [navigate]);

  return (
    <Row align="center" verticalAlign="middle" span>
      <Column columns={{ small: 8, medium: 6, large: 3 }}>
        <EmptyState
          image={<StyledAsset src={image} alt="" aria-hidden="true" />}
          title={{ children: title, as: 'h1', color: errorEnum === ErrorEnum.EmailMismatch ? 'danger1' : 'black1' }}
          subtitle={{ children: subtitle }}
          action={<Button expanded color="primary" text={t('Common:GoBackToX', { locationName: t('Common:Login') })} onClick={onClick} dataTest="go-to-spaces" />} />
      </Column>
    </Row>
  );
};

export default LoginError;
