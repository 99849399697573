import styled from 'styled-components';

export const CheckboxLabel = styled.label`
  padding: 0 0.5rem;
  user-select: none;
  cursor: pointer;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  padding-top: 0.75rem;
`;
