import styled from 'styled-components';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { Colors, Breakpoints } from '@myblueprint-spaces/papier-core';

export const LoginFormWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const CaptchaWrapper = styled(Typography)`
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  width: 100%;
  padding: 0.5rem 2rem;
  border-top: 0.0625rem solid ${Colors.grey3};
  text-align: center;
  background: ${Colors.white}
`;

export const ConnectWithGoogleBlock = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
  border-bottom: 0.0625rem solid ${Colors.grey3};
  padding-bottom: 1.5rem;
  @media (min-width: ${Breakpoints.medium}em) {
    padding-bottom: 2rem;
  }
`;

export const Or = styled(Typography)`
  position: absolute;
  bottom: -0.875rem;
  text-transform: lowercase;
  width: 1.5rem;
  left: calc(50% - 0.75rem);
  text-align: center;
  font-weight: 500;
  background: ${Colors.white};
`;

export const SubtextWrapper = styled.div`
  margin-top: 2rem;
`;

export const CleanButtonWrapper = styled.span`
  margin-left: 0.5rem;
`;

export const CustomRow = styled(Row)`
  max-height: calc(100% - 3.25rem);
  height: 100%;
  overflow: auto;
  margin-right: 0rem;
  min-height: 0;
  position: absolute;
  padding: 3.5rem 0;
  @media (max-width: ${Breakpoints.medium}em) {
    max-height: calc(100% - 7.5rem);
    padding: 2.25rem 0;
  }
`;

export const CustomColumn = styled(Column)`
  position: relative;
  padding-bottom: 2rem;
`;

export const StyledAsset = styled.img`
  width: 12.5rem;
  height: 12.5rem;
`;

export const ConnectWithExternal = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
  border-bottom: 0.0625rem solid ${Colors.grey3};
  padding-bottom: 1.5rem;
  @media (min-width: ${Breakpoints.medium}em) {
    padding-bottom: 2rem;
  }
`;
