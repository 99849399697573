import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import BackendAdapter from 'i18next-multiload-backend-adapter';
import HttpApi from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import * as Fallback from '@myblueprint-spaces/spaces-resx';
import en from '@myblueprint-spaces/spaces-resx/json/en.json';
import fr from '@myblueprint-spaces/spaces-resx/json/fr.json';
import enCA from '@myblueprint-spaces/spaces-resx/json/en-CA.json';
import enUS from '@myblueprint-spaces/spaces-resx/json/en-US.json';
import frCA from '@myblueprint-spaces/spaces-resx/json/fr-CA.json';
import { Language, getLanguageFromCulture } from '@myblueprint-spaces/redux/lib/api/common/resources';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  en,
  fr,
  'en-CA': enCA,
  'en-US': enUS,
  'fr-CA': frCA
};

const getResXBundle = () => {
  const lang = getLanguageFromCulture(i18n.language);
  const enBundle = JSON.parse(JSON.stringify(en));
  const frBundle = JSON.parse(JSON.stringify(fr));
  const enKeys = Object.keys(en);
  const frKeys = Object.keys(fr);
  const enUsKeys = Object.keys(enUS);
  const enCaKeys = Object.keys(enCA);
  const frCaKeys = Object.keys(frCA);

  switch (lang) {
    case Language.EnglishCanada:
      enCaKeys.forEach((enCAKey) => {
        if (enKeys.includes(enCAKey)) {
          enBundle[enCAKey] = { ...(en[enCAKey]), ...(enCA[enCAKey]) };
        }
      });
      return enBundle;
    case Language.EnglishUS:
      enUsKeys.forEach((enUSKey) => {
        if (enKeys.includes(enUSKey)) {
          enBundle[enUSKey] = { ...(en[enUSKey]), ...(enUS[enUSKey]) };
        }
      });
      return enBundle;
    case Language.FrenchCanada:
      frCaKeys.forEach((frCAKey) => {
        if (frKeys.includes(frCAKey)) {
          frBundle[frCAKey] = { ...(fr[frCAKey]), ...(frCA[frCAKey]) };
        }
      });
  }
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnNull: false,
    compatibilityJSON: 'v3',
    detection: {
      lookupQuerystring: 'lang',
      lookupCookie: 'lang',
    },
    keySeparator: false,
    appendNamespaceToMissingKey: true,
    parseMissingKeyHandler: (key) => {
      const resXObj = getResXBundle();
      const [nameSpace, keyString] = key.split(':');
      const nameSpaceObj = resXObj?.[nameSpace];
      const value = nameSpaceObj?.[keyString];
      return value ?? `Resx:${key}`;
    },
    fallbackLng: Fallback.i18nextFallback,
    defaultNS: 'Common',
    ns: ['Common'],
    interpolation: {
      escapeValue: false
    },
    backend: {
      backends: [
        new BackendAdapter(null, { backend: HttpApi, backendOption: {
          loadPath: `${process.env.REACT_APP_API_URI}Resources?lng={{lng}}&ns={{ns}}`
        } }),
        resourcesToBackend(resources)
      ]
    }
  });

export default i18n;
