import React, { useContext, useMemo } from 'react';
import Notifications from './Notifications';
import { INotificationsProps } from './types';
import { NotificationsConfigContextProvider } from '@myblueprint-spaces/web-common/lib/Common/Notifications';
import { useNotificationInteraction } from './hooks';
import { formatDateShort } from '@myblueprint-spaces/modules';
import { useTranslation } from 'react-i18next';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';

const NotificationsContainer = (props: INotificationsProps) => {
  const { closePopover } = props;
  const getNotificationInteraction = useNotificationInteraction();
  const { isSmall } = useContext(WindowSizeContext);
  const { i18n } = useTranslation();

  const notificationsConfig = useMemo(() => ({
    getNotificationInteraction,
    closePopover,
    getItemAdditionalInfo: (item) => {
      const formattedDate = formatDateShort(item?.lastUpdated, i18n.language);
      return <Typography type={(!closePopover || isSmall) ? 'subtitle2' : 'subtitle1'} color="black3" style={{ alignSelf: 'center' }}>{formattedDate}</Typography>;
    }
  }), [closePopover, getNotificationInteraction, isSmall]);

  return (
    <NotificationsConfigContextProvider {...notificationsConfig}>
      <Notifications {...props} />
    </NotificationsConfigContextProvider>
  );
};

export default NotificationsContainer;
