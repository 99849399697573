import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { SchoolSelectors } from '@myblueprint-spaces/redux/lib/schools';

export default function useSchoolWithId(schoolId?: string) {
  return useSelector((state: IState) =>
    schoolId
      ? SchoolSelectors.getSchoolWithId(state, schoolId)
      : undefined
  );
}
