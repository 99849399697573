
import { PasswordStrength } from '@myblueprint-spaces/appstate/lib/helpers/passwordStrength';
import { PasswordStrengthProgress } from './types';

export const getPasswordStrengthProgress = (passwordStrength: PasswordStrength): PasswordStrengthProgress => {
  let passes = 0.0;
  let color = '';

  for (const key in passwordStrength) {
    if (passwordStrength[key]) {
      passes++;
    }
  }

  const progress = passes / Object.keys(passwordStrength).length;

  if (progress > 0.75) {
    color = 'success1';
  } else if (progress > 0.25) {
    color = 'warning1';
  } else {
    color = 'danger1';
  }

  return {
    progress,
    color
  };
};
