import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import { RowColumn, Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import { useTranslation } from 'react-i18next';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { EmptyStateWrapper, CustomImage } from './styles';
import EmptyStateImg from 'assets/Asset_noResults.png';

interface IEmptyStateProps {
  emptySearchSubtitle: string;
  clearSearch: () => void
}

const SearchEmptyState = ({ emptySearchSubtitle, clearSearch }: IEmptyStateProps) => {
  const { t } = useTranslation(['Common']);

  return (
    <EmptyStateWrapper>
      <Row align="center">
        <Column columns={{ small: 10, medium: 8 }} collapse>
          <CustomImage alt="" src={EmptyStateImg} />
          <HeaderBlock
            title={{ children: t('Common:NoResults'), type: 'header4' }}
            subtitle={{ children: emptySearchSubtitle, type: 'body2' }} />
          <RowColumn collapse={false}>
            <Button
              icon="refresh"
              size="medium"
              color="inversePrimary"
              expanded
              shadow
              style={{ margin: '0 1rem' }}
              onClick={clearSearch}
              text={t('Common:Actions.ClearSearch')}
              dataTest="empty-state-clear-search"
            />
          </RowColumn>
        </Column>
      </Row>
    </EmptyStateWrapper>
  );
};

SearchEmptyState.propTypes = {
  emptySearchSubtitle: PropTypes.string,
  clearSearch: PropTypes.func
};

export default withErrorBoundaries(SearchEmptyState as FunctionComponent<IEmptyStateProps>);
