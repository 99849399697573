import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SsoProcessorProps } from './types';
import { failToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import LoadingGif from '@myblueprint-spaces/papier-web/lib/Common/LoadingGif';
import { useHandleAuthorizationErrors, onReceiveMessage } from '@myblueprint-spaces/web-common/lib/Common/ExternalLoginBase';
import { useNavigate } from 'react-router-dom';
import { useSSOLogin } from 'components/Login/SamlLogin/hooks';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';

const SsoProcessor = (props: SsoProcessorProps) => {
  const { data, saveAuthenticationData, saveExternalUserData } = props;
  const { t } = useTranslation(['Common', 'LoginSignUp.Common', 'Spaces.Common']);
  const navigate = useNavigate();
  const getFullUrl = useGetFullUrl();
  const missingRecordUrl = getFullUrl('/missing-record');

  const onError = useHandleAuthorizationErrors(true, true);
  const onAuthenticated = useSSOLogin();
  const onSignUp = useCallback(() => navigate(missingRecordUrl), [navigate, missingRecordUrl]);

  const receiveMessage = onReceiveMessage(onError, saveExternalUserData, onSignUp, saveAuthenticationData, onAuthenticated);

  useEffect(() => {
    try {
      const dataDecoded = atob(data);
      const ssoEvent = {
        data: JSON.parse(dataDecoded)
      };

      receiveMessage(ssoEvent);
    } catch (e) {
      failToast(e, t);
      navigate('/');
    }
  }, [data, saveAuthenticationData, saveExternalUserData, navigate]);

  return <LoadingGif />;
};

export default withErrorBoundaries(SsoProcessor);
