import useSentStaffInvites from './useSentStaffInvites';
import useStaffRequestInvites from './useStaffRequestInvites';
import useLoadStaffInvites from './useLoadStaffInvites';
import useResendInvite from './useResendInvite';
import useDeleteInvites from './useDeleteInvites';
import useDeleteInvite from './useDeleteInvite';
import useInviteStudentAdmin from './useInviteStudentAdmin';
import useEditStudentInvite from './useEditStudentInvite';
import useApproveTeacherInvite from './useApproveTeacherInvite';
import useApproveTeacherInvites from './useApproveTeacherInvites';
import useInviteWithId from './useInviteWithId';
import useLoadInviteWithGuid from './useLoadInviteWithGuid';
import useInviteId from './useInviteId';
import useAcceptInvite from './useAcceptInvite';
import useTeacherRequestInvites from './useTeacherRequestInvites';
import useStudentInvitesWithInvitedBy from './useStudentInvitesWithInvitedBy';

const InviteHooks = {
  useSentStaffInvites,
  useStaffRequestInvites,
  useLoadStaffInvites,
  useResendInvite,
  useDeleteInvites,
  useDeleteInvite,
  useInviteStudentAdmin,
  useEditStudentInvite,
  useApproveTeacherInvite,
  useApproveTeacherInvites,
  useInviteWithId,
  useLoadInviteWithGuid,
  useInviteId,
  useAcceptInvite,
  useTeacherRequestInvites,
  useStudentInvitesWithInvitedBy
};

export default InviteHooks;
