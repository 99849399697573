import LoadingGif from '@myblueprint-spaces/papier-web/lib/Common/LoadingGif';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import { LoginProviders } from '@myblueprint-spaces/redux/lib/user';
import ExternalLoginBase, { useHandleAuthorizationErrors } from '@myblueprint-spaces/web-common/lib/Common/ExternalLoginBase';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import useFunctionState from '@myblueprint-spaces/papier-web/lib/modules/hooks/useFunctionState';
import React, { useCallback, useEffect } from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { IdpProcessorProps } from './types';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import { useSSOLogin } from 'components/Login/SamlLogin/hooks';
import useId from '@myblueprint-spaces/papier-web/lib/modules/hooks/useId';

const WindowLoader = (props: { initiateLogin?: () => void }) => {
  const { initiateLogin } = props;
  useEffect(() => {
    initiateLogin?.();
  }, []);

  return <LoadingGif />;
};

const IdpProcessor = (props: IdpProcessorProps) => {
  const { settings, resetSettings } = props;
  const [onClose, setOnClose] = useFunctionState(() => undefined);
  const navigate = useNavigate();
  const getFullUrl = useGetFullUrl();
  const moreInfoUrl = getFullUrl('/more-info');
  const id = useId();

  const handleAuthorizeFail = useHandleAuthorizationErrors(true);
  const handleAuthenticated = useSSOLogin();
  const handleSSOSignUp = useCallback(() => navigate(moreInfoUrl), [navigate, moreInfoUrl]);
  const onPopUpClose = useCallback(() => {
    navigate('/');
    resetSettings();
  }, [resetSettings, navigate]);

  useEffect(() => {
    const { ssoRequired } = settings || {};

    setOnClose((userClosedWindow) => {
      if (userClosedWindow) {
        if (ssoRequired) {
          navigate('/no-access');
        } else {
          navigate('/');
        }
        resetSettings();
      }
    });
  }, [settings, navigate]);

  if (typeof settings === 'undefined') return <LoadingDots />;

  return (
    <ExternalLoginBase
      id={id}
      provider={LoginProviders.saml}
      onClose={onClose}
      onAuthenticated={handleAuthenticated}
      onError={handleAuthorizeFail}
      onSignUp={handleSSOSignUp}
      onPopUpClose={onPopUpClose}
    >
      {({ initiateLogin }) => <WindowLoader initiateLogin={initiateLogin} /> }
    </ExternalLoginBase>
  );
};

export default withGoogleReCaptcha(withErrorBoundaries(IdpProcessor));
