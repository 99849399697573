import useStaffCount from './useStaffCount';
import useStaff from './useStaff';
import useLoadStaff from './useLoadStaff';
import useEditStaff from './useEditStaff';
import useCreateTeacher from './useCreateTeacher';
import useStaffRequestCount from './useStaffRequestCount';
import useClassroomStaff from './useClassroomStaff';
import useSetStaffStatus from './useSetStaffStatus';

const StaffHooks = {
  useStaffCount,
  useStaff,
  useLoadStaff,
  useEditStaff,
  useCreateTeacher,
  useStaffRequestCount,
  useClassroomStaff,
  useSetStaffStatus,
};

export default StaffHooks;
