import { Spec } from 'immutability-helper';
import { MAX_EMAIL_LENGTH, validateEmail, validateName } from '@myblueprint-spaces/modules';
import { NAME_MAX_CHAR } from 'modules/constants';

export type AccountFormInfoItem<T = string> = {
  value?: Spec<T> | T | null,
  valid?: Spec<boolean> | boolean,
  validationText?: Spec<string | null> | string | null
};

export interface MainAccountInfo {
  firstName: AccountFormInfoItem;
  lastName: AccountFormInfoItem;
  email: AccountFormInfoItem;
}

export const getValidationDetails = (localState, t, ignoreEmail = false) => {
  const validDetails = {} as MainAccountInfo;
  let valid = true;

  const { firstName: { value: fName }, lastName: { value: lName }, email } = localState;

  if (fName === '') {
    valid = false;
    validDetails.firstName = {
      valid: { $set: false },
      validationText: { $set: t('Admin.Common:Validation.FirstName.Empty') }
    };
  }

  if (lName === '') {
    valid = false;
    validDetails.lastName = {
      valid: { $set: false },
      validationText: { $set: t('Admin.Common:Validation.LastName.Empty') }
    };
  }

  if (!validateName(fName)) {
    valid = false;
    validDetails.firstName = {
      valid: { $set: false },
      validationText: { $set: t('Common:Validations.FirstName') }
    };
  }

  if (!validateName(lName)) {
    valid = false;
    validDetails.lastName = {
      valid: { $set: false },
      validationText: { $set: t('Common:Validations.LastName') }
    };
  }

  if (fName.length > NAME_MAX_CHAR) {
    valid = false;
    validDetails.firstName = {
      valid: { $set: false },
      validationText: { $set: t('Common:FirstName.LengthValidation', { count: fName.length - NAME_MAX_CHAR, limit: NAME_MAX_CHAR }) }
    };
  }

  if (lName.length > NAME_MAX_CHAR) {
    valid = false;
    validDetails.lastName = {
      valid: { $set: false },
      validationText: { $set: t('Common:LastName.LengthValidation', { count: lName.length - NAME_MAX_CHAR, limit: NAME_MAX_CHAR }) }
    };
  }

  if (!ignoreEmail && !validateEmail(email.value.trim() as string)) {
    valid = false;
    validDetails.email = {
      valid: { $set: false }
    };
  }

  if (!ignoreEmail && email.value.length > MAX_EMAIL_LENGTH) {
    valid = false;
    validDetails.email = {
      valid: { $set: false },
      validationText: { $set: t('Common:Email.LengthValidation', { count: email.value.length - MAX_EMAIL_LENGTH, limit: MAX_EMAIL_LENGTH }) }
    };
  }

  return { validDetails, valid: valid };
};
