import { SchoolDisplayScope } from '@myblueprint-spaces/redux/lib/scopes';
import { User } from '@myblueprint-spaces/redux/lib/user';
import React from 'react';

export interface ILoggedUserContextProps {
  availableSchools: SchoolDisplayScope[];
  loggedUserBasicInfo: User;
}

const LoggedUserContext = React.createContext<ILoggedUserContextProps>({} as ILoggedUserContextProps);

export default LoggedUserContext;
