import React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { UserActions } from '@myblueprint-spaces/redux/lib/user';

export default function useUpdateUserAvatar() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (attachmentId: string) => dispatch(UserActions.updateUserAvatar(attachmentId)),
    [dispatch]);
}
