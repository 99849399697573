import React, { RefObject, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, RowColumn, Column, FullHeightScrollableColumn } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import { Clipped } from 'components/Shared/styles';
import { CurrentSchoolContextProvider } from 'contexts/CurrentSchoolContext';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import AccountTypeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AccountTypeContext';
import { AccountType } from '@myblueprint-spaces/appstate';

const LoggedLayout = ({ children }: { children: React.ReactChild | React.ReactChild[] }) => {
  const { isLargeUp } = React.useContext(WindowSizeContext);
  const mainContentRef = useRef<HTMLDivElement>(null);

  return (
    <AccountTypeContext.Provider value={AccountType.Admin}>
      <CurrentSchoolContextProvider>
        <div ref={mainContentRef} style={{ height: '100%', width: '100%', overflowY: 'clip' }}>
          <Row expanded span collapse>
            {isLargeUp && <Column shrink>
              <Sidebar />
            </Column>}
            <Column style={{ height: '100%' }}>
              <RowColumn expanded collapse={false}>
                <Topbar mainContentRef={mainContentRef as RefObject<HTMLDivElement>} />
              </RowColumn>
              <Row expanded span>
                <FullHeightScrollableColumn style={{ height: 'calc(100% - 4rem)' }}>
                  <main style={{ display: 'contents' }} aria-labelledby="mainLabel">
                    <Clipped id="mainLabel">Administrative Spaces</Clipped>
                    {children}
                  </main>
                </FullHeightScrollableColumn>
              </Row>
            </Column>
          </Row>
        </div>
      </CurrentSchoolContextProvider>
    </AccountTypeContext.Provider>
  );
};

LoggedLayout.propTypes = {
  children: PropTypes.oneOf([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

export default LoggedLayout;
