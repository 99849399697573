// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-undef
__webpack_nonce__ = window.nonce;

import React from 'react';
import { createRoot } from 'react-dom/client';
import AppRoot from 'layouts/AppRoot';
import init from 'reducers/store';
import { ThemeProvider } from 'styled-components';
import { Themes } from '@myblueprint-spaces/papier-core';
import FontImport from '@myblueprint-spaces/papier-web/lib/modules/FontImport';
import { IconImport } from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import { UtilityClasses } from '@myblueprint-spaces/papier-web/lib/Common/UtilityClasses';
import LoadingGif from '@myblueprint-spaces/papier-web/lib/Common/LoadingGif';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import fromEntries from 'object.fromentries';

const appStore = init();

const renderApp = () => {
  // Object.fromEntries pollyfill
  if (!Object.fromEntries) {
    // @ts-expect-error This will error
    fromEntries.shim();
  }

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <React.Fragment>
      <FontImport />
      <IconImport />
      <UtilityClasses />
      <Provider store={appStore}>
        <BrowserRouter>
          <React.Suspense fallback={<LoadingGif />}>
            <ThemeProvider theme={{ name: Themes.EpLight }}>
              <AppRoot />
            </ThemeProvider>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    </React.Fragment>
  );
};

// @ts-expect-error hot does not support typescript
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // @ts-expect-error hot does not support typescript
  module.hot.accept('layouts/AppRoot', renderApp);
}

renderApp();
