import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { InviteSelectors } from '@myblueprint-spaces/redux/lib/invites';
import { useTranslation } from 'react-i18next';
import { getInviteAccountString } from 'pages/StaffManager/components/Shared/helpers';

export default function useSentStaffInvites(schoolId?: string) {
  const { t } = useTranslation(['Common', 'Admin.StaffManager']);

  return useSelector((state: IState) => {
    return InviteSelectors.getSentStaffInvites(state, schoolId)?.map((i) => ({
      ...i,
      account: getInviteAccountString(i, t)
    }));
  });
}
