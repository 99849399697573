import useSchools from './useSchools';
import useSchoolWithId from './useSchoolWithId';
import useLastVisited from './useLastVisited';
import useUpdateLastVisited from './useUpdateLastVisited';
import useLoadSignUpSchools from './useLoadSignUpSchools';
import useSignUpSchools from './useSignUpSchools';
import useLicense from './useLicense';
import useSchoolLinks from './useSchoolLinks';

const SchoolHooks = {
  useSchools,
  useSchoolWithId,
  useLastVisited,
  useUpdateLastVisited,
  useLoadSignUpSchools,
  useSignUpSchools,
  useLicense,
  useSchoolLinks
};
export default SchoolHooks;
