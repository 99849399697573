import React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { UserActions } from '@myblueprint-spaces/redux/lib/user';

export default function useDeleteUserAvatar() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(() => dispatch(UserActions.removeUserAvatar()), [dispatch]);
}
