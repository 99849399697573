import { useCallback } from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { StaffActions } from '@myblueprint-spaces/redux/lib/staff';

export default function useSetStaffStatus() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return useCallback((userIds: string[], active: boolean, schoolIds?: string[]) => dispatch(StaffActions.setStaffStatus(userIds, active, schoolIds)), []);
}

