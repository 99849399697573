import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import { updateIntercomLastRequest } from 'modules/services/intercom';
import withRouter from '@myblueprint-spaces/web-common/lib/modules/hocs/withRouter';

export interface IRouteContext {
  previousPaths: string[];
  goBack: (fallbackUrl?: string|null) => void;
  remove: (filterFn: (e: string) => boolean) => void;
}
export const RouteContext = createContext<IRouteContext>({} as IRouteContext);

class RouteContextProvider extends Component<{ location; navigate; user; children }, { previousPaths: string[] }> {
  public static propTypes = {
    location: PropTypes.object,
    navigate: PropTypes.func,
    children: PropTypes.array,
    user: PropTypes.object
  };

  constructor(props) {
    super(props);
    const fromStorage = sessionStorage?.getItem('myb-history-stack');

    this.state = {
      previousPaths: fromStorage ? fromStorage.split(',') : ([] as string[])
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { previousPaths } = this.state;
    // Do not add multiple instances of the same url
    // Do not add 404 as a possible goBack path
    if (previousPaths[previousPaths.length - 1] !== prevProps.location.pathname && location.pathname != prevProps.location.pathname && prevProps.location.pathname !== '/404') {
      // update Intercom when URL changes
      updateIntercomLastRequest();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState((state) => ({
        previousPaths: state.previousPaths.concat([prevProps.location.pathname])
      }), () => {
        const { previousPaths: newPP } = this.state;
        sessionStorage.setItem('myb-history-stack', newPP.toString());
      });
    }
  }

  // Custom implementation to go back to the previously
  // accessed authenticated URL.
  // If goBack is triggered when previous path was login,
  // the user is sent to the root url
  goBack = (fallbackUrl = null) => {
    const { navigate, location } = this.props;
    const { previousPaths } = this.state;
    const DASHBOARD_URL = '/dashboard';

    if (!previousPaths || previousPaths.length === 0) {
      navigate(DASHBOARD_URL);
    // if you tried to access a url, got sent to login and that page has a button with a goBack you will get sent back to login. To avoid this, there will be a fallback
    } else if (previousPaths[previousPaths?.length - 1] === '/' && fallbackUrl !== null) {
      navigate(fallbackUrl);
    } else if (location.pathname === '/404') {
      // if you are on 404, your previous path is a wrongUrl.
      this.setState((state) => {
        const currentPP = state.previousPaths;
        return {
          previousPaths: currentPP.filter((pP) => pP !== currentPP[currentPP.length - 1])
        };
      }, () => {
        const { previousPaths: newPreviousPath } = this.state;
        sessionStorage.setItem('myb-history-stack', newPreviousPath.toString());
        // if user came from login to a wrong path, send them to start, if not, send them back
        newPreviousPath[newPreviousPath.length - 1] === '/' ? navigate(DASHBOARD_URL) : navigate(-1);
      });
    } else if (location.pathname !== '/404') {
      navigate(-1);
    } else {
      navigate(DASHBOARD_URL);
    }
  };

  remove = (filterFn) => {
    this.setState((state) => ({
      previousPaths: state.previousPaths.filter((path) => !filterFn(path))
    }), () => {
      const { previousPaths } = this.state;
      sessionStorage.setItem('myb-history-stack', previousPaths.toString());
    });
  };

  render() {
    const { children } = this.props;
    const { previousPaths } = this.state;
    return (
      <RouteContext.Provider
        value={{
          previousPaths,
          goBack: this.goBack,
          remove: this.remove,
        }}>
        {children}
      </RouteContext.Provider>
    );
  }
}

export default withRouter(RouteContextProvider);
