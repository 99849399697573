import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import FakeDropdown, { FakeDropdownContext } from 'components/FakeDropdown';
import CurrentSchoolContext from 'contexts/CurrentSchoolContext';
import SchoolSelectionRow from './components/SchoolSelectionRow';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { SchoolSelectionProps, SchoolWithStatistics } from './types';
import LoggedUserContext from 'contexts/LoggedUserContext';
import UserHooks from 'modules/hooks/redux/user';
import { isDistrictAccount } from 'modules/helpers/accountTypeHelper';
import { getSchoolOptions } from './helper';
import { SchoolDisplayScope } from '@myblueprint-spaces/redux/lib/scopes';

const SchoolSelection = ({ hideAllOption = false, usePlaceholder = false }: SchoolSelectionProps) => {
  const { t } = useTranslation(['Admin.Common']);
  const adminAssociations = UserHooks.useAdminAssociation()?.associations;
  const isDistrict = !!(adminAssociations && isDistrictAccount(adminAssociations));
  const { school: currentSchool, setSchool } = useContext(CurrentSchoolContext);
  const { availableSchools: allSchools } = useContext(LoggedUserContext);
  const hasAllOption = !hideAllOption && (allSchools.length > 1 || isDistrict);
  const usedCurrentSchool = (currentSchool || (hasAllOption ? { displayName: t('Admin.Common:AllSchools') } : !usePlaceholder && allSchools?.[0]) as SchoolDisplayScope);
  const chosenOption = useMemo(() => ({
    label: usedCurrentSchool?.displayName,
    obj: usedCurrentSchool
  }), [usedCurrentSchool]);
  const { isSmall, isLargeUp } = useContext(WindowSizeContext);

  const getRow = useCallback((iterationSchool, i) => (
    <FakeDropdownContext.Consumer key={i}>
      {({ selected, setSelected }: { selected: SchoolDisplayScope, setSelected: (school: SchoolDisplayScope) => void }) => (
        <SchoolSelectionRow small school={iterationSchool} isSelected={selected?.id === iterationSchool.id} onClick={setSelected} />
      )}
    </FakeDropdownContext.Consumer>
  ), []);

  const texts = useMemo(() => ({
    label: t('Admin.Common:SelectASchool'), dropdownPlaceholder: t('Admin.Common:SelectASchool'),
    dialogTitle: t('Admin.Common:SelectASchool'), emptySearchSubtitle: t('Admin.Common:Search.NoResultsSchools.Subtitle'),
    searchPlaceholder: t('Admin.Common:SearchSchools')
  }), [t]);

  const searchFunction = useCallback((options, str) => options.filter((o) => o.displayName.toLowerCase().includes(str.toLowerCase())), []);

  const selectorFunction = useCallback(() => getSchoolOptions(allSchools, t, hasAllOption), [allSchools, hasAllOption, t]);

  const sortFunction = useCallback((a: SchoolDisplayScope, b: SchoolDisplayScope) => !a.id ? -1 : !b.id ? 1 : a.displayName.localeCompare(b.displayName), []);

  if (allSchools.length === 0) return <LoadingDots />;

  if (allSchools.length === 1 && !hasAllOption) {
    return <div tabIndex={!isLargeUp ? 0 : -1}><Typography type="body2" color="black1" ellipsis>{usedCurrentSchool?.displayName}</Typography></div>;
  } else {
    return (
      <FakeDropdown ellipsis dialogSize="large" hideLabel chosenOption={chosenOption} rowComponent={getRow} dataTest="spaces" size={isSmall ? 'small' : 'medium'} noMargin rounded
        selectorFunction={selectorFunction} refreshOption={t} sortFunction={sortFunction} texts={texts} onSelect={({ studentNumber, staffNumber, ...school }: SchoolWithStatistics) => setSchool(school?.id ? school : undefined)}
        searchFunction={searchFunction} />
    );
  }
};

export default withErrorBoundaries(SchoolSelection as FunctionComponent<SchoolSelectionProps>);
