import React from 'react';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import FlexContainer from '@myblueprint-spaces/papier-web/lib/Atoms/FlexContainer';
import LoadingGif from '@myblueprint-spaces/papier-web/lib/Common/LoadingGif';
import { MainContentContextProvider } from '@myblueprint-spaces/web-common/lib/Common/Contexts/MainContentContext';
import RouteContextProvider from 'contexts/RouteContext';
import { EventtedToastManager } from '@myblueprint-spaces/web-common/lib/services/toasts';
import { EventtedConfirmationManager } from '@myblueprint-spaces/web-common/lib/services/confirms';
import 'modules/services/i18next';
import './index.css';
import './reset.css';
import { ThemeProvider } from 'styled-components';
import { Themes } from '@myblueprint-spaces/papier-core';
import { FileUploadContextProvider } from '@myblueprint-spaces/web-common/lib/Common/Contexts/FileUploadContext';
import { CustomSwitchRoute } from 'components/Shared';
import { Route } from 'react-router-dom';
import Notifications from 'pages/Notifications';
import LoginScreen from 'pages/LoginScreen';
import AuthenticatedUser from '../AuthenticatedUser';
import NotFound from 'pages/NotFound';
import Invite from 'pages/Invite';
import Impersonate from '@myblueprint-spaces/web-common/lib/Common/Impersonate';
import IdpSSOProcessor from 'pages/IdpSSOProcessor';
import LoginError from 'pages/LoginError';
import { ErrorEnum } from 'pages/LoginError/LoginError';
import NoSchoolsAvailable from 'pages/NoSchoolsAvailable';
import AuthenticationContextProvider from '@myblueprint-spaces/web-common/lib/Common/Contexts/AuthenticationContext';

const Dashboard = React.lazy(() => import(/* webpackChunkName: "Dashboard" */'pages/Dashboard'));
const StudentManager = React.lazy(() => import(/* webpackChunkName: "StudentManager" */'pages/StudentManager'));
const StaffManager = React.lazy(() => import(/* webpackChunkName: "StaffManager" */'pages/StaffManager'));
const Settings = React.lazy(() => import(/* webpackChunkName: "Settings" */'views/Settings'));
const Imports = React.lazy(() => import(/* webpackChunkName: "Imports" */'pages/Imports'));
const NotificationDataPanel = React.lazy(() => import(/* webpackChunkName: "NotificationDataPanel" */'pages/NotificationDataPanel'));
const PortfolioTemplates = React.lazy(() => import(/* webpackChunkName: "PortfolioTemplates" */'pages/PortfolioTemplates'));
const ClassroomManager = React.lazy(() => import(/* webpackChunkName: "ClassroomManager" */'pages/ClassroomManager'));

const AppRoot = () => {
  return (
    <React.Suspense fallback={<LoadingGif />}>
      <Typography as={FlexContainer} type="body1" color="black1">
        <MainContentContextProvider>
          <ThemeProvider theme={{ name: Themes.EpLight }}>
            <FileUploadContextProvider>
              <RouteContextProvider>
                <AuthenticationContextProvider>
                  <React.Suspense fallback={<LoadingGif />}>
                    <div style={{ height: '100%', width: '100%' }}>
                      <CustomSwitchRoute>
                        <Route path="/*" element={<LoginScreen />} />
                        <Route path="/invite/:inviteGuid" element={<Invite />} />
                        <Route path="/impersonate" element={<Impersonate />} />
                        <Route element={<AuthenticatedUser />}>
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/students/*" element={<StudentManager />} />
                          <Route path="/staff/*" element={<StaffManager />} />
                          <Route path="/settings/*" element={<Settings />} />
                          <Route path="/notifications/:notificationId" element={<NotificationDataPanel />} />
                          <Route path="/notifications" element={<Notifications />} />
                          <Route path="/imports/*" element={<Imports />} />
                          <Route path="/portfolios/*" element={<PortfolioTemplates />} />
                          <Route path="/classes" element={<ClassroomManager />} />
                        </Route>
                        <Route path="/404" element={<NotFound />} />
                        <Route path="/email-mismatch" element={<LoginError errorEnum={ErrorEnum.EmailMismatch} />} />
                        <Route path="/no-access" element={<LoginError errorEnum={ErrorEnum.NoAccess} />} />
                        <Route path="/sso-error" element={<LoginError errorEnum={ErrorEnum.SSOError} />} />
                        <Route path="/missing-record" element={<LoginError errorEnum={ErrorEnum.MissingRecord} />} />
                        <Route path="/error" element={<LoginError errorEnum={ErrorEnum.NoAssociationsError} />} />
                        <Route path="/sso/*" element={<IdpSSOProcessor />} />
                        <Route path="/no-schools" element={<NoSchoolsAvailable />} />
                      </CustomSwitchRoute>
                    </div>
                    <EventtedToastManager />
                    <EventtedConfirmationManager />
                  </React.Suspense>
                </AuthenticationContextProvider>
              </RouteContextProvider>
            </FileUploadContextProvider>
          </ThemeProvider>
        </MainContentContextProvider>
      </Typography>
    </React.Suspense>
  );
};

export default AppRoot;

