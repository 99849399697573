import React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { DeleteOAuthModel, UserActions } from '@myblueprint-spaces/redux/lib/user';

export default function useDeleteOAuth() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (model: DeleteOAuthModel) => dispatch(UserActions.deleteOAuth(model)),
    [dispatch]);
}
