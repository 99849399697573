import React, { useEffect } from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import LoginHooks from 'modules/hooks/redux/login';
import Login from './Login';
import { LoginProps } from './types';
import { useSchoolOrDistrictNames } from './hooks';
import InviteHooks from 'modules/hooks/redux/invites';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginContainer = (props: Partial<LoginProps>) => {
  const { invite } = props;
  const { state } = useLocation();
  const login = LoginHooks.useLogin();
  const acceptInvite = InviteHooks.useAcceptInvite();
  const schoolOrDistrictNames = useSchoolOrDistrictNames(invite);
  const navigate = useNavigate();

  useEffect(() => {
    if (!state.email) navigate('/');
  }, [state.email, navigate]);

  const allProps = {
    login,
    schoolOrDistrictNames,
    acceptInvite,
    email: state.email as string,
    ...props
  };

  return (
    <Login {...allProps} />
  );
};

export default withGoogleReCaptcha(LoginContainer);
