import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { UpdateUserBasicInfo, UserActions } from '@myblueprint-spaces/redux/lib/user';

export default function useUpdateUserBasicInfo() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (userProfileInfo: UpdateUserBasicInfo) => dispatch(UserActions.updateUserBasicInfo(userProfileInfo)),
    [dispatch]
  );
}
