import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { StudentActions, UpdateStudentInfo } from '@myblueprint-spaces/redux/lib/students';

export default function useEditStudents() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (studentId: string, studentUpdateInfo: UpdateStudentInfo) => dispatch(StudentActions.edit(studentId, studentUpdateInfo)),
    [dispatch]
  );
}
