import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { SignUpActions } from '@myblueprint-spaces/redux/lib/signUp';

export default function useCreateUser() {
  const dispatch = useDispatch<ThunkDispatch<IState, undefined, Action>>();
  return React.useCallback(
    (password?: string, captcha?: string) => dispatch(SignUpActions.createUser(password, captcha)),
    [dispatch]
  );
}
