import { Colors } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

export const SchoolInfoWrapper = styled.div`
  padding: 1rem 0;
  border-bottom: solid 0.0625rem ${Colors.grey2};
`;

export const TextWrapper = styled.div`
  padding-left: 0.25rem;
`;
