import { initializeStore, ReduxGlobals } from '@myblueprint-spaces/redux';

export default function init() {
  ReduxGlobals.setPath(process.env.REACT_APP_API_URI || '/');

  ReduxGlobals.setSignalRFeatures({});

  const reduxOptions = {};

  return initializeStore(undefined, reduxOptions);
}
