import React, { useCallback, useContext, useState } from 'react';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import { AccountType } from '@myblueprint-spaces/appstate';
import { useTranslation } from 'react-i18next';
import { LoginFormWrapper, FormWrapper } from 'views/Login/Shared';
import { IFormValidationProps } from '@myblueprint-spaces/papier-web/lib/Common/FormValidation/types';
import Logo from '@myblueprint-spaces/papier-web/lib/Common/Logo';
import Form from '@myblueprint-spaces/papier-web/lib/Common/Form';
import TextBox from '@myblueprint-spaces/papier-web/lib/Common/TextBox';
import Dropdown from '@myblueprint-spaces/papier-web/lib/Common/Dropdown';
import Checkbox from '@myblueprint-spaces/papier-web/lib/Common/Checkbox';
import HeaderBlock from '@myblueprint-spaces/papier-web/lib/Common/HeaderBlock';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { Row, RowColumn, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import { ButtonProps } from '@myblueprint-spaces/papier-web/lib/Common/Button/Button';
import { StyledAsset, EmptyStateWrapper, TypographyWrapper, SchoolsWrapper, SchoolItem, HeaderContainer } from './styles';
import { getAccountTypeStrCamelCase, getScopeTypeFromAccountType } from 'modules/helpers/accountTypeHelper';
import EmptySchool from 'assets/Asset_empty_school.png';
import { getLanguageFromCulture } from '@myblueprint-spaces/redux/lib/resources';
import { TimeZones } from '@myblueprint-spaces/redux/lib/user';
import { SignUpUserData } from '@myblueprint-spaces/redux/lib/signUp';
import { useCreateSSOUser } from 'components/Login/SamlLogin/hooks';
import { SchoolDisplayScope } from '@myblueprint-spaces/redux/lib/scopes/types';
import { handleValidateHelper, handleChangeHelper, getDefaultState } from './helper';
import SchoolInfo from './components/SchoolInfo';
import { IAdditionalInformationProps, MainInfo } from './types';
import { BackButtonWrapper } from '../Shared/styles';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { useNavigate } from 'react-router-dom';
import { arrayExcept } from '@myblueprint-spaces/modules';
import TermsOfAgreement from '@myblueprint-spaces/web-common/lib/Common/TermsOfAgreement';

const AdditionalInformation = (props: IAdditionalInformationProps) => {
  const {
    schoolAndDistrictList,
    email,
    firstName: userFirstName,
    lastName: userLastName,
    providedSSOAccountType,
    hasInvites,
    schoolFromExternalId,
    externalUserData,
    googleReCaptchaProps,
    signUpSchools,
    accountTypeFromRelatedRecords,
    resetSettings
  } = props;
  const { t, i18n } = useTranslation(['Common', 'LoginSignUp.Common', 'Spaces.Common']);
  const { isSmall } = useContext(WindowSizeContext);
  const createUserSSO = useCreateSSOUser();
  const navigate = useNavigate();
  const schoolDropdownOptions = signUpSchools?.map((signUpSchool) => ({
    value: signUpSchool,
    label: signUpSchool.displayName
  }));

  const defaultUserInfo = getDefaultState(t, userFirstName, userLastName, providedSSOAccountType);
  const [userInfo, setUserInfo] = useState<MainInfo>(defaultUserInfo);
  const [schoolsAndDistrict, setSchoolsAndDistrict] = useState(schoolAndDistrictList);
  const { firstName, lastName, agreement, dropdownAccountType, chosenSchool } = userInfo;
  const accountTypeDropdownOptions = [
    { value: AccountType.Teacher, label: getAccountTypeStrCamelCase(AccountType.Teacher) },
    { value: AccountType.Student, label: getAccountTypeStrCamelCase(AccountType.Student) }
  ];
  const showSchoolDropdown = !hasInvites && !schoolFromExternalId;
  const showAccountTypeDropdown = showSchoolDropdown || (providedSSOAccountType === undefined && accountTypeFromRelatedRecords === undefined);
  const schoolDropdownValid = chosenSchool.valid || schoolsAndDistrict.length > 0;
  const schoolDropdownValidation = schoolDropdownValid ? null : { state: 'error', message: chosenSchool.validationText } as IFormValidationProps;
  const buttonProps = {
    text: t('LoginSignUp.Common:SignUp.SignUp'),
    type: 'submit',
    size: 'medium',
    expanded: true,
    dataTest: 'sign-up',
    style: { marginTop: '1rem' }
  } as ButtonProps;

  const handleValidate = handleValidateHelper({ firstName, lastName, agreement, dropdownAccountType, schoolsAndDistrict, showAccountTypeDropdown, showSchoolDropdown, userInfo, setUserInfo, t });

  const handleChange = handleChangeHelper(userInfo, setUserInfo);

  const handleSchoolChange = (e: SchoolDisplayScope) => {
    if (!schoolsAndDistrict.some((school) => school.id === e.ownerId && school.displayName === e.displayName)) setSchoolsAndDistrict([...schoolsAndDistrict, { displayName: e.displayName, id: e.ownerId }]);
  };

  const handleSubmit = () => {
    const userSelectedSchoolIds = arrayExcept(schoolsAndDistrict, schoolAndDistrictList).map(({ id }) => id);
    const userSignUpData: SignUpUserData = {
      scopeType: getScopeTypeFromAccountType(dropdownAccountType.value as AccountType),
      firstName: firstName.value as string,
      lastName: lastName.value as string,
      email: externalUserData?.email as string,
      culture: getLanguageFromCulture(i18n.language),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZones,
      schoolIds: userSelectedSchoolIds
    };

    return googleReCaptchaProps.executeRecaptcha('sso_signup')
      .then((token) => createUserSSO(userSignUpData, token));
  };

  const handleGoBack = useCallback(() => {
    resetSettings();
    navigate('/');
  }, [resetSettings, navigate]);

  return (
    <LoginFormWrapper>
      <HeaderContainer>
        <BackButtonWrapper>
          <Button
            rounded
            onClick={handleGoBack}
            icon="left-arrow"
            size={isSmall ? 'small' : 'medium'}
            color="secondary"
            dataTest="back"
            title={t('Common:Actions.Back')}
          />
        </BackButtonWrapper>
      </HeaderContainer>
      <FormWrapper>
        <Form
          onSubmit={handleSubmit}
          onValidate={handleValidate}
          button={buttonProps}
          showResult>
          <RowColumn align="center">
            <Logo size="xlarge" logoOnly />
          </RowColumn>
          <HeaderBlock
            title={{ as: 'h1', children: t('LoginSignUp.Common:LoginOAuth.Title') }}
            subtitle={{ type: 'compact', color: 'black3', children: t('LoginSignUp.Common:LoginOAuth.Subtitle') }}
            style={{ paddingBottom: '2rem' }}
          />
          {showAccountTypeDropdown && <Dropdown
            label={t('LoginSignUp.Common:MoreDetails.AccountType.Label') as string}
            placeholder={providedSSOAccountType !== undefined ? undefined : t('LoginSignUp.Common:MoreDetails.AccountType.Placeholder') as string}
            options={accountTypeDropdownOptions}
            value={dropdownAccountType.value}
            onChange={handleChange('dropdownAccountType')}
            validation={dropdownAccountType.valid ? null : { state: 'error', message: dropdownAccountType.validationText as string }}
            disabled={showSchoolDropdown && [AccountType.Teacher, AccountType.Student].includes(providedSSOAccountType as AccountType)}
            dataTest="select-account-type"
          />}
          <TextBox type="text" label={t('Common:UserInfo.Email')} size="medium"
            value={email} dataTest="email" disabled />
          <Row collapse={false}>
            <Column columns={{ small: 6 }}>
              <TextBox validation={firstName.valid ? null : { state: 'error', message: firstName.validationText as string }} label={t('Common:UserInfo.FirstName')} size="medium"
                value={firstName.value as string} onChange={handleChange('firstName')} dataTest="first-name" disabled={!!userFirstName} />
            </Column>
            <Column columns={{ small: 6 }}>
              <TextBox validation={lastName.valid ? null : { state: 'error', message: lastName.validationText as string }} label={t('Common:UserInfo.LastName')} size="medium"
                value={lastName.value as string} onChange={handleChange('lastName')} dataTest="last-name" disabled={!!userLastName} />
            </Column>
          </Row>
          <SchoolsWrapper>
            {showSchoolDropdown ? <Dropdown
              searchable
              label={t('Common:School') as string}
              placeholder={t('LoginSignUp.Common:MoreDetails.SelectSchools.Placeholder') as string}
              options={schoolDropdownOptions!}
              value={chosenSchool.value}
              onChange={handleSchoolChange}
              validation={schoolDropdownValidation}
              disabled={schoolAndDistrictList.length === 5}
              dataTest="select-school"
            /> : <Typography type="body2" color="black2">
              {t('Common:School')}
            </Typography>}
            <SchoolItem>
              {schoolAndDistrictList.length > 0 ? schoolAndDistrictList.map((school, key) => (
                <SchoolInfo
                  school={school}
                  key={key}
                  dropdownAccountType={dropdownAccountType.value}
                  showSchoolDropdown={showSchoolDropdown}
                  schools={schoolAndDistrictList}
                  setSchools={setSchoolsAndDistrict}
                  accountTypeFromRelatedRecords={accountTypeFromRelatedRecords} />
              ))
                : <EmptyStateWrapper>
                  <StyledAsset src={EmptySchool} aria-hidden="true" />
                  <TypographyWrapper>
                    <Typography type="header4">{t('LoginSignUp.Common:MoreDetails.NoSchool.Title')}</Typography>
                  </TypographyWrapper>
                  <Typography type="body2" color="black3">{t('LoginSignUp.Common:MoreDetails.NoSchool.Subtitle')}</Typography>
                </EmptyStateWrapper>
              }
            </SchoolItem>
          </SchoolsWrapper>
          <Checkbox checked={agreement.value as boolean} label={<TermsOfAgreement />} size="small" validation={agreement.valid ? null : { state: 'error', message: t(agreement.validationText as string) }}
            onChange={handleChange('agreement')} dataTest="agreement" alignItems="start" />
        </Form>
      </FormWrapper>
    </LoginFormWrapper>
  );
};

export default withErrorBoundaries(AdditionalInformation);
