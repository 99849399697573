import { AccountType } from '@myblueprint-spaces/appstate';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import CovalentGrid from '@myblueprint-spaces/papier-web/lib/Atoms/CovalentGrid';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import RowColumn from '@myblueprint-spaces/papier-web/lib/Common/Grid/RowColumn';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { Spec } from 'immutability-helper';
import { getAccountTypeStrCamelCase } from 'modules/helpers/accountTypeHelper';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SchoolAndDistrictDetails } from '../../types';
import { SchoolInfoWrapper, TextWrapper } from './styles';

interface ISchoolInfoProps {
  school: SchoolAndDistrictDetails;
  key: number;
  dropdownAccountType?: AccountType | Spec<AccountType | undefined, never>;
  showSchoolDropdown: boolean;
  schools: SchoolAndDistrictDetails[];
  setSchools: (value: React.SetStateAction<SchoolAndDistrictDetails[] | undefined>) => void;
  accountTypeFromRelatedRecords?: AccountType;
}

const SchoolInfo = ({ school, key, dropdownAccountType, showSchoolDropdown, schools, setSchools, accountTypeFromRelatedRecords }: ISchoolInfoProps) => {
  const { displayName, id, accountType } = school;
  const { t } = useTranslation(['Common']);
  const defaultAccountType = accountTypeFromRelatedRecords !== undefined ? getAccountTypeStrCamelCase(accountTypeFromRelatedRecords) : '-';
  const dropdownAccountTypeStr = dropdownAccountType !== undefined ? getAccountTypeStrCamelCase(dropdownAccountType) : defaultAccountType;
  const accountTypeStr = accountType !== undefined ? getAccountTypeStrCamelCase(accountType) : dropdownAccountTypeStr;
  const { isSmall } = useContext(WindowSizeContext);

  return (
    <SchoolInfoWrapper key={key}>
      <CovalentGrid align="justify" verticalAlign="middle">
        <CovalentGrid collapse>
          <Icon type="book" color="black2" />
          <TextWrapper>
            <RowColumn>
              <Typography type={isSmall ? 'compact' : 'body2'}>{displayName}</Typography>
            </RowColumn>
            <RowColumn>
              <Typography type="subtitle1" color="black3">{accountTypeStr}</Typography>
            </RowColumn>
          </TextWrapper>
        </CovalentGrid>
        {showSchoolDropdown && <Button
          rounded
          icon="trash"
          size="small"
          color="secondary"
          dataTest="delete"
          title={t('Common:Actions.Delete')}
          onClick={() => setSchools(schools?.filter((newSchool) => newSchool.id !== id))}
        />
        }
      </CovalentGrid>
    </SchoolInfoWrapper>
  );
};

export default withErrorBoundaries(SchoolInfo);
