import useConfig from './useConfig';
import useLoadConfigs from  './useLoadConfigs';
import useIntegrations from  './useIntegrations';

const GlobalHooks = {
  useConfig,
  useLoadConfigs,
  useIntegrations
};

export default GlobalHooks;
