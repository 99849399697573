import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import CurrentSchoolContext from './CurrentSchoolContext';
import UserHooks from 'modules/hooks/redux/user';
import { isDistrictAccount, isSuperAdminAccount } from 'modules/helpers/accountTypeHelper';
import { AdminRoleAssociation } from '@myblueprint-spaces/redux/lib/admins/types';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';

export interface ICurrentSchoolAccountInfoContext {
  isDistrict: boolean;
  isSuperAdmin: boolean;
  adminAssociations: AdminRoleAssociation[] | undefined;
}

const CurrentSchoolAccountInfoContext = createContext<ICurrentSchoolAccountInfoContext>({} as ICurrentSchoolAccountInfoContext);

const CurrentSchoolAccountInfoContextProvider = ({ children }) => {
  const { school } = useContext(CurrentSchoolContext);
  const adminAssociations = UserHooks.useAdminAssociation()?.associations;
  const isSuperAdmin = !!(adminAssociations && isSuperAdminAccount(adminAssociations, school));
  const isDistrict = !!(adminAssociations && isDistrictAccount(adminAssociations));

  if (adminAssociations === undefined) return <LoadingDots />;

  return (
    <CurrentSchoolAccountInfoContext.Provider value={{ isDistrict, isSuperAdmin, adminAssociations }}>
      {children}
    </CurrentSchoolAccountInfoContext.Provider>
  );
};

CurrentSchoolAccountInfoContextProvider.propTypes = {
  children: PropTypes.object
};

export { CurrentSchoolAccountInfoContextProvider };
export default CurrentSchoolAccountInfoContext;
