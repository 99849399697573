import React, { useEffect } from 'react';
import AuthenticationHooks from 'modules/hooks/redux/authentication';
import { useNavigate, useParams } from 'react-router-dom';
import IdpProcessor from './IdpProcessor';
import { failToast } from '@myblueprint-spaces/web-common/lib/services/toasts/toastEventEmitter';
import { UserErrors } from '@myblueprint-spaces/redux/lib/user';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const IdpProcessorContainer = () => {
  const { idp } = useParams<{ idp: string }>();
  const { t } = useTranslation(['Common']);
  const loadIdpSettings = AuthenticationHooks.useLoadIdpSettings();
  const settings = AuthenticationHooks.useSettings();
  const resetSettings = AuthenticationHooks.useResetSettings();
  const navigate = useNavigate();
  const getFullUrl = useGetFullUrl();
  const errorUrl = getFullUrl('/sso-error');

  useEffect(() => {
    if (typeof settings === 'undefined' && idp) {
      loadIdpSettings(idp)
        .catch((err) => {
          if (axios.isAxiosError(err)) {
            const { response } = err;
            const { status, data } = response || {};
            if (status === 400 && data?.code === UserErrors.SsoMisconfigured) navigate(errorUrl);
          }
          failToast(err, t);
          throw err;
        });
    }
  }, [settings, idp, loadIdpSettings]);

  if (settings === null) {
    navigate('/');
    return null;
  }

  const allProps = {
    settings,
    resetSettings
  };

  return <IdpProcessor {...allProps} />;
};

export default IdpProcessorContainer;
