import React from 'react';
import PropTypes from 'prop-types';
import { getPasswordStrengthProgress } from './helpers';
import PasswordCheck from './components/PasswordCheck';
import { ColorNames } from '@myblueprint-spaces/papier-core';
import { checkPasswordStrength } from '@myblueprint-spaces/appstate/lib/helpers/passwordStrength';
import { CheckList, PasswordStrengthIndicatorWrapper } from './styles';
import ProgressBar from '@myblueprint-spaces/papier-web/lib/Common/ProgressBar';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';

export const PasswordStrengthIndicator = ({ password }) => {
  const passwordStrength = checkPasswordStrength(password);
  const progress = getPasswordStrengthProgress(passwordStrength);

  const checks: React.ReactNode[] = [];
  for (const [key, value] of Object.entries(passwordStrength)) {
    checks.push(<PasswordCheck passwordCheck={key} checked={value} />);
  }
  const checkRows: React.ReactNode[] = [];
  for (let i = 0; i < checks.length / 2; i++) {
    const row = (
      <Row collapse>
        <Column columns={{ small: 12, medium: 6 }}>{checks[i * 2]}</Column>
        { !!checks[i * 2 + 1] && <Column columns={{ small: 12, medium: 6 }}>{checks[i * 2 + 1]}</Column> }
      </Row>
    );
    checkRows.push(row);
  }

  if (!password || !password.length) return null;

  return (
    <PasswordStrengthIndicatorWrapper>
      <ProgressBar value={progress.progress} color={progress.color as ColorNames} max={1} />
      <CheckList>
        {checkRows}
      </CheckList>
    </PasswordStrengthIndicatorWrapper>
  );
};

PasswordStrengthIndicator.propTypes = {
  password: PropTypes.string
};

export default PasswordStrengthIndicator;
