import { ISortExpression } from '@myblueprint-spaces/papier-web/lib/Common/DataGrid/types';
import { isDistrictAccount, isDistrictInvite, isTeacherAccount, isTeacherInvite } from 'modules/helpers/accountTypeHelper';

export const getSchoolString = (data, t, schools, isInvite = false) => {
  const { admin } = data;
  const isTeacher = isInvite ? isTeacherInvite(data) : isTeacherAccount(data);
  const isDistrict = isInvite ? isDistrictInvite(data) : (!isTeacher && isDistrictAccount(admin.associations));

  let text = '-';

  if (!isDistrict && schools.length === 1) {
    text = schools[0] ? schools[0].displayName : '-';
  } else if (!isDistrict) {
    text = t('Common:XSchool', { count: schools.length });
  }

  return text;
};

export const getClassesString = (data, schoolId) => {
  const isTeacher = isTeacherAccount(data, schoolId);

  if (isTeacher) {
    if (schoolId) {
      return data?.teachers?.find((record) => record.schoolId === schoolId)?.classrooms.length || '0';
    }
    return data?.teachers?.reduce((acc, record) => acc + record?.classrooms.length, 0);
  } else {
    return '-';
  }
};

/* All available account names */
export const getAvailableAccounts = (t) => ({
  DISTRICT: t('Common:DistrictAdmin'),
  SCHOOL: t('Common:SchoolAdmin'),
  TEACHER: t('Common:Teacher')
});

/* Account name according to staff object */
export const getStaffAccountString = (staff, t, schoolId) => {
  const Account = getAvailableAccounts(t);
  const isTeacher = isTeacherAccount(staff, schoolId);

  const isDistrict = staff?.admin?.associations && isDistrictAccount(staff.admin.associations);

  if (isDistrict) return Account.DISTRICT;

  return isTeacher ? Account.TEACHER : Account.SCHOOL;
};

export const getInviteAccountString = (invite, t) => {
  const Account = getAvailableAccounts(t);

  return isTeacherInvite(invite) ? Account.TEACHER : isDistrictInvite(invite) ? Account.DISTRICT : Account.SCHOOL;
};

export const getNewDataSource = (currentDS, data, schools, t, isInvite = false) => {
  const map = new Map();
  currentDS.forEach((item) => map.set(item.id, item));
  // set both the visible text (for sorting) + data in order to be able to proccess other things
  data.forEach((item) => map.set(item.id, {
    ...map.get(item.id),
    schoolColumnValue: getSchoolString(item, t, schools[item.id], isInvite),
    schools: Array.isArray(schools[item.id]) ? schools[item.id] : [schools[item.id]],
    extraLoaded: true
  }));

  return Array.from(map.values());
};

export const getCommonDataGridConfig = (isSmall) => ({
  showFooter: true,
  paginate: true,
  contentOffset: { small: '1rem', medium: '1.25rem', large: '3.25rem' },
  dataKey: 'id',
  mainRowExpanded: true,
  mainRowCollapse: true,
  sortExpression: { field: 'lastName', direction: 'asc' } as ISortExpression,
  stickyHeader: isSmall ? '3.4375rem' : '3.9375rem',
  hideHeader: isSmall
});
