import { Breakpoints } from '@myblueprint-spaces/papier-core';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LogoutWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const EmptyStatewrapper = styled.div`
  margin-top: 8.5rem;

  @media (min-width: ${Breakpoints.medium}em) {
    margin-top: 10.5rem;
  }
`;
