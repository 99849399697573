import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { InviteSelectors } from '@myblueprint-spaces/redux/lib/invites';

export default function useSentStudentInvites(classroomId?: string) {
  return useSelector((state: IState) => {
    if (!classroomId) return undefined;
    return InviteSelectors.getSentStudentInvites(state, classroomId);
  });
}
