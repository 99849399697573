import { useSelector } from 'react-redux';
import { IState } from '@myblueprint-spaces/redux';
import { StaffSelectors } from '@myblueprint-spaces/redux/lib/staff';
import { useTranslation } from 'react-i18next';
import { getStaffAccountString } from 'pages/StaffManager/components/Shared/helpers';

export default function useStaff(schoolId?: string, approved = true, inactive = false) {
  const { t } = useTranslation(['Common', 'Admin.StaffManager']);

  return useSelector((state: IState) => {
    if (!approved) {
      return StaffSelectors.getTeacherStaff(state, schoolId, false)?.map((s) => ({
        ...s,
        account: getStaffAccountString(s, t, schoolId)
      }));
    } else {
      const staffUsers = inactive ? StaffSelectors.getInactiveStaff(state, schoolId) : StaffSelectors.getStaff(state, schoolId);
      return staffUsers?.map((s) => ({
        ...s,
        account: getStaffAccountString(s, t, schoolId)
      }));
    }
  });
}
