import AppConfigContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/AppConfigContext';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import React, { useContext } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Route } from 'react-router-dom';
import SsoProcessor from 'pages/IdpSSOProcessor/components/SsoProcessor';
import IdpProcessor from 'pages/IdpSSOProcessor/components/IdpProcessor';
import { CustomSwitchRoute } from 'components/Shared';

const IdpSSOProcessor = () => {
  const { integrations: { captchaClientId } } = useContext(AppConfigContext)!;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={captchaClientId}
      useRecaptchaNet>
      <CustomSwitchRoute>
        <Route index element={<SsoProcessor />} />
        <Route path=":idp" element={<IdpProcessor />} />
      </CustomSwitchRoute>
    </GoogleReCaptchaProvider>
  );
};

export default withErrorBoundaries(IdpSSOProcessor);
