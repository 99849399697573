import { Breakpoints, ButtonSizeType, Colors, primaryFocus } from '@myblueprint-spaces/papier-core';
import CleanButton from '@myblueprint-spaces/papier-web/lib/Atoms/CleanButton';
import { RowColumn, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import styled from 'styled-components';

export const StyledFakeDropdown = styled(CleanButton)(({ noMargin, size, rounded }: { rounded?: boolean; noMargin?: boolean; size: ButtonSizeType }) => `
  border-color: ${Colors.grey1};
  padding: ${size === 'small' ? '0.375rem 0.5rem 0.375rem 0.625rem' : '0.1875rem 0.5rem 0.1875rem 0.625rem'};
  border-radius: ${rounded ? '2rem' : '0.25rem'};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  transition: ${['box-shadow', 'background-color', 'border-color'].map((s) => `${s} 0.2s ease-in-out`).join(',')};
  border-width: 0.0625rem;
  border-style: solid;
  cursor: pointer;
  background-color: ${Colors.white};
  box-shadow: none;
  ${noMargin ? '' : 'margin-bottom: 1.5rem;'}
  width: 100%;
  height: ${size === 'small' ? '2rem' : '2.5rem'};

  &:hover: {
    border-color: ${Colors.black3};
    &:focus {
      border-color: ${Colors.primary1};
    }
  }
  &:disabled {
    border-color: ${Colors.grey2};
    background-color: ${Colors.grey4};
    cursor: default;
  }
  &:focus {
    border-color: transparent;
    box-shadow: ${primaryFocus};
  }
  &:invalid {
    border-color: ${Colors.danger1};
    margin-bottom: 0.25rem;
  }
`);

export const List = styled.ul`
  list-style: none;
  padding-inline-start: 0;
  -webkit-padding-start: 0;
  margin-block-start: 0;
  -webkit-margin-start: 0;
  -webkit-margin-before: 0;
  margin-block-end: 0;
  -webkit-padding-before: 0;
  -webkit-margin-end: 0;
`;

export const ListWrapper = styled.div`
  border: 0.0625rem solid ${Colors.grey2};
  border-radius: 0.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1rem;
  height: 28.375rem;

  @media (max-width: ${Breakpoints.medium}em) {
    height: 100%;
    border: 0;
    border-top: 0.0625rem solid ${Colors.grey2};
    border-radius: 0;
  }
`;

export const DialogWrapper = styled.div`
  @media (min-width: ${Breakpoints.medium}em) {
    margin: 0 2.5rem 2.5rem 2.5rem;
  }
`;

export const TagList = styled.div`
  margin-top: 0.5rem;
`;

export const TagWrapper = styled.div`
  display: inline-block;
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
  }
`;

export const TextBoxWrapper = styled(RowColumn)`
  @media (max-width: ${Breakpoints.medium}em) {
    padding: 1rem;
    padding-bottom: 0;
    border-top: 0.0625rem solid ${Colors.grey2};
  }
`;

export const CustomColumn = styled(Column)`
  ${({ size }: { size?: ButtonSizeType }) => size === 'small' ? 'height: 100%;' : ''};
  text-align: left;
`;

