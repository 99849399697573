import React, { useCallback, useEffect, useContext } from 'react';
import ExternalLoginBase, { useHandleAuthorizationErrors } from '@myblueprint-spaces/web-common/lib/Common/ExternalLoginBase';
import { LoginProviders } from '@myblueprint-spaces/redux/lib/user';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import LoginButton from 'pages/LoginScreen/components/LoginButton';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import AuthenticationHooks from 'modules/hooks/redux/authentication';
import useFunctionState from '@myblueprint-spaces/papier-web/lib/modules/hooks/useFunctionState';
import { useSSOLogin } from './hooks';
import useGetFullUrl from '@myblueprint-spaces/web-common/lib/modules/hooks/useGetFullUrl';
import { useNavigate } from 'react-router-dom';
import { LoginEmailFormContext } from 'pages/LoginScreen/components/LoginEmail';
import { ButtonStatus } from '@myblueprint-spaces/papier-core';
import useId from '@myblueprint-spaces/papier-web/lib/modules/hooks/useId';

const SamlLogin = () => {
  const { setBtnStatus } = useContext(LoginEmailFormContext);
  const resetSettings = AuthenticationHooks.useResetSettings();
  const [onClose, setOnClose] = useFunctionState(() => undefined);
  const getFullUrl = useGetFullUrl();
  const moreInfoUrl = getFullUrl('/more-info');
  const navigate = useNavigate();
  const id = useId();

  useEffect(() => {
    resetSettings();
    return (() => resetSettings()) as () => void;
  }, []);

  const handleAuthorizeFail = useHandleAuthorizationErrors(true);
  const handleAuthenticated = useSSOLogin();
  const handleSSOSignUp = useCallback(() => navigate(moreInfoUrl), [navigate, moreInfoUrl]);
  const onPopUpClose = useCallback(() => {
    setBtnStatus(ButtonStatus.initial);
    resetSettings();
  }, [resetSettings, setBtnStatus]);

  return (
    <ExternalLoginBase
      provider={LoginProviders.saml}
      id={id}
      onError={handleAuthorizeFail}
      onAuthenticated={handleAuthenticated}
      onClose={onClose}
      onSignUp={handleSSOSignUp}
      onPopUpClose={onPopUpClose}>
      {
        ({ initiateLogin }) => <LoginButton setOnClose={setOnClose} initiateLogin={initiateLogin} />
      }
    </ExternalLoginBase>
  );
};

export default withGoogleReCaptcha(withErrorBoundaries(SamlLogin));
