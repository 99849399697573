import React, { useEffect, useContext, useState, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import withErrorBoundaries from '@myblueprint-spaces/papier-web/lib/modules/hocs/withErrorBoundaries';
import Tag from '@myblueprint-spaces/papier-web/lib/Common/Tag';
import Dialog from '@myblueprint-spaces/papier-web/lib/Common/Dialog';
import useId from '@myblueprint-spaces/papier-web/lib/modules/hooks/useId';
import Icon from '@myblueprint-spaces/papier-web/lib/Common/Icon';
import TextBox from '@myblueprint-spaces/papier-web/lib/Common/TextBox';
import Button from '@myblueprint-spaces/papier-web/lib/Common/Button';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import LoadingDots from '@myblueprint-spaces/papier-web/lib/Common/LoadingDots';
import Label, { LabelSize } from '@myblueprint-spaces/papier-web/lib/Common/Label';
import { Row, RowColumn, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { AcceptedTypes, FakeDropdownProps } from './types';
import { StyledFakeDropdown, List, DialogWrapper, TagList, TagWrapper, ListWrapper, TextBoxWrapper, CustomColumn } from './styles';
import { RovingTabIndexProvider } from '@myblueprint-spaces/react-roving-tabindex';
import { FakeDropdownContext } from '.';
import { useTranslation } from 'react-i18next';
import WindowSizeContext from '@myblueprint-spaces/web-common/lib/Common/Contexts/WindowSizeContext';
import { Clipped } from 'components/Shared/styles';
import SearchEmptyState from 'components/Shared/SearchEmptyState';

const FakeDropdown = (props: FakeDropdownProps) => {
  const { options = [], chosenOption, rowComponent, getTagProps, texts, onSelect, listComponent = null, searchFunction, disabled = false, tags = false, dataTest, onNewDataAvailable,
    size = 'medium', hideLabel = false, noMargin = false, rounded = false, dialogSize, ellipsis = false } = props;
  const { t } = useTranslation(['Common']);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [currentSelectedOption, setCurrentSelectedOption] = useState<AcceptedTypes | AcceptedTypes[] | undefined>(chosenOption?.obj);
  const [filteredBy, setFilteredBy] = useState('');
  const dialogId = useId();
  const listId = useId();
  const dropdownId = useId();
  const { isSmall } = useContext(WindowSizeContext);
  const { dropdownPlaceholder, searchPlaceholder, dialogTitle, label, emptySearchSubtitle } = texts;
  const [noOption, setNoOption] = useState(!currentSelectedOption || (Array.isArray(currentSelectedOption) && !currentSelectedOption?.length));

  const handleConfirm = () => {
    currentSelectedOption && onSelect(currentSelectedOption);
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setFilteredBy('');
    setFilteredOptions(options);
    setDialogOpen(false);
  };

  const handleDropdownClick = () => {
    setDialogOpen(!dialogOpen);
    setCurrentSelectedOption(chosenOption?.obj);
  };

  useEffect(() => {
    !dialogOpen && setFilteredOptions(options);
    !dialogOpen && onNewDataAvailable && onNewDataAvailable(options);
  }, [options, dialogOpen]);

  useEffect(() => {
    setNoOption(!currentSelectedOption || (Array.isArray(currentSelectedOption) && !currentSelectedOption?.length));
  }, [currentSelectedOption]);

  useEffect(() => {
    setCurrentSelectedOption(chosenOption?.obj);
  }, [chosenOption]);

  useEffect(() => {
    setFilteredOptions(filteredBy && filteredBy.length ? searchFunction(options, filteredBy) : options);
  }, [filteredBy]);

  const okBtn = (
    <Button
      size={isSmall ? 'small' : 'medium'}
      id={`${dialogId}--okButton`}
      color="primary"
      icon="checkmark"
      rounded
      onClick={handleConfirm}
      disabled={noOption}
      title={t('Common:Ok')}
      dataTest="ok"
    />
  );

  const [list, setList] = useState<React.ReactNode | null>(null);
  useEffect(() => {
    if (!dialogOpen) {
      setList(listComponent?.(listId));
    }
  }, [listComponent, listId, options, dialogOpen]);

  return (
    <React.Suspense fallback={<LoadingDots />}>
      <RowColumn expanded>
        {hideLabel
          ? <Clipped>
            <Label htmlFor={dropdownId} size={size as LabelSize}>
              {label}
            </Label>
          </Clipped>
          : <Label htmlFor={dropdownId} size={size as LabelSize}>
            {label}
          </Label>}
      </RowColumn>
      <RowColumn expanded>
        <StyledFakeDropdown data-test={`mpb-fake-drpwn-${dataTest}`} id={dropdownId} onClick={handleDropdownClick} size={size} noMargin={noMargin}
          aria-label={`${dialogTitle} ${chosenOption?.label || ''}`} aria-expanded={dialogOpen} disabled={options.length === 0 || disabled} rounded={rounded}>
          <Row expanded align="justify" verticalAlign="middle" collapse span>
            <CustomColumn size={size}>
              <Typography color={currentSelectedOption && !disabled ? 'black1' : 'black3'} style={{ display: 'block' }} type={size === 'small' ? 'compact' : 'body2'} as="span" ellipsis={ellipsis}>
                {chosenOption?.label || dropdownPlaceholder}
              </Typography>
            </CustomColumn>
            <CustomColumn shrink size={size}>
              {chosenOption?.pill}
            </CustomColumn>
            <Column shrink>
              <Icon size="small" type={dialogOpen ? 'up-arrow' : 'down-arrow'} color={disabled ? 'grey1' : 'black3'} />
            </Column>
          </Row>
        </StyledFakeDropdown>
      </RowColumn>

      <Dialog
        size={dialogSize || (isSmall ? 'small' : 'medium')}
        visible={dialogOpen}
        rightComponent={okBtn}
        onClose={handleCloseDialog}
        // can't be styled comp. because it needs to have the as="h1"
        centerComponent={isSmall && <Typography as="h1" type="subtitle1" color="black1" style={{ display: 'block', textAlign: 'center' }} weight="medium">{dialogTitle}</Typography>}
        title={dialogTitle}
        fullScreenOnMobile
        initialFocus={() => document.querySelector(`#${listId} li`)}
      >
        <DialogWrapper>
          {!isSmall && <RowColumn expanded>
            {/* can't be styled comp. because it needs to have the as="h1" */}
            <Typography as="h1" type="header4" color="black1" style={{ display: 'block', textAlign: 'center', margin: '0 0 2rem 0' }} id={`${dialogId}--title`} weight="demibold">{dialogTitle}</Typography>
          </RowColumn>}
          <TextBoxWrapper expanded>
            <TextBox clearable size={isSmall ? 'small' : 'medium'} value={filteredBy} bottomMargin={false} placeholder={searchPlaceholder} icon="search" onChange={setFilteredBy} dataTest="search" />
          </TextBoxWrapper>
          <RowColumn expanded collapse={!isSmall}>
            <TagList>
              {tags && (currentSelectedOption as AcceptedTypes[])?.map((s, i) => {
                const { text, findItem } = getTagProps?.(s) || {};
                const tagProps = {
                  color: 'secondary',
                  text: text as string,
                  onRemove: () => setCurrentSelectedOption((cSO) => findItem?.(cSO))
                };
                return (
                  <TagWrapper key={`selected-${i}`}>
                    <Tag {...tagProps} color="secondary" />
                  </TagWrapper>
                );
              })}
            </TagList>
          </RowColumn>
          <RowColumn expanded>
            <ListWrapper>
              <FakeDropdownContext.Provider value={{ options: filteredOptions, selected: currentSelectedOption, setSelected: setCurrentSelectedOption, isFiltered: options?.length !== filteredOptions?.length }}>
                <React.Suspense fallback={<LoadingDots />}>
                  {filteredOptions?.length === 0
                    ? <SearchEmptyState clearSearch={() => setFilteredBy('')} emptySearchSubtitle={emptySearchSubtitle} />
                    : <React.Fragment>
                      {list}
                      {rowComponent && <List role="listbox" id={listId} aria-labelledby={`${dialogId}--title`}>
                        <RovingTabIndexProvider>
                          {filteredOptions.map((o, i) => (
                            rowComponent(o, i)
                          ))}
                        </RovingTabIndexProvider>
                      </List>}
                    </React.Fragment>
                  }
                </React.Suspense>
              </FakeDropdownContext.Provider>
            </ListWrapper>
          </RowColumn>
        </DialogWrapper>
      </Dialog>
    </React.Suspense>
  );
};

FakeDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  chosenOption: PropTypes.object.isRequired,
  rowComponent: PropTypes.func,
  texts: PropTypes.shape({
    dropdownPlaceholder: PropTypes.string.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    emptySearchSubtitle: PropTypes.string.isRequired,
  }),
  getTagProps: PropTypes.func,
  onSelect: PropTypes.func,
  listComponent: PropTypes.func,
  searchFunction: PropTypes.func,
  disabled: PropTypes.bool,
  tags: PropTypes.bool,
  hideLabel: PropTypes.bool,
  noMargin: PropTypes.bool,
  dataTest: PropTypes.string,
  dialogSize: PropTypes.string
};

export default withErrorBoundaries(FakeDropdown as FunctionComponent<FakeDropdownProps>);
