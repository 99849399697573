import React from 'react';
import PropTypes from 'prop-types';
import { getMessageForCheck } from '@myblueprint-spaces/appstate/lib/helpers/passwordStrength';
import { ColorNames, FontTypeNames, Colors } from '@myblueprint-spaces/papier-core';
import { IconNameType } from '@myblueprint-spaces/papier-icons';
import { StyledIcon, PasswordCheckContainer } from '../styles';
import Typography from '@myblueprint-spaces/papier-web/lib/Common/Typography';
import { Row, Column } from '@myblueprint-spaces/papier-web/lib/Common/Grid';
import { useTranslation } from 'react-i18next';

export const PasswordCheck = ({ checked, passwordCheck }) => {
  const { t } = useTranslation(['PasswordStrength.Common']);

  let iconProps = {
    type: 'remove' as IconNameType,
    color: 'grey1' as ColorNames
  };
  let typographyProps = {
    type: 'subtitle1' as FontTypeNames,
    color: 'black1' as ColorNames
  };
  let customStyles = {};

  if (checked) {
    iconProps = {
      type: 'check' as IconNameType,
      color: 'success1' as ColorNames
    };
    typographyProps = {
      ...typographyProps,
      color: 'black3' as ColorNames
    };
    customStyles = {
      textDecorationLine: 'line-through',
      textDecorationColor: Colors.black3
    };
  }

  return (
    <PasswordCheckContainer>
      <Row collapse verticalAlign="middle">
        <Column shrink>
          <StyledIcon size="small" {...iconProps} />
        </Column>
        <Column>
          <div style={{ ...customStyles }}>
            <Typography as="p" {...typographyProps}>
              {t(getMessageForCheck(passwordCheck))}
            </Typography>
          </div>
        </Column>
      </Row>
    </PasswordCheckContainer>
  );
};

PasswordCheck.propTypes = {
  checked: PropTypes.bool.isRequired,
  passwordCheck: PropTypes.string.isRequired
};

export default PasswordCheck;
