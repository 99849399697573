import * as React from 'react';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { IState } from '@myblueprint-spaces/redux';
import { AuthenticationActions } from '@myblueprint-spaces/redux/lib/authentication';

export default function useLogin() {
  const dispatch = useDispatch<ThunkDispatch<IState, unknown, Action>>();
  return React.useCallback(
    (username:string, password:string, token: string) =>
      dispatch(AuthenticationActions.login(username, password, token )),
    [dispatch]
  );
}
