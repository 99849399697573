import useUserData from './useUserData';
import useExternalUserData from './useExternalUserData';
import useInviteId from './useInviteId';
import useSaveUserData from './useSaveUserData';
import useSaveExternalUserData from './useSaveExternalUserData';
import useCreateUser from './useCreateUser';
import useAcceptInvite from './useAcceptInvite';
import useRelatedRecords from './useRelatedRecords';

const SignUpHooks = {
  useUserData,
  useExternalUserData,
  useInviteId,
  useSaveUserData,
  useSaveExternalUserData,
  useCreateUser,
  useAcceptInvite,
  useRelatedRecords
};

export default SignUpHooks;
